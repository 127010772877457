import { Chip, styled } from '@material-ui/core'

import { fontRegular } from '@Common/jss/'

const defaultStyle = {
  borderRadius: 2,
  fontSize: 14,
  ...fontRegular,
  height: 23,
  // width: 80,
  color: '#6D6E71',
  background: '#F4F4F4'
}

const MyChip = styled(Chip)(() => ({
  ...defaultStyle,
  color: '#4EB857',
  background: '#F0FCF1'
}))

export default MyChip
