import { useState } from 'react'

import { loadGroupInfoApi } from 'src/Service/api'
import { getLocationQuery } from 'src/Utils'

const useGroupInfo = () => {
  const [groupInfo, setGroupInfo] = useState({})

  const groupid = getLocationQuery(`groupid`)

  const fetchGroupInfo = async params => {
    try {
      const response = await loadGroupInfoApi(params)
      if (response.data.success) {
        const { data } = response.data
        console.log('data', data)
        setGroupInfo(data)
      } else {
        console.log('error')
      }
    } catch (e) {
      console.log(e.toString())
    }
  }

  const loadGroup = () => {
    if (groupid) {
      const params = { groupId: groupid }
      fetchGroupInfo(params)
    }
  }

  return {
    groupInfo,
    loadGroup
  }
}

export default useGroupInfo
