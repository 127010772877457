import classNames from 'clsx'
import PropTypes from 'prop-types'

import { useState } from 'react'
import { Link as RouterLink } from 'react-router-dom'

import { styled } from '@material-ui/core'
import { AppBar, Hidden, IconButton } from '@material-ui/core'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import Grow from '@material-ui/core/Grow'
import MenuItem from '@material-ui/core/MenuItem'
import MenuList from '@material-ui/core/MenuList'
import Popper from '@material-ui/core/Popper'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import MenuIcon from '@material-ui/icons/Menu'

import CustomTag from '@Components/Tag/Tag'

import { setAccountNamespace } from '@Utils/formatLocationData'

// Logo Image
import Logo from '@Common/images/orbitLogo.png'
import { fontBold } from '@Common/jss/'
// import { roleLists } from '@Utils/checkPermission'
import topBarStyle from '@Common/jss/components/topBarStyle'

import CustomPaper from 'src/Components/CustomPaper'
import { PERMISSION_SET_TOP_BAR } from 'src/Utils/constants'

import Button from '../CustomButtons/Button'
import Toolbar from '../Toolbar/Toolbar'

const useStyles = makeStyles(topBarStyle)

const CustomerTypography = styled(Typography)(() => ({
  color: '#4EB857',
  fontSize: '14px',
  ...fontBold
}))

const CustomerStick = ({ classes, userInfo }) => {
  return userInfo?.proxyAccountInfo ? (
    <CustomTag
      className={classes.tag}
      color={`success`}
      round
    >
      <span className={classes.tagLabel}>{`Customer - `}</span>
      <CustomerTypography component={'span'}>
        {userInfo?.proxyAccountInfo.accountName}
      </CustomerTypography>
    </CustomTag>
  ) : null
}

CustomerStick.propTypes = {
  classes: PropTypes.object,
  userInfo: PropTypes.object
}

const TopBar = props => {
  const {
    className,
    onSidebarOpen,
    onLogOutClick,
    onSalesRestoreClick,
    onSalesResetAgreementClick,
    color,
    logoText,
    hiddenSmMenu = true,
    // activationCodeHandle,
    userInfo
  } = props
  const [openProfile, setOpenProfile] = useState(null)
  const classes = useStyles()
  // const hasPermissions = useCallback(() => {
  //   const { roleInAccount } = userInfo
  //   if (!roleInAccount || !roleLists.includes(roleInAccount)) {
  //     return false
  //   } else {
  //     return roleInAccount !== 'Member'
  //   }
  // }, [userInfo])

  const hiddenUser = true

  const userName = userInfo ? setAccountNamespace(userInfo) : 'CA'
  const handleCloseProfile = () => {
    setOpenProfile(null)
  }

  const handleClickProfile = event => {
    if (openProfile && openProfile.contains(event.target)) {
      setOpenProfile(null)
    } else {
      setOpenProfile(event.currentTarget)
    }
  }

  const appBarClasses = classNames({
    [classes.appBar]: true,
    [classes[color]]: color,
    ...className
  })
  const dropdownItem = classNames({
    [classes.dropdownItem]: true,
    [classes.primaryHover]: true
  })
  const routerLinkPath =
    userInfo && userInfo.roleInAccount
      ? PERMISSION_SET_TOP_BAR.includes(userInfo.roleInAccount)
        ? '/dashboard'
        : '/profile'
      : '/profile'

  return (
    <AppBar
      className={appBarClasses}
      color="transparent"
    >
      <Toolbar className={classes.container}>
        <div className={classes.leftBox}>
          <div className={classes.logoBox}>
            <RouterLink
              className={classes.logoWrapper}
              to={routerLinkPath}
            >
              <img
                alt="Logo"
                className={classes.logo}
                src={Logo}
              />
              {logoText ? (
                <div className={classes.logoText}>{logoText}</div>
              ) : null}
            </RouterLink>
            {userInfo.isDemoUser && (
              <span className={classes.logoDemo}>Demo</span>
            )}
            <div className={classes.flexGrow} />
          </div>

          <CustomerStick
            classes={classes}
            userInfo={userInfo}
          />
        </div>

        {/* 暂时隐藏此功能，后面优化 */}
        {/* <span className={classes.plans}>
          <a href="https://dten.com/" target="_blank" rel="noopener noreferrer">
            {`PLANS & PRICING`}
          </a>
        </span> */}

        <Hidden mdDown={!hiddenSmMenu}>
          {/* {hasPermissions() && (
            <Button
              color="dTen"
              className={classes.generate}
              onClick={activationCodeHandle}
            >
              Generate Activation Code
            </Button>
          )} */}
          <div className={classes.managerClasses}>
            {hiddenUser ? null : userInfo?.proxyAccountInfo ? (
              <Button
                className={classes.partner}
                disableRipple
                link
              >
                <svg
                  aria-hidden="true"
                  className={classNames('icon', classes.supportIcon)}
                >
                  <use xlinkHref="#icon-partner" />
                </svg>
                {userInfo?.proxyAccountInfo.accountName}
              </Button>
            ) : (
              <Button
                className={classes.partner}
                disableRipple
                link
              >
                <svg
                  aria-hidden="true"
                  className={classNames('icon', classes.supportIcon)}
                >
                  <use xlinkHref="#icon-partner" />
                </svg>
                {userInfo.accountName}
              </Button>
            )}
            <Button
              aria-haspopup="true"
              aria-label="Person"
              aria-owns={openProfile ? 'profile-menu-list' : null}
              className={classes.buttonLink}
              color="success"
              justIcon
              round
              onClick={handleClickProfile}
            >
              <span className={classes.userName}>{userName}</span>
            </Button>
            <Popper
              anchorEl={openProfile}
              open={Boolean(openProfile)}
              placement="bottom-end"
              className={classNames({
                [classes.popperClose]: !openProfile,
                [classes.popperResponsive]: true,
                [classes.popperNav]: true
              })}
              disablePortal
              transition
            >
              {({ TransitionProps }) => (
                <Grow
                  {...TransitionProps}
                  id="profile-menu-list"
                  style={{ transformOrigin: '0 0 0' }}
                >
                  <CustomPaper>
                    <ClickAwayListener onClickAway={handleCloseProfile}>
                      <MenuList
                        role="menu"
                        autoFocusItem
                      >
                        <MenuItem
                          className={dropdownItem}
                          onClick={onLogOutClick}
                        >
                          <svg
                            aria-hidden="true"
                            className="icon"
                          >
                            <use xlinkHref="#icon-tuichu1" />
                          </svg>
                          {'Sign out'}
                        </MenuItem>
                        {userInfo.isDemoUser && (
                          <>
                            <MenuItem
                              className={dropdownItem}
                              onClick={onSalesRestoreClick}
                            >
                              <svg
                                aria-hidden="true"
                                className="icon"
                                style={{ transform: 'rotateY(180deg)' }}
                              >
                                <use xlinkHref="#icon-gengxin" />
                              </svg>
                              {'Restart the Demo'}
                            </MenuItem>

                            <MenuItem
                              className={dropdownItem}
                              onClick={onSalesResetAgreementClick}
                            >
                              {/* <svg
                                aria-hidden="true"
                                className="icon"
                                style={{ transform: 'rotateY(180deg)' }}
                              >
                                <use xlinkHref="#icon-gengxin" />
                              </svg> */}
                              {'Reset Partner Agreement'}
                            </MenuItem>
                          </>
                        )}
                      </MenuList>
                    </ClickAwayListener>
                  </CustomPaper>
                </Grow>
              )}
            </Popper>
          </div>
        </Hidden>
        {hiddenSmMenu ? null : (
          <Hidden lgUp>
            <IconButton
              color="inherit"
              onClick={onSidebarOpen}
            >
              <MenuIcon />
            </IconButton>
          </Hidden>
        )}
      </Toolbar>
    </AppBar>
  )
}

TopBar.propTypes = {
  className: PropTypes.string,
  onLogOutClick: PropTypes.func,
  onSalesRestoreClick: PropTypes.func,
  onSalesResetAgreementClick: PropTypes.func,
  onSidebarOpen: PropTypes.func,
  sidebarMinimize: PropTypes.func,
  color: PropTypes.oneOf([
    'primary',
    'info',
    'success',
    'warning',
    'danger',
    'default'
  ]),
  logoText: PropTypes.node,
  activationCodeHandle: PropTypes.func,
  hiddenSmMenu: PropTypes.bool,
  userInfo: PropTypes.object,
  TransitionProps: PropTypes.object
  // rtlActive: PropTypes.bool,
  // brandText: PropTypes.string,
  // miniActive: PropTypes.bool,
  // handleDrawerToggle: PropTypes.func,
  //
}

export default TopBar
