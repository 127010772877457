import activation from './zh-CN/activation'
import component from './zh-CN/components'
import menu from './zh-CN/menu'
import particular from './zh-CN/particular'
import login from './zh-CN/user'

export default {
  'navBar.lang': '语言',
  'layout.user.link.help': '帮助',
  'layout.user.link.privacy': '隐私',
  'layout.user.link.terms': '条款',
  'app.preview.down.block': '下载此页面到本地项目',
  'app.welcome.link.fetch-blocks': '获取全部区块',
  'app.welcome.link.block-list': '基于 block 开发，快速构建标准页面',
  'com.input.required': '此字段是必填',
  ...login,
  ...menu,
  ...particular,
  ...component,
  ...activation
}
