import PropTypes from 'prop-types'

import { useHistory } from 'react-router-dom'

import { makeStyles } from '@material-ui/core/styles'

import Card from '@Components/Card/Card'
import ActivateFooter from '@Components/Footer/ActivateFooter'

import scanLayoutStyle from '@Common/jss/layouts/scanLayoutStyle'

import { isMobile } from '@/Utils'

const useStyles = makeStyles(scanLayoutStyle)

const ScanLayout = props => {
  const classes = useStyles()
  const { children } = props
  // eslint-disable-next-line no-unused-vars
  const history = useHistory()
  const mobile = isMobile()
  if (!mobile) {
    // history.replace(`/`)
  }
  return (
    <div className={classes.layout}>
      <main className={classes.main}>
        <Card className={classes.content}>{children}</Card>
      </main>
      <ActivateFooter
        position="inherit"
        isScan
      />
    </div>
  )
}
ScanLayout.propTypes = {
  children: PropTypes.node
}
export default ScanLayout
