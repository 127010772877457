import classNames from 'clsx'
import PropTypes from 'prop-types'

import { forwardRef } from 'react'

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles'

// CSS Module
import typographyStyles from '@Common/jss/components/typographyStyles'

const useStyles = makeStyles(typographyStyles)
const Muted = forwardRef((props, ref) => {
  const { children, className, ...other } = props
  const classes = useStyles()
  const mutedClasses = classNames({
    [classes.defaultFontStyle]: true,
    [classes.mutedText]: true,
    [className]: className
  })
  return (
    <div
      ref={ref}
      className={mutedClasses}
      {...other}
    >
      {children}
    </div>
  )
})

Muted.displayName = 'Muted'

Muted.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node
}

export default Muted
