import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { Link as RouterLink } from 'react-router-dom'

import { Box, Container, Typography } from '@material-ui/core'

import Exception404Img from '../../Common/images/404-error.png'
import Button from '../../Components/CustomButtons/Button'
// Custom Components
import Page from '../../Components/Page/Page'
// Css Module
import classes from './exception404.module.scss'

const Exception404 = () => {
  const location = useLocation()
  useEffect(() => {
    return unmount
  }, [])

  function unmount() {
    const nextRoutePathName = location.pathname
    // 判断路由是哪个地址，如果不是当前的路由地址说明是销毁了，那就执行事件，如果是那就不执行
    if (!String.prototype.startsWith.call(nextRoutePathName, '/404')) {
      // 你的操作
      document.title = 'Orbit'
    }
  }
  // const clickBackHandle = () => {
  //   const { history } = props
  //   history.go(-1)
  // }
  return (
    <Page
      className={classes.exception}
      title="404: Not found"
    >
      <Container maxWidth="lg">
        <Box
          display="flex"
          justifyContent="center"
          mt={6}
        >
          <img
            alt="Under development"
            className={classes.image}
            src={Exception404Img}
          />
        </Box>
        <Typography
          align="center"
          color="textPrimary"
          variant={'h4'}
        >
          404: The page you are looking for isn’t here
        </Typography>
        <Typography
          align="center"
          color="textSecondary"
          variant="subtitle2"
        >
          You either tried some shady route or you came here by mistake.
          Whichever it is, try using the navigation.
        </Typography>
        <Box
          display="flex"
          justifyContent="center"
          mt={6}
        >
          <Button
            color="white"
            component={RouterLink}
            id="btnBackHome"
            to="/equipment"
            variant="outlined"
          >
            Back to home
          </Button>
        </Box>
      </Container>
    </Page>
  )
}

export default Exception404
