import classesNames from 'clsx'
import PropTypes from 'prop-types'

import Divider from '@material-ui/core/Divider'
import InputBase from '@material-ui/core/InputBase'
import Paper from '@material-ui/core/Paper'
import { makeStyles } from '@material-ui/core/styles'
import { Add, Remove } from '@material-ui/icons'

import generateNumStyle from '@Common/jss/components/generateNumStyle'

import Button from '../CustomButtons/Button'

const useStyles = makeStyles(generateNumStyle)

const GenerateNum = props => {
  const {
    className,
    value,
    onChangeHandle,
    addOrRemoveHandle,
    onClickHandle,
    onBlurHandle
  } = props
  const classes = useStyles()
  return (
    <Paper
      className={classesNames(
        {
          [classes.root]: true
        },
        className
      )}
    >
      <InputBase
        className={classes.input}
        inputProps={{ 'aria-label': 'devices code number' }}
        placeholder=""
        value={value}
        onBlur={onBlurHandle}
        onChange={onChangeHandle}
        onClick={onClickHandle}
      />
      <div className={classes.handle}>
        <Button
          className={classes.addBtn}
          color="transparent"
          justIcon
          onClick={() => addOrRemoveHandle('add')}
        >
          <Add />
        </Button>
        <Divider className={classes.divider} />
        <Button
          className={classes.reduceBtn}
          color="transparent"
          justIcon
          onClick={() => addOrRemoveHandle('reduce')}
        >
          <Remove />
        </Button>
      </div>
    </Paper>
  )
}

GenerateNum.propTypes = {
  className: PropTypes.string,
  value: PropTypes.number,
  onChangeHandle: PropTypes.func,
  addOrRemoveHandle: PropTypes.func,
  onClickHandle: PropTypes.func,
  onBlurHandle: PropTypes.func
}

export default GenerateNum
