/**
 * @author our_time
 * @data: 2020/8/10
 * @email: wangpj1029@163.com
 * @Description: Custom Utils
 */

/***
 *  .--,       .--,
 * ( (  \.---./  ) )
 *  '.__/o   o\__.'
 *     {=  ^  =}
 *      >  -  <
 *     /       \
 *    //       \\
 *   //|   .   |\\
 *   "'\       /'"_.-~^`'-.
 *      \  _  /--'         `
 *    ___)( )(___
 *   (((__) (__)))    高山仰止,景行行止.虽不能至,心向往之。
 */
import dayjs from 'dayjs'
import { Base64 } from 'js-base64'

export const ITEM_HEIGHT = 48
export const ITEM_PADDING_TOP = 8

/**
 * @Desc 用户名校验规则
 * @type {RegExp}
 */

export const regName =
  /^((?![~!@#$%^&*()<>?:"{}！,、￥、……、（）、？、：、‘’。，“”【】《》〈〉｛｝「」『』﹃﹄〔〕…～]).)*$/

/**
 * @description: 6位数字
 * @param {*}
 * @return {*}
 */

export const reg6digit = /^\d{6}$/

export const regDigit = /[^\d]/g

export const regDomainGeneral =
  /^([a-zA-Z0-9]([a-zA-Z0-9\\-]{0,}[a-zA-Z0-9])?\.)+[a-zA-Z]{1,6}$/

export const regDomainUnlimitedLength =
  /^([a-zA-Z0-9]([a-zA-Z0-9\\-]+[a-zA-Z0-9])?\.)+[a-zA-Z]+$/

var strRegexDomainIP =
  '' +
  '(([0-9]{1,3}.){3}[0-9]{1,3}' + // IP形式的URL- 199.194.52.184
  '|' + // 允许IP和DOMAIN（域名）
  '([0-9a-z_!~*()-]+.)*' + // 域名- www.
  '([0-9a-z][0-9a-z-]{0,61})?[0-9a-z].' + // 二级域名
  '[a-z]{2,6})' + // first level domain- .com or .museum
  '(:[0-9]{1,4})?' + // 端口- :80
  '$'

export const regDomainIP = new RegExp(strRegexDomainIP)

/**
 * @Desc 国际号码校验规则
 * @type {RegExp}
 */

// export const regPhone = /^(1\s?)?(\(\d{3}\)|\d{3})\s?-?\d{3}-?\s?\d{4}$/
export const regPhone = /^(\d){1,3}[ ]?([-]?((\d)|[ ]){0,12})+$/
/**
 * @Desc 判断密码是否包含大小写字母，切长度为6～32个字符
 * @type {RegExp}
 */

export const regPassword = /(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])[\s\S]{6,32}$/

/**
 * @Desc 校验邮箱格式
 * @type {RegExp}
 */

export const regEmail =
  /^\S+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.[a-zA-Z0-9]{1,64}$/

/**
 * @Desc 校验邮箱格式不限长度
 * @type {RegExp}
 */
export const regEmailUnlimitedLength =
  /^\S+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.[a-zA-Z0-9]*$/

/**
 * @description: IP地址
 * @param {*}
 * @return {*}
 */
export const regIP =
  /^(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])$/
// 只能包含字母、数字和破折号（必须以字母或数字开头） 大小写不敏感
export const regDomain = /^[a-zA-Z0-9][a-zA-Z0-9-]*$/
/**
 * @Desc 设置下拉框显示高度
 * @type {{PaperProps: {style: {maxHeight: number, width: number}}}}
 */

export const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 210
    },
    anchorReference: {
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'center'
      },
      transformOrigin: {
        vertical: 'top',
        horizontal: 'center'
      }
    }
  }
}

/**
 * @Desc 加密对象
 * @param data
 * @returns {{}|*}
 */
export function mapObjectValues(data) {
  if (Object.prototype.toString.call(data) === '[object Object]') {
    let result = {}
    Object.keys(data).map(item => (result[item] = Base64.encode(data[item])))
    return result
  }
  return data
}

/**
 * @Desc 获取所有的参数（通过对象的形式返回）
 * @returns {Object}
 * @constructor
 */

export const getQueryStrings = () => {
  const hrefUrl = window.location.href
  const url = hrefUrl.split('?')[1] || ''
  let theRequest = {}
  if (url) {
    let stylists = url.split('&')
    for (let i = 0; i < stylists.length; i++) {
      theRequest[stylists[i].split('=')[0]] = decodeURIComponent(
        stylists[i].split('=')[1]
      )
    }
  }
  return theRequest
}

/**
 * @Desc 返回两个集合的交集
 * @param thisSet
 * @param otherSet
 * @returns {Set<any>}
 */
export function intersection(thisSet, otherSet) {
  //初始化一个新集合，用于表示交集。
  const interSectionSet = new Set()
  //将当前集合转换为数组
  const values = Array.from(thisSet)
  //遍历数组，如果另外一个集合也有该元素，则interSectionSet加入该元素。
  for (let i = 0; i < values.length; i++) {
    if (otherSet.has(values[i])) {
      interSectionSet.add(values[i])
    }
  }
  return interSectionSet
}

/**
 * @Desc 设置时间选择器列表
 * @returns {string[]}
 */

export const setTimePickerLists = () => {
  const times = Array.from({ length: 24 }, (x, i) => [
    `${`${i}`.padStart(2, '0')}:00`,
    `${`${i}`.padStart(2, '0')}:30`
  ]).flat()
  return times.map(item => {
    return formatTimerAmPm(item)
  })
}

/**
 * @Desc 24小时转 12 小时
 * @returns {string}
 */

export const formatTimerAmPm = timeString => {
  const timeArray = timeString.replace(/\s/g, '').split(':')
  let hour = Number(timeArray[0])
  const minute = timeArray[1]
  const ampm = hour >= 12 ? 'PM' : 'AM'
  hour = hour >= 12 ? hour - 12 : hour
  hour = hour >= 10 ? hour : `0${hour}`
  let result = `${hour}:${minute} ${ampm}`
  if (ampm === 'AM' && hour === '00') {
    result = `12:${minute} AM`
  } else if (ampm === 'PM' && hour === '00') {
    result = `12:${minute} PM`
  }
  return result
}

/**
 * @Desc 12小时转 24 小时
 * @returns {string}
 */

export const formatTimer24Hour = timeString => {
  let timeOriginal = timeString.replace(/\s/g, '')
  let time24Hour = ''
  let timeArray = []
  let hour = ''
  let minute = ''
  if (timeOriginal.indexOf('AM') > -1) {
    time24Hour = timeOriginal.replace('AM', '')
    timeArray = time24Hour.split(':')
    hour = timeArray[0]
    minute = timeArray[1]
    if (hour === '12') {
      return `00:${minute}`
    }
    return `${hour}:${minute}`
  } else if (timeOriginal.indexOf('PM') > -1) {
    time24Hour = timeOriginal.replace('PM', '')
    timeArray = time24Hour.split(':')
    hour = Number(timeArray[0]) + 12
    minute = timeArray[1]
    // console.log('hour', hour)
    if (hour === 24) {
      return `12:${minute}`
    }
    return `${hour}:${minute}`
  }
}

/**
 * @Desc 点击复制到剪贴板函数
 * @param content
 */

export function copyToClipboard(content) {
  if (window.clipboardData) {
    window.clipboardData.setData('text', content)
  } else {
    ;(function (content) {
      document.oncopy = function (e) {
        e.clipboardData.setData('text', content)
        e.preventDefault()
        document.oncopy = null
      }
    })(content)
    document.execCommand('Copy')
  }
}
/**
 * @Desc 不允许输入中文
 * @param e
 */
export function inputChangeReplaceCn(e) {
  e.target.value = e.target.value.replace(/[\u4e00-\u9fa5]/g, '')
}

/**
 * @Desc 根据id合并数据
 * @param a
 * @param b
 * @returns {[]}
 */
export function merge(a, b) {
  let c = []
  for (let u of a) {
    const temp = b.filter(i => i.id === u.id)[0]
    c.push({ ...u, ...temp })
  }
  return c
}
/**
 * @Desc email验证最大长度
 *
 */
export const EMAILMAXLENGTH = 64

/**
 * @Desc 替换Url
 * @param domain
 */
export const replaceUrl = domain => {
  const { pathname, host } = window.location
  if (domain && host !== domain) {
    window.location.href = `https://${domain}${pathname}`
  }
}

export const checkIsPicture = file => {
  console.log(file.name)
  if (file.name) {
    return /\.(gif|jpg|jpeg|png|GIF|JPG|PNG)$/.test(file.name)
  } else {
    return false
  }
}

const templateEmails = [
  'dten.com',
  'linshiyouxiang',
  'temporary-mail.net',
  'qq.com',
  'zoom.us'
]

/**
 * @description: 判断是否测试邮箱
 * @param {*}
 * @return {*}
 */
export const isTestUser = (email = '') => {
  let isTest = 0
  for (let item of templateEmails) {
    let isFind = email.indexOf(item) > -1
    if (isFind) {
      return 1
    }
  }
  return isTest
}

// 🧓表情占4个字节，中文日文占2个字节，正则查询双字节字符*2，一个表情是2个双字节字符
export const widthCheck = (str, len) => {
  // 限制输入框输入的字符数
  let temp = 0
  for (let i = 0; i < str.length; i++) {
    // eslint-disable-next-line no-control-regex
    if (/[^\x00-\xff]/gi.test(str[i])) {
      temp += 2
    } else {
      temp++
    }
    if (temp > len) {
      str = str.substr(0, i)
    }
  }
  return str
}

/**
 * @description:
 * const appleIphone = /iPhone/i;
 * const appleIpod = /iPod/i;
 * const appleTablet = /iPad/i;
 * const appleUniversal = /\biOS-universal(?:.+)Mac\b/i;
 * const androidPhone = /\bAndroid(?:.+)Mobile\b/i; // Match 'Android' AND 'Mobile'
 * const androidTablet = /Android/i;
 * const amazonPhone = /(?:SD4930UR|\bSilk(?:.+)Mobile\b)/i; // Match 'Silk' AND 'Mobile'
 * const amazonTablet = /Silk/i;
 * const windowsPhone = /Windows Phone/i;
 * const windowsTablet = /\bWindows(?:.+)ARM\b/i; // Match 'Windows' AND 'ARM'
 * const otherBlackBerry = /BlackBerry/i;
 * const otherBlackBerry10 = /BB10/i;
 * const otherOpera = /Opera Mini/i;
 * const otherChrome = /\b(CriOS|Chrome)(?:.+)Mobile/i;
 * const otherFirefox = /Mobile(?:.+)Firefox\b/i; // Match 'Mobile' AND 'Firefox'
 * @param {*}
 * @return {*} boolean
 */
export const isMobile = () => {
  return /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini| mobile/i.test(
    window.navigator.userAgent
  )
}
/**
 * @description: 一句话首字母大写
 * @param {*}
 * @return {*}
 */
export const sentenceFirstWordUpperCase = (str = '') => {
  const characters = [...str]
  characters[0] = characters[0].toUpperCase()
  const sentence = characters.join('')
  return sentence
}

// 递归查找菜单树
export const treeFind = (tree, func) => {
  for (const data of tree) {
    if (func(data)) return data
    if (data.routes) {
      const res = treeFind(data.routes, func)
      if (res) return res
    }
  }
  return null
}

/**
 * @description: 获取地址栏参数
 * @param {*}
 * @return {*}
 */
export const getLocationQuery = key => {
  const params = new URLSearchParams(window.location.search)
  const result = params.get(key)
  return result
}

/**
 * @description: 单词首字母大写
 * @param {*}
 * @return {*}
 */
export const firstWordUpperCase = (str = '') => {
  return str.toLowerCase().replace(/( |^)[a-z]/g, L => L.toUpperCase())
}

export const validJSON = json => {
  try {
    JSON.parse(json)
    return true
  } catch {
    return false
  }
}

export const isSuperAdmin = userInfo => {
  const { roleInAccount } = userInfo
  return roleInAccount === 'SuperAdmin'
}

export function changeTimeByTimeZone(times, zone) {
  if (!zone) {
    return times
  } else {
    if (zone.includes(':') || zone.includes('+') || zone.includes('-')) {
      return dayjs.utc(times).utcOffset(zone)
    }
    return dayjs.utc(times).tz(zone)
  }
}

/**
 * @description: 字符数超过n，截取n
 * @param {*} str 字符串
 * @param {*} n 截取个数
 * @return {*}
 */
export const sliceString = (str, n) => {
  if (!str) {
    return ''
  }
  return str.length <= n ? str : `${str.slice(0, n)}...`
}

/* 
[{ label: '5Minutes', value: 5 }]
查找数组中,value 对应的label
*/
export const getBtnTxt = (val, arr) => {
  const obj = arr.find(item => item.value === val)
  return obj ? obj.label : ''
}

/**
 * @description: 判断空对象
 * @param {*} value
 * @return {*}
 */
export function isEmptyObject(value) {
  return (
    value && Object.keys(value).length === 0 && value.constructor === Object
  )
}

/**
 * @description: 判断权限
 * @param {*} isSwitched
 * @param {*} permissions
 * @param {*} permissionId
 * @return {*}
 */
export function getCustomerPermissionPower({
  isSwitched,
  permissions,
  permissionId
}) {
  let hasPower = true
  if (isSwitched) {
    // console.log('permissions', permissions)
    hasPower = permissions.includes(permissionId)
  }
  return hasPower
}
/**
 * @description: 字符串转URL对象
 * @param {*} str
 * @return {*}
 */
export function getUrlByString(str) {
  // eslint-disable-next-line no-irregular-whitespace
  const url = new URL(str)
  return url
}

export function getExtension(name) {
  return name.includes('.') ? name.substring(name.lastIndexOf('.') + 1) : ''
}

export function isOkExt(name, acceptArray) {
  const fileExt = getExtension(name).toLowerCase()
  const arr = acceptArray.map(item => item.toLowerCase())
  return arr.includes(fileExt)
}
