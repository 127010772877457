export default function chainPropTypes(propType1, propType2) {
  if (process.env.NODE_ENV === 'production') {
    // eslint-disable-next-line react/display-name
    return () => null
  }

  return function validate(...args) {
    return propType1(...args) || propType2(...args)
  }
}
