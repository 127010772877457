import { whiteColor } from '../index'

const authLayoutStyle = theme => ({
  root: {
    width: '100%',
    height: '100%',
    position: 'relative',
    padding: '60px 0 61px'
  },
  main: {
    width: '100%',
    height: '100%',
    background: whiteColor,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 30,
      paddingRight: 30
    }
  },
  links: {
    background: '#F4F6F7'
  },
  content: {
    width: '100%',
    height: '100%',
    overflow: 'auto'
  }
})

export default authLayoutStyle
