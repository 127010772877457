import { Base64 } from 'js-base64'
import { cloneDeep, isEqual } from 'lodash'
import { action, makeObservable, observable } from 'mobx'

import { getActivationInfo, setActivationInfo } from '../Utils/cache'

const activationBase = getActivationInfo()

class ActivateStore {
  constructor(rootStore) {
    makeObservable(this, {
      activationInfo: observable,
      setActivationInfo: action.bound
    })

    this.rootStore = rootStore
  }

  activationInfo = activationBase
    ? JSON.parse(Base64.decode(activationBase))
    : {
        state: 0,
        deviceId: ''
      }

  setActivationInfo(values) {
    const updatedInfo = { ...cloneDeep(this.activationInfo), ...values }
    if (!isEqual(updatedInfo, this.activationInfo)) {
      this.activationInfo = updatedInfo
      const encodeData = Base64.encode(JSON.stringify(updatedInfo))
      setActivationInfo(encodeData)
    }
  }
}

export default ActivateStore
