import PropTypes from 'prop-types'

import { lazy, Suspense } from 'react'
import { Route, Switch } from 'react-router-dom'

import Loading from '../../Components/Loading/Loading'

const OktaSignIn = lazy(() => import('./OktaSignIn/OktaSignIn'))
const Notification = lazy(() => import('./Notification/Notification'))

const ThirdPartyAuth = props => {
  const { match } = props
  return (
    <Suspense fallback={<Loading />}>
      <Switch>
        <Route
          path={`${match.url}/sign-in`}
          render={props => <OktaSignIn {...props} />}
          exact
          strict
        />
        <Route
          path={`${match.url}/remind`}
          render={props => <Notification {...props} />}
        />
      </Switch>
    </Suspense>
  )
}

ThirdPartyAuth.propTypes = {
  match: PropTypes.object
}

export default ThirdPartyAuth
