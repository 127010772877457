import PropTypes from 'prop-types'

import CustomTabs from '@Components/CustomTabs/CustomTabs'

const MembersLayout = props => {
  const { children } = props
  const tabsInfo = [
    {
      id: 'users',
      name: 'Users',
      routes: {
        pathname: '/members/users'
      }
    },
    {
      id: 'pending',
      name: 'Pending',
      routes: {
        pathname: '/members/pending'
      }
    }
  ]
  return <CustomTabs tabs={tabsInfo}>{children}</CustomTabs>
}

MembersLayout.propTypes = {
  children: PropTypes.node
}

export default MembersLayout
