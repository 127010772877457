import { createContext, useContext, useEffect, useState } from 'react'

export const CanAutoFocusContext = createContext(true)

export const useCanAutoFocus = () => useContext(CanAutoFocusContext)

export function useAutoFocusControl(open) {
  const [canAutoFocus, setCanAutoFocus] = useState(false)

  useEffect(() => {
    if (!open) {
      setCanAutoFocus(false)
    }
  }, [open])
  if (process.env.NODE_ENV === 'test') {
    return {
      canAutoFocus: true,
      onOpen: () => {}
    }
  }

  return {
    canAutoFocus,
    onOpen: () => setCanAutoFocus(true)
  }
}
