import { grayColor, successColor, whiteColor } from '../index'

const dialogContentStyle = () => ({
  dialog: {
    width: '480px',
    padding: '35px 40px',
    backgroundColor: whiteColor,
    borderRadius: '10px',
    boxShadow: '0px 0px 20px 0px rgba(0,0,0,0.35)',
    overflow: 'scroll'
  },
  greenLawn: {
    borderTop: `10px solid ${successColor[0]}`
  },
  title: {
    position: 'relative',
    fontSize: '24px',
    fontFamily: '"Lato Medium", "Lato-Medium", "Lato", sans-serif',
    fontWeight: 500,
    color: grayColor[18],
    lineHeight: '27px'
  },
  subtitle: {
    fontSize: '14px',
    fontFamily: '"Lato Bold", "Lato-Bold", "Lato", sans-serif',
    fontWeight: 'bold',
    color: grayColor[0],
    lineHeight: '27px'
  },
  closeBtn: {
    position: 'absolute',
    top: '-10px',
    right: '-30px'
  },
  close: {
    width: 16,
    height: 16,
    color: 'rgba(155,155,155,1)'
  },
  content: {
    fontSize: '14px',
    fontFamily: '"Lato Regular", "Lato-Regular", "Lato", sans-serif',
    fontWeight: 400,
    color: 'rgba(109, 110, 113, 1)',
    lineHeight: '28px',
    paddingTop: '20px',
    paddingBottom: '30px'
  },
  centerText: {
    textAlign: 'center'
  },
  centerAction: {
    justifyContent: 'center !important'
  },
  actions: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end'
  },
  save: {
    fontSize: '14px',
    fontFamily: '"Lato Medium", "Lato-Medium", "Lato", sans-serif',
    fontWeight: '500',
    lineHeight: '20px',
    minWidth: 82
    // paddingTop: '3px',
    // paddingBottom: '3px'
  },
  highlightButton: {
    color: whiteColor,
    boxShadow: 'none',
    backgroundColor: '#4eb857'
  },
  cancel: {
    fontSize: '14px',
    fontFamily: '"Lato Medium", "Lato-Medium", "Lato", sans-serif',
    fontWeight: '500',
    color: 'rgba(109,110,113,1)',
    background: 'transparent',
    boxShadow: 'none',
    border: '1px solid rgba(109,110,113,1)',
    lineHeight: '20px',
    marginRight: 10,
    minWidth: 82,
    // paddingTop: '3px',
    // paddingBottom: '3px',
    '&:hover, &:active': {
      color: whiteColor,
      backgroundColor: 'rgba(109, 110, 113, 1)'
    }
  }
})

export default dialogContentStyle
