/**
 * @desc: Uploader Component
 * @author: OurTime...
 * @date: 2021/1/14
 */
import classesNames from 'clsx'
import PropTypes from 'prop-types'

import { Fragment, useRef, useState } from 'react'

import { LinearProgress } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { Add, BrokenImage, Cancel } from '@material-ui/icons'

import getUid from '@Utils/uid'

import { blackColor, dangerColor, grayColor, whiteColor } from '@Common/jss'

import Button from '../CustomButtons/Button'
import Tooltip from '../Tooltip/Tooltip'
import Typography from '../Typography/Typography'

// import { checkIsPicture } from '../../Utils'

// import { UploadFile } from '../../Service/api'

const styles = theme => {
  return {
    root: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap'
    },
    inner: {
      width: 90,
      height: 90,
      textAlign: 'center',
      display: 'inline-block',
      border: '1px dashed #979797',
      margin: '10px 0 10px 0',
      '& > input': {
        display: 'none'
      }
    },
    replyEnclosure: {
      width: '60px',
      height: '36px',
      marginRight: '20px',
      fontFamily: '"Lato Bold", "Lato Bold", "Lato", sans-serif',
      fontWeight: 'bold',
      '& > input': {
        display: 'none'
      }
    },
    replyEnclosureIcon: {
      fontSize: '16px',
      fontWeight: 'bold',
      marginRight: '0px !important',
      transform: 'rotate(180deg)'
    },
    icon: {
      width: '36px !important',
      height: '36px !important',
      color: '#6D6E71',
      marginRight: '0 !important'
    },
    lists: {
      // display: 'flex',
      // alignItems: 'center',
      // justifyContent: 'center'
    },
    container: {
      width: '110px',
      height: '110px',
      display: 'inline-block',
      padding: '10px 20px 10px 0',
      marginBottom: 10,
      '& $loadingIcon, & $iconDone': {
        width: '16px',
        height: '16px',
        marginTop: '4px'
      },
      '&:nth-child(4n+4)': {
        width: 90,
        paddingRight: 0
      }
    },
    detailContainer: {
      width: '110px',
      height: '110px',
      display: 'inline-block',
      padding: '10px 20px 10px 0',
      marginBottom: 10,
      '& $loadingIcon, & $iconDone': {
        width: '16px',
        height: '16px',
        marginTop: '4px'
      },
      '&:nth-child(2n+2)': {
        width: 90,
        paddingRight: 0
      }
    },
    'img-container': {
      width: '100%',
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      border: '1px solid #d9d9d9',
      borderRadius: 4,
      position: 'relative',
      '& > img': {
        width: '100%',
        height: '100%',
        borderRadius: 4
      }
    },
    'status-done': {
      '&:hover': {
        '& $cancel': {
          display: 'block',
          fill: dangerColor[0],
          color: whiteColor
        }
      }
    },
    'status-error': {
      border: `1px solid ${dangerColor[0]}`,
      '&:hover': {
        '& $cancel': {
          display: 'block',
          fill: dangerColor[0],
          color: whiteColor
        }
      }
    },
    'status-uploading': {
      '& $cancel': {
        display: 'none'
      }
    },
    cancel: {
      width: '16px',
      height: '16px',
      position: 'absolute',
      top: '-8px',
      right: '-8px',
      fill: blackColor,
      display: 'none',
      zIndex: theme.zIndex.tooltip,
      cursor: 'pointer'
    },
    name: {
      color: grayColor[0],
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      overflow: 'hidden'
    },
    loadingRoot: {
      padding: 0
    },
    loadingIcon: {},
    iconDone: {},
    text: {
      flex: 1,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap'
    },
    uploadingWrapper: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column'
    },
    uploadingText: {
      fontSize: 14,
      marginBottom: 6
    },
    uploading: {
      width: '100%',
      background: '#4EB857'
    },
    barColorPrimary: {
      background: '#009082'
    },
    error: {},
    brokenImage: {
      width: 32,
      height: 32,
      color: dangerColor[0]
    },
    iconSize: {
      fontSize: '40px'
    },
    fileLargeError: {
      color: '#f44336',
      marginTop: '5px',
      lineHeight: '20px'
    }
  }
}

function getBase64(file) {
  if (file.type.includes('image/heic')) {
    return ''
  }

  if (file.type.includes('image/')) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => resolve(reader.result)
      reader.onerror = error => reject(error)
    })
  }

  return ''
}

const Uploader = props => {
  const {
    classes,
    multiple = false,
    fileList,
    onChange,
    showFileList = true,
    disabled,
    onMouseEnter,
    onMouseLeave,
    maxSize,
    onDelFile,
    fileType,
    fromPage = '',
    btnClass = '',
    linkBtn = false,
    ...other
  } = props
  const fileInput = useRef()
  const [err, setErr] = useState('')

  const handleImageChange = async e => {
    e.preventDefault()
    await uploadFiles(e.target.files)
  }
  const handleClick = () => {
    fileInput.current.click()
  }

  const uploadFiles = async files => {
    let filesList = []
    let fd = new FormData()
    setErr('')
    const postFiles = Array.prototype.slice.call(files)
    for (let i = 0; i < postFiles.length; i++) {
      const file = postFiles[i]
      file.uploadKey = getUid()
      const fileSize = file.size / 1048576
      if (maxSize && fileSize > maxSize) {
        setErr(
          `File ${file.name} is too big(${fileSize.toFixed(
            2
          )}MB) . Max fileSize is ${maxSize}MB.`
        )
      } else {
        fd.append('files', file)
        fd.append('ids', file.uploadKey)
        filesList.push({
          uploadKey: file.uploadKey,
          url: await getBase64(file),
          status: 'uploading',
          name: file.name,
          token: ''
        })

        // UploadFile(fd).then(res => {
        //   console.log(res)
        // })
      }
    }
    fileInput.current.value = ''
    // UploadFile(fd).then(res => {
    //   console.log(res)
    // })
    if (onChange) onChange(filesList, fd)
  }

  const handleDel = item => {
    if (onDelFile) onDelFile(item)
  }

  const events = disabled
    ? null
    : { onClick: handleClick, onMouseEnter, onMouseLeave }

  const statusNodes = props => {
    // eslint-disable-next-line react/prop-types
    const { url, name, status } = props
    const baseNodes = {
      done: url ? (
        <img
          alt={name}
          src={url}
        />
      ) : (
        <svg
          aria-hidden="true"
          className={`${'icon' + ' '}${classes.iconSize}`}
        >
          <use xlinkHref="#icon-file" />
        </svg>
      ),
      uploading: (
        <div className={classes.uploadingWrapper}>
          <div className={classes.uploadingText}>{'uploading'}</div>
          <LinearProgress
            classes={{ barColorPrimary: classes.barColorPrimary }}
            className={classes.uploading}
          />
        </div>
      ),
      error: (
        <div className={classes.error}>
          <BrokenImage className={classes.brokenImage} />
        </div>
      )
    }
    return baseNodes[status]
  }
  return (
    <Fragment>
      <div className={classes.root}>
        {showFileList && fileList && fileList.length > 0
          ? fileList.map(item => {
              return (
                <div
                  key={item.uploadKey}
                  className={classesNames({
                    [classes['container']]: !fromPage,
                    [classes['detailContainer']]: fromPage
                  })}
                >
                  <div
                    className={classesNames({
                      [classes['img-container']]: true,
                      [classes[`status-${item.status}`]]: true
                    })}
                  >
                    {statusNodes(item)}
                    <Cancel
                      className={classes.cancel}
                      onClick={() => handleDel(item)}
                    />
                  </div>
                  <Typography className={classes.name}>
                    <span className={classes.text}>{item.name}</span>
                  </Typography>
                </div>
              )
            })
          : null}
        {linkBtn ? (
          <Tooltip
            placement="bottom-end"
            title={'Add Attachment'}
          >
            <Button
              className={classesNames(classes.replyEnclosure, btnClass)}
              color="dTen"
              disabled={disabled}
              variant="outlined"
              onClick={() => {
                fileInput.current.click()
              }}
            >
              <input
                ref={fileInput}
                accept={`${fileType ? fileType : 'file'}`}
                multiple={multiple}
                type="file"
                onChange={handleImageChange}
                {...other}
              />
              <svg
                aria-hidden="true"
                className={classesNames('icon', classes.replyEnclosureIcon)}
              >
                <use xlinkHref="#icon-link"></use>
              </svg>
            </Button>
          </Tooltip>
        ) : (
          <Button
            className={classesNames(classes.inner, btnClass)}
            color={'transparent'}
            {...events}
            disabled={disabled}
          >
            <input
              ref={fileInput}
              accept={`${fileType ? fileType : 'file'}`}
              multiple={multiple}
              type="file"
              onChange={handleImageChange}
              {...other}
              // accept="image/*"
            />
            <Add className={classes.icon} />
          </Button>
        )}
      </div>
      {err ? <div className={classes.fileLargeError}> {err} </div> : null}
    </Fragment>
  )
}

Uploader.propTypes = {
  avatar: PropTypes.bool,
  disabled: PropTypes.bool,
  addButtonProps: PropTypes.object,
  changeButtonProps: PropTypes.object,
  removeButtonProps: PropTypes.object,
  onChange: PropTypes.func,
  uploadListType: PropTypes.oneOf(['text', 'picture', 'picture-card']),
  classes: PropTypes.object,
  multiple: PropTypes.bool,
  fileList: PropTypes.array,
  showFileList: PropTypes.bool,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
  maxSize: PropTypes.number,
  onDelFile: PropTypes.func,
  fileType: PropTypes.string,
  fromPage: PropTypes.string,
  btnClass: PropTypes.string,
  linkBtn: PropTypes.bool
}

export default withStyles(styles, { name: 'CustomUploader' })(Uploader)
