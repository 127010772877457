export const isRangeValid = (utils, range) => {
  return Boolean(
    range && range[0] && range[1] && !utils.isBefore(range[1], range[0])
  )
}

export const isWithinRange = (utils, day, range) => {
  return isRangeValid(utils, range) && utils.isWithinRange(day, range)
}

export const isStartOfRange = (utils, day, range) => {
  return isRangeValid(utils, range) && utils.isSameDay(day, range[0])
}

export const isEndOfRange = (utils, day, range) => {
  return isRangeValid(utils, range) && utils.isSameDay(day, range[1])
}

export const validateDate = (
  utils,
  value,
  { minDate, maxDate, disableFuture, shouldDisableDate, disablePast }
) => {
  const now = utils.date()
  const date = utils.date(value)

  if (date === null) {
    return null
  }

  switch (true) {
    case !utils.isValid(value):
      return 'invalidDate'

    case Boolean(shouldDisableDate && shouldDisableDate(date)):
      return 'shouldDisableDate'

    case Boolean(disableFuture && utils.isAfterDay(date, now)):
      return 'disableFuture'

    case Boolean(disablePast && utils.isBeforeDay(date, now)):
      return 'disablePast'

    case Boolean(minDate && utils.isBeforeDay(date, minDate)):
      return 'minDate'

    case Boolean(maxDate && utils.isAfterDay(date, maxDate)):
      return 'maxDate'

    default:
      return null
  }
}

export function parseRangeInputValue(utils, { value = [null, null] }) {
  return value.map(date =>
    !utils.isValid(date) || date === null
      ? null
      : utils.startOfDay(utils.date(date))
  )
}
