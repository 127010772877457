/**
 * @desc: <h2>PermissionLayout</h2>
 * @Description:
 * @author: OurTime...
 * @date: 2022/3/4
 */
import classNames from 'clsx'
import PropTypes from 'prop-types'

import { styled } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import Footer from '@Components/Footer/Footer'

// CSS Modules
import DtenLogo from '@Common/images/dten_logo.png'
import authLayoutStyle from '@Common/jss/layouts/authLayoutStyle'

const useStyles = makeStyles(authLayoutStyle)

const StylesHeader = styled('div')(() => ({
  width: '100%',
  height: '60px',
  position: 'fixed',
  top: 0,
  left: 0,
  background: '#FFFFFF',
  boxShadow: '0px 3px 4px 0px rgba(224, 226, 230, 0.25)',
  padding: '10px 30px',
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center'
}))

const StyledImg = styled('img')(() => ({
  display: 'block',
  width: '97px',
  height: '31px'
}))

const PermissionLayout = props => {
  const { children } = props
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <StylesHeader>
        <StyledImg
          alt={'Logo'}
          src={DtenLogo}
        />
      </StylesHeader>
      <main className={classNames(classes.main, classes.links)}>
        <div className={classes.content}>{children}</div>
      </main>
      <Footer
        border={false}
        position="static"
      />
    </div>
  )
}

PermissionLayout.propTypes = {
  children: PropTypes.element.isRequired
}

export default PermissionLayout
