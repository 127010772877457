/**
 * @desc: <h2>ExternalLinks</h2>
 * @Description:
 * @author: OurTime...
 * @date: 2022/3/4
 */
import PropTypes from 'prop-types'

import { lazy, Suspense } from 'react'
import { Route, Switch } from 'react-router-dom'

import Loading from '../../Components/Loading/Loading'

// const Accredit = React.lazy(() => import('./Accredit'))
const Accredit = lazy(() =>
  import('src/Views/Admin/DeviceManagement/ManageAuthorization')
)
const RemoteExplain = lazy(() => import('./RemoteExplain'))

const ExternalLinks = props => {
  const { match } = props
  return (
    <Suspense fallback={<Loading />}>
      <Switch>
        <Route
          path={`${match.url}/accredit`}
          render={props => <Accredit {...props} />}
          exact
          strict
        />
        <Route
          path={`${match.url}/remote-explain`}
          render={props => <RemoteExplain {...props} />}
        />
      </Switch>
    </Suspense>
  )
}

ExternalLinks.propTypes = {
  match: PropTypes.object
}

export default ExternalLinks
