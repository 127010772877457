import { observer } from 'mobx-react'
import PropTypes from 'prop-types'

import { useEffect } from 'react'

import { makeStyles } from '@material-ui/core/styles'

// CSS Modules
import helpLayoutStyle from '@Common/jss/layouts/helpLayoutStyle'

import { useStore } from 'src/Hooks/useStore'

const useStyles = makeStyles(helpLayoutStyle)

const HelpLayout = props => {
  const {
    user: { fetchLoadUserInfo }
  } = useStore()
  const { children } = props
  const classes = useStyles()

  useEffect(() => {
    fetchLoadUserInfo()
  }, [])

  return (
    <div className={classes.root}>
      <main className={classes.main}>
        <div className={classes.content}>{children}</div>
      </main>
    </div>
  )
}

HelpLayout.propTypes = {
  children: PropTypes.element.isRequired
}

export default observer(HelpLayout)
