import {
  boxShadow,
  defaultFont,
  grayColor,
  hexToRgb,
  whiteColor
} from '../index'
import customDropdownStyle from './customDropdownStyle'

const headerLinksStyle = theme => ({
  ...customDropdownStyle(theme),
  root: {
    padding: '8px 0',
    backgroundColor: 'rgba(232,234,235,1)'
  },
  user: {
    padding: '8px 16px',
    position: 'relative',
    flexDirection: 'column',
    '&:after': {
      content: '""',
      position: 'absolute',
      bottom: '0',
      right: '15px',
      height: '1px',
      width: 'calc(100% - 30px)',
      backgroundColor: 'hsla(0,0%,100%,.3)'
    }
  },
  itemLink: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    transition: 'all 300ms linear',
    marginTop: 10,
    borderRadius: '6px',
    position: 'relative',
    backgroundColor: 'transparent',
    padding: '8px 16px',
    ...defaultFont,
    '&:hover': {
      outline: 'none',
      backgroundColor: 'rgba(244,245,247,1)',
      boxShadow: 'none'
    },
    '&,&:hover,&:focus': {
      color: 'inherit'
    }
  },
  photo: {
    transition: 'all 300ms linear',
    width: '34px',
    height: '34px',
    overflow: 'hidden',
    float: 'left',
    zIndex: '5',
    marginRight: '11px',
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#4eb857',
    color: whiteColor,
    ...boxShadow
  },
  userCollapseButton: {
    margin: '0',
    padding: '6px 15px 6px 0',
    '&:hover': {
      background: 'none'
    },
    cursor: 'pointer'
  },
  userItemText: {
    color: 'rgba(95,95,95,1)',
    fontSize: '16px',
    fontFamily: '"Lato Bold", "Lato-Bold", "Lato", sans-serif',
    fontWeight: 600,
    lineHeight: '24px',
    textTransform: 'capitalize'
  },
  primary: {
    fontSize: 'inherit'
  },
  userNameDisplay: {
    maxWidth: '149px',
    overflow: 'hidden',
    display: 'inline-flex',
    height: '24px'
  },
  caret: {
    marginTop: '13px',
    position: 'absolute',
    right: '18px',
    transition: 'all 150ms ease-in',
    display: 'inline-block',
    width: '0',
    height: '0',
    marginLeft: '2px',
    verticalAlign: 'middle',
    borderTop: '4px solid',
    borderRight: '4px solid transparent',
    borderLeft: '4px solid transparent'
  },
  userCaret: {
    marginTop: '10px'
  },
  caretActive: {
    transform: 'rotate(180deg)'
  },
  list: {
    marginTop: '15px',
    paddingLeft: '0',
    paddingTop: '0',
    paddingBottom: '0',
    marginBottom: '0',
    listStyle: 'none',
    color: 'inherit',
    '&:before,&:after': {
      display: 'table',
      content: '" "'
    },
    '&:after': {
      clear: 'both'
    }
  },
  collapseList: {
    marginTop: '0',
    '& $caret': {
      marginTop: '8px'
    },
    cursor: 'pointer'
  },
  collapseItem: {
    position: 'relative',
    display: 'block',
    textDecoration: 'none',
    margin: '10px 0 0 0',
    padding: '0',
    color: '#6d6e71'
  },
  collapseActive: {
    outline: 'none',
    backgroundColor: `rgba(${hexToRgb(grayColor[17])}, 0.2)`,
    boxShadow: 'none'
  },
  collapse: {
    width: '100%'
  },
  handle: {
    padding: '16px 16px 0 16px'
  },
  buttonIcon: {
    marginRight: '14px !important',
    width: '16px !important',
    height: '16px !important'
  },
  userCollapseLinks: {
    marginTop: '-4px'
  }
})

export default headerLinksStyle
