import PropTypes from 'prop-types'

import { Box } from '@material-ui/core'
import { styled, Typography } from '@material-ui/core'

import { fontRegular } from '@Common/jss/'

import MyChip from './MyChip'

const LabelEmail = styled(Typography)(() => ({
  fontSize: 14,
  ...fontRegular,
  fontWeight: 400,
  color: '#2C2C2C',
  marginRight: 9
}))

const LabelRadio = ({ email, isMy }) => {
  return (
    <Box display="flex">
      <LabelEmail>{email}</LabelEmail>
      {isMy && <MyChip label="My Account" />}
    </Box>
  )
}

LabelRadio.propTypes = {
  email: PropTypes.string,
  isMy: PropTypes.bool
}

export default LabelRadio
