export default {
  'particular.profile.country.placeholder': 'Country',
  'particular.profile.city.placeholder': 'City',
  'particular.profile.street.placeholder': 'Address',
  'particular.profile.floor.placeholder': 'Floor',
  'particular.profile.room.placeholder': 'Room',
  'particular.profile.country.required': 'Country is required',
  'particular.profile.city.required': 'City is required',
  'particular.profile.street.required': 'Address is required',
  'particular.profile.floor.required': 'Floor is required',
  'particular.profile.room.required': 'Room is required',
  'particular.profile.device-name.required': 'Device Name is required'
}
