import { fontRegular, successColor } from '../index'

const generateNumStyle = () => ({
  root: {
    color: '#5F5F5F',
    cursor: 'text',
    display: 'inline-flex',
    position: 'relative',
    fontSize: '14px',
    boxSizing: 'border-box',
    alignItems: 'center',
    ...fontRegular,
    fontWeight: 400,
    lineHeight: '1.1876em',
    letterSpacing: '0.00938em',
    boxShadow: 'none',
    border: '1px solid #D3D8DC',
    '&:hover': {
      borderColor: successColor[0]
    }
    // borderRadius: '4px'
  },
  input: {
    '& > input': {
      height: '34px',
      padding: '8.5px',
      fontSize: 24,
      ...fontRegular,
      color: '#D3D8DC',
      textAlign: 'center'
    }
  },
  handle: {
    display: 'flex',
    height: '100%',
    flexDirection: 'column',
    justifyContent: 'center',
    borderLeft: '1px solid #D3D8DC'
  },
  addBtn: {
    width: '30px !important',
    height: '24px !important',
    padding: '3px !important',
    color: '#6D6E71',
    '& > svg': {
      width: '1rem',
      height: '1rem'
    }
  },
  reduceBtn: {
    width: '30px !important',
    height: '24px !important',
    padding: '3px !important',
    color: '#6D6E71',
    '& > svg': {
      width: '1rem',
      height: '1rem'
    }
  }
})

export default generateNumStyle
