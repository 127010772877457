import { useState } from 'react'

import {
  Box,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  styled
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import LabelRadio from './LabelRadio'

const BoxData = styled(Box)(() => ({
  maxHeight: 140,
  overflowY: 'auto',
  '& .MuiFormControl-root': {
    display: 'flex'
  }
}))

const RadioFormLabel = styled(FormControlLabel)(() => ({
  fontSize: 14,

  margin: 0,
  height: 50
}))

const useStyles = makeStyles({
  root: {
    '& .MuiFormControlLabel-root:not(:last-child)': {
      borderBottom: '1px solid #EBEBEB'
    }
  }
})

const Emails = () => {
  const classes = useStyles()

  let arr = [...Array.from({ length: 8 }).keys()]
  const [value, setValue] = useState(0)

  const handleChange = event => {
    setValue(event.target.value - 0)
  }

  return (
    <BoxData>
      <FormControl
        class={classes.root}
        component="div"
      >
        <RadioGroup
          aria-label="gender"
          name="gender1"
          value={value}
          onChange={handleChange}
        >
          {arr.map(item => {
            return (
              <RadioFormLabel
                key={item}
                control={<Radio size="small" />}
                value={item}
                label={
                  <LabelRadio
                    email={`wei***u@dten.com.cn`}
                    isMy
                  />
                }
              />
            )
          })}
        </RadioGroup>
      </FormControl>
    </BoxData>
  )
}

export default Emails
