import clsx from 'clsx'
import PropTypes from 'prop-types'

import { FormattedMessage } from 'react-intl'

// @material-ui/core component
import { makeStyles } from '@material-ui/core/styles'

const LoadingStyles = makeStyles({
  root: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '50px 0',
    color: '#2C2C2C'
  },
  loading: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#2C2C2C'
  },
  icon: {
    width: '1em',
    height: '1em',
    verticalAlign: '-0.15em',
    fill: 'currentColor',
    overflow: 'hidden',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '48px',
    transformOrigin: 'center center',
    transition: '0.5s',
    animation: 'rotate 1s linear infinite'
  },
  tip: {
    marginTop: '20px'
  }
})

const Loading = ({ ...props }) => {
  const classes = LoadingStyles()
  const { className } = props
  const { tip } = props
  return (
    <div className={clsx(classes.root, className)}>
      <div className={classes.loading}>
        <svg
          aria-hidden="true"
          className={classes.icon}
        >
          <use xlinkHref="#icon-loading" />
        </svg>
      </div>
      {tip && <p className={classes.tip}>{tip}</p>}
    </div>
  )
}

Loading.defaultProps = {
  tip: <FormattedMessage id="loading.tip" />
}

Loading.propTypes = {
  tip: PropTypes.node.isRequired,
  className: PropTypes.string
}

export default Loading
