import PropTypes from 'prop-types'

import { Children } from 'react'
import { IntlProvider } from 'react-intl'

// import defined messages in Chinese
import en_US from '../Locales/en-US'
import zh_CN from '../Locales/zh-CN'

// const baseNavigator = true
const useLocalStorage = true

const localeInfo = {
  'en-US': {
    messages: en_US,
    locale: 'en-US'
  },
  'zh-CN': {
    messages: zh_CN,
    locale: 'zh-CN'
  }
}
let appLocale = {
  locale: 'en-US',
  messages: en_US
}

if (
  useLocalStorage &&
  localStorage.getItem('locale') &&
  localeInfo[localStorage.getItem('locale')]
) {
  appLocale = localeInfo[localStorage.getItem('locale')]
}
// else if (localeInfo[navigator.language] && baseNavigator) {
//   appLocale = localeInfo[navigator.language]
// }
else {
  appLocale = localeInfo['en-US'] || appLocale
}

const LocalesProvider = props => {
  const { children } = props
  return (
    <IntlProvider
      locale={appLocale.locale}
      messages={appLocale.messages}
    >
      {Children.only(children)}
    </IntlProvider>
  )
}

LocalesProvider.propTypes = {
  children: PropTypes.node
}

export default LocalesProvider
