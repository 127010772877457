import PropTypes from 'prop-types'

import { forwardRef } from 'react'

const SidebarWrapper = forwardRef((props, ref) => {
  const { className, user, headerLinks, links } = props
  return (
    <div
      ref={ref}
      className={className}
    >
      {user}
      {headerLinks}
      {links}
    </div>
  )
})

SidebarWrapper.displayName = 'SidebarWrapper'

SidebarWrapper.propTypes = {
  className: PropTypes.string,
  user: PropTypes.node,
  headerLinks: PropTypes.node,
  links: PropTypes.node
}

export default SidebarWrapper
