import PropTypes from 'prop-types'

import { VIEWBOX_CENTER_X, VIEWBOX_CENTER_Y } from './constants'

function Path({
  className,
  counterClockwise,
  dashRatio,
  pathRadius,
  strokeWidth,
  style
}) {
  return (
    <path
      className={className}
      fillOpacity={0}
      strokeWidth={strokeWidth}
      d={getPathDescription({
        pathRadius,
        counterClockwise
      })}
      style={Object.assign(
        {},
        style,
        getDashStyle({ pathRadius, dashRatio, counterClockwise })
      )}
    />
  )
}

Path.propTypes = {
  className: PropTypes.string,
  to: PropTypes.string,
  counterClockwise: PropTypes.any,
  dashRatio: PropTypes.any,
  pathRadius: PropTypes.any,
  strokeWidth: PropTypes.number,
  style: PropTypes.object
}

// SVG path description specifies how the path should be drawn
function getPathDescription({ pathRadius, counterClockwise }) {
  const radius = pathRadius
  const rotation = counterClockwise ? 1 : 0

  // Move to center of canvas
  // Relative move to top canvas
  // Relative arc to bottom of canvas
  // Relative arc to top of canvas
  return `
      M ${VIEWBOX_CENTER_X},${VIEWBOX_CENTER_Y}
      m 0,-${radius}
      a ${radius},${radius} ${rotation} 1 1 0,${2 * radius}
      a ${radius},${radius} ${rotation} 1 1 0,-${2 * radius}
    `
}

function getDashStyle({ counterClockwise, dashRatio, pathRadius }) {
  const diameter = Math.PI * 2 * pathRadius
  const gapLength = (1 - dashRatio) * diameter

  return {
    // Have dash be full diameter, and gap be full diameter
    strokeDasharray: `${diameter}px ${diameter}px`,
    // Shift dash backward by gapLength, so gap starts appearing at correct distance
    strokeDashoffset: `${counterClockwise ? -gapLength : gapLength}px`
  }
}

export default Path
