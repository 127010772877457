// import { useObserver } from 'mobx-react'
import { Base64 } from 'js-base64'
import PropTypes from 'prop-types'

import { forwardRef, useMemo } from 'react'
import { useParams } from 'react-router-dom'
// import classNames from 'clsx'
import { NavLink as RouterNavLink, useHistory } from 'react-router-dom'

import { makeStyles } from '@material-ui/core/styles'
// @material-ui/icons
import { ChevronRight } from '@material-ui/icons'

import GridContainer from '@Components/Grid/GridContainer'
// Custom Component
// import Card from '@Components/Card/Card'
// import CardContent from '@Components/Card/CardContent'
// import Loading from '@Components/Loading/Loading'
import GridItem from '@Components/Grid/GridItem'
import Muted from '@Components/Typography/Muted'
import Typography from '@Components/Typography/Typography'

// Custom Context
import DeviceContext from '@Context/DeviceContext'

import { useStore } from '@Hooks/useStore'

const detailsStyle = () => ({
  wrapper: {
    paddingBottom: 50
  },
  root: {
    padding: '30px 50px'
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    padding: '0 0 30px 0',
    color: 'rgba(95,95,95,1)'
  },
  link: {
    fontSize: 'inherit',
    fontFamily: '"Lato Bold", "Lato-Bold", Lato, sans-serif',
    fontWeight: 'bold',
    color: 'inherit',
    '&:hover': {
      cursor: 'pointer'
    }
  },
  muted: {
    fontSize: 'inherit',
    color: 'inherit'
  }
})
const useStyles = makeStyles(detailsStyle)

const CustomRouterLink = forwardRef((props, ref) => (
  <RouterNavLink
    innerRef={ref}
    {...props}
  />
))

// function useQueryParamsData() {
//   const { route } = useStore()
//   return useObserver(() => ({
//     queryParams: route.queryParams
//   }))
// }

const TicketDetailsLayout = props => {
  const { children } = props
  const history = useHistory()
  const classes = useStyles()
  const { route } = useStore()
  const { ticketId } = useParams()
  const queryParams = route.queryParams

  // 获取查询参数
  const queryInfo = useMemo(() => {
    if (queryParams) {
      return JSON.parse(Base64.decode(queryParams))
    }
  }, [queryParams])

  const clickedHandle = () => {
    history.push('/support/tickets')
  }
  // 设置链接标题
  const getLinkTitle = () => {
    return 'Tickets'
  }
  return (
    <DeviceContext.Provider value={{ queryInfo: queryInfo }}>
      <GridContainer className={classes.wrapper}>
        <GridItem sm={12}>
          <Typography
            className={classes.title}
            color="textSecondary"
            component="h1"
            variant="h6"
          >
            <Muted
              className={classes.link}
              onClick={clickedHandle}
            >
              {getLinkTitle()}
            </Muted>
            <ChevronRight />
            <Muted className={classes.muted}>{`TicketNo-${ticketId}`}</Muted>
          </Typography>
        </GridItem>
        <GridItem sm={12}>{children}</GridItem>
      </GridContainer>
    </DeviceContext.Provider>
  )
}

CustomRouterLink.displayName = 'CustomRouterLink'

TicketDetailsLayout.propTypes = {
  children: PropTypes.node
}

export default TicketDetailsLayout
