import clsx from 'clsx'
import PropTypes from 'prop-types'

import { useRef } from 'react'

import Box from '@material-ui/core/Box'
import CssBaseline from '@material-ui/core/CssBaseline'
// @material-ui/core components
import Drawer from '@material-ui/core/Drawer'
import Hidden from '@material-ui/core/Hidden'
import { makeStyles } from '@material-ui/core/styles'

// Logo Image
import Logo from '@Common/images/orbitLogo.png'
import sidebarStyle from '@Common/jss/components/sidebarStyle'

import SidebarFooter from '../Footer/SidebarFooter'
import HeaderLinks from '../Navbar/HeaderLinks'
import Muted from '../Typography/Muted'
// Custom Components
import SidebarNav from './SidebarNav'
import SidebarWrapper from './SidebarWrapper'

const useStyles = makeStyles(sidebarStyle)

const Sidebar = props => {
  const {
    open,
    // variant,
    onClose,
    className,
    pages,
    handleLogOutClick,
    handleSalesRestoreClick
  } = props
  const mainPanel = useRef()
  const classes = useStyles()
  const arch = localStorage.getItem('arch')
  const brand = (
    <div className={classes.logo}>
      <a
        className={classes.logoMini}
        href="https://dten.com/"
        rel="noopener noreferrer"
        target="_blank"
      >
        <img
          alt="logo"
          className={classes.img}
          src={Logo}
        />
      </a>
    </div>
  )
  const linkRender = (
    <div className={clsx(classes.root, className)}>
      <div className={classes.links}>
        {pages.map(item => {
          const boxClassName = item.name ? item.name.toLowerCase() : 'item'
          return (
            <Box
              key={item.id}
              className={classes[boxClassName]}
            >
              {item.name !== 'Support Virtual Parent' ? (
                <Muted className={classes.header}>{item.name}</Muted>
              ) : null}
              <SidebarNav pages={item.routes} />
            </Box>
          )
        })}
      </div>
      <div className={classes.extension}>
        <SidebarFooter />
      </div>
    </div>
  )
  return (
    <div ref={mainPanel}>
      <CssBaseline />
      <Hidden
        implementation="css"
        mdDown
      >
        <Drawer
          anchor="left"
          classes={{ paper: classes.drawer }}
          variant={'permanent'}
          ModalProps={{
            keepMounted: true // Better open performance on mobile.
          }}
          open
          onClose={onClose}
        >
          {arch !== 'unknown' && (
            <SidebarWrapper
              className={classes.sidebarWrapper}
              links={linkRender}
            />
          )}
        </Drawer>
      </Hidden>
      <Hidden
        implementation="css"
        lgUp
      >
        <Drawer
          anchor="left"
          classes={{ paper: classes.drawer }}
          open={open}
          variant={'temporary'}
          ModalProps={{
            keepMounted: true // Better open performance on mobile.
          }}
          onClose={onClose}
        >
          {brand}
          <SidebarWrapper
            className={classes.sidebarWrapper}
            links={linkRender}
            headerLinks={
              <HeaderLinks
                handleLogOutClick={handleLogOutClick}
                handleSalesRestoreClick={handleSalesRestoreClick}
              />
            }
          />
        </Drawer>
      </Hidden>
    </div>
  )
}

Sidebar.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired,
  // variant: PropTypes.string.isRequired,
  pages: PropTypes.array,
  activationCodeHandle: PropTypes.func,
  handleLogOutClick: PropTypes.func,
  handleSalesRestoreClick: PropTypes.func
}

export default Sidebar
