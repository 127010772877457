import PropTypes from 'prop-types'

import DialogContent from 'src/Components/DialogContent/DialogContent'

const LogoutDialog = ({ closeHandle, confirmHandle, ...rest }) => {
  return (
    <DialogContent
      closeHandle={closeHandle}
      confirmHandle={confirmHandle}
      dialogContent={'Are you sure you want to sign out ?'}
      dialogTitle={'Sign out'}
      cancel
      {...rest}
    />
  )
}

LogoutDialog.propTypes = {
  closeHandle: PropTypes.func,
  confirmHandle: PropTypes.func
}

export default LogoutDialog
