/**
 * @author our_time
 * @data: 2020/5/14
 * @email: wangpj1029@163.com
 * @Description: config.js
 */

const BASE_HOST = {
  'orbit.dten.com': 'usProduction',
  'stage-orbit.dten.dev': 'usAwsStage',
  'orbit.displayten.com.cn': 'cnProd',
  'service.dtencn.com': 'cnStage',
  'test2.service.displayten.com.cn': 'd7Test',
  'test.service.displayten.com.cn': 'cnTest',
  'test-service.dtencn.com': 'cnTest1',
  'dev-service.dtencn.com': 'development',
  'orbit.dtencn.com': 'cnTest2'
  // localhost: 'development'
}

const BASE_URL = {
  usProduction: 'https://service.dten.com', // aws环境的 服务器数据请求地址
  usAwsStage: 'https://stage-service.dten.dev',
  cnProd: 'https://service.displayten.com.cn', //  stage环境,
  cnStage: 'https://service.dtencn.com', // aws环境的 服务器数据请求地址
  d7Test: 'https://test2.service.displayten.com.cn', // aws环境的 服务器数据请求地址
  cnTest: 'https://test.service.displayten.com.cn', // 测试环境地址
  cnTest1: 'https://test-service.dtencn.com', // 测试环境地址
  cnTest2: 'https://service.dtencn.com', // 国内正式环境地址2
  development: 'https://dev-service.dtencn.com' // 开发环境
}

export const SUFFIX_NAME =
  process.env.NODE_ENV === 'production'
    ? document.domain.includes('.dten.com')
      ? '.dten.com'
      : document.domain.includes('.dten.dev')
        ? '.dten.dev'
        : document.domain.includes('.dtencn.com')
          ? '.dtencn.com'
          : '.displayten.com.cn'
    : '.dev-service.dtencn.com'

export const API_DOMAIN =
  process.env.NODE_ENV === 'production'
    ? BASE_URL[BASE_HOST[document.domain]]
      ? BASE_URL[BASE_HOST[document.domain]]
      : `https://${document.domain}`
    : '/apis'

export const API_ROOT = `${API_DOMAIN}/console-service`
export const API_SUPPORT = `${API_DOMAIN}/dten-support`
export const API_SURVEY = `${API_DOMAIN}/survey-service`
export const API_DEVICE_OPERATOR = `${API_DOMAIN}/device-operator`
export const API_DEVICE_MANAGER = `${API_DOMAIN}/device-manager`
export const API_DEVICE_USER = `${API_DOMAIN}/user-service`
export const API_ORBIT_SETTING_SERVICE = `${API_DOMAIN}/OrbitSettingService`
export const API_SECURITY_SERVICE = `${API_DOMAIN}/security-service`
// widows 系统补丁
export const API_DEVICE_PATCH_SERVICE = `${API_DOMAIN}/device-patch-service`
export const API_DHC = `${API_DOMAIN}/dhc`
// 问卷

export const API_MARKET = `${API_DOMAIN}/marketing-service`
// 销售演示demo
// export const API_SALES_DEMO = `${API_DOMAIN}/device-operator/demo`
export const API_SIEM = `${API_DOMAIN}/siemapi`

export const RECAPTCHAKEY =
  process.env.NODE_ENV === 'production'
    ? '6LcVblMbAAAAAJUWdiQPPJwt7pghM3wqyQ1BYnYQ'
    : '6LfUZVYbAAAAAIIOSGkf72t4Rn5fMJfqfXi-ZZsh'

export const isUseReCaptcha = false

export const VERSION = '3.7.10'

export const showVersion = () => {
  window.orbit = { version: VERSION }
  console.log(window.orbit)
}

/* 海外生产环境 */
export const isUsProduction = window.location.origin.includes('.dten.com')
