/**
 * @author our_time
 * @data: 2020/10/31
 * @email: wangpj1029@163.com
 * @Description: 确认排除2
 * 升级包安装状态（0:正常 1:检测中，2：下载中，3：安装中，4：成功，5：下载失败/安装失败, 6阻塞 ,10 已下发升级指令）
 */

// 设备在线状态集合
export const installStatus = [3] // [1, 3, 6, 10]

/**
 * @description: 获取设备型号
 * D7 都是windows 其他都是 安卓
 * @param {*}
 * device
 * deviceType
 * productScreensize: 75 55 尺寸
 * productModel
 * productType
 * isDual 是否双屏
 *
 * @return {*}
 */

// 所有有icon图标的设备 (Icon的iconfont 使用全大写)
const dtenModelLogs = [`Mate`, `GO`, `ME`, `D7`, `ONBOARD`]

export const getDtenDeviceModel = equipment => {
  const {
    device = '',
    deviceType = '',
    productScreensize = '',
    productType = '',
    isDual = 0,
    matePair = '0',
    productModel
  } = equipment
  // dtenModel 和  icon图标 保持一致（使用全大写）
  // R1 目前没有图标
  // const models = ['D7', 'ME', 'ON', 'GO', 'Mate','ONBOARD','R1']
  const allModels = {
    D7: device === 'D7',
    R1: device === 'R1',
    ME: device === 'S1' && deviceType === '004',
    ON: device === 'S1' && ['002', '003'].includes(deviceType),
    GO: device === 'S1' && deviceType === '005',
    Mate: device === 'Mate',
    ONBOARD: productModel === 'ON BOARD'
  }
  let dtenModel = ''
  Object.keys(allModels).forEach(key => {
    const value = allModels[key]
    if (value) {
      dtenModel = key
    }
  })

  const dtenModelIcon = (
    dtenModelLogs.includes(dtenModel) ? dtenModel : 'D7'
  ).toUpperCase()
  const os = device === 'D7' ? 'Windows' : 'Android'

  /* 
	1. matePair  === 0 不允许恢复 恢复出厂，不允许升级app 和 os
	2. 只要是mate 设备 ，不允许 quick check 和 repair
	3. matePair  === 1 允许恢复 恢复出厂，允许升级app 和 os
	*/

  // 独立的 mate
  const isMateOnly = dtenModel === 'Mate' && matePair === '1'
  // mate 非独立的 (设备列表会看到2台设备 1台mate 1台go)
  const isMatePair = dtenModel === 'Mate' && matePair === '0'

  return {
    dtenModel,
    dtenModelIcon,
    isDual,
    os,
    productScreensize,
    productType,
    isMateOnly,
    isMatePair
  }
}

export const getDtenDeviceProductToTicket = (equipment = {}) => {
  /* 
	数据来源此接口
	/dten-support/tickets/getSubDefault?timezone=8

	  const allServerProducts = [
    {
      id: 900000114306,
      name: 'DTEN D7 55"',
      raw_name: 'DTEN D7 55"',
      value: 'dten_d7_55'
    },
    {
      id: 900000931726,
      name: 'DTEN D7 55" DUAL',
      raw_name: 'DTEN D7 55" DUAL',
      value: 'd7_55_dual'
    },
    {
      id: 900000114326,
      name: 'DTEN D7 75"',
      raw_name: 'DTEN D7 75"',
      value: 'dten_d7_75'
    },
    {
      id: 900000116766,
      name: 'DTEN 55" ON ',
      raw_name: 'DTEN 55" ON ',
      value: 'd7_on'
    },
    {
      id: 900002370563,
      name: 'DTEN D5',
      raw_name: 'DTEN D5',
      value: 'dten_d5'
    },
    {
      id: 900002062746,
      name: 'DTEN Go & Mate',
      raw_name: 'DTEN Go & Mate',
      value: 'dten_mate'
    },
    {
      id: 900000931746,
      name: 'DTEN ME',
      raw_name: 'DTEN ME',
      value: 'dten_me'
    },
    {
      id: 900011645403,
      name: 'DTEN ME Pro',
      raw_name: 'DTEN ME Pro',
      value: 'dten_me_pro'
    },
    {
      id: 900005980266,
      name: 'Orbit',
      raw_name: 'Orbit',
      value: 'orbit'
    },
    {
      id: 900007110663,
      name: 'DTEN onTV',
      raw_name: 'DTEN onTV',
      value: 'dten_ontv'
    },
    {
      id: 900000116826,
      name: 'DTEN Stylus',
      raw_name: 'DTEN Stylus',
      value: 'd5_stylus'
    },
    {
      id: 900000116806,
      name: 'DTEN Heckler Cart',
      raw_name: 'DTEN Heckler Cart',
      value: 'd5_heckler'
    },
    {
      id: 900000116846,
      name: 'Other',
      raw_name: 'Other',
      value: 'dten_other'
    },
    {
      id: 900000991166,
      name: 'Zoom related',
      raw_name: 'Zoom related',
      value: 'zoom_related'
    },
    {
      id: 900008149783,
      name: 'RS232',
      raw_name: 'RS232',
      value: 'rs232'
    },
		{
			"id": 900002062746,
			"name": "DTEN Mate",
			"raw_name": "DTEN Mate",
			"value": "dten_mate"
		}
  ]
	*/

  const {
    device = '',
    deviceType = '',
    productModel,
    productScreensize,
    isDual
  } = equipment
  console.log('equipment=', equipment)
  const allModels = {
    dten_d7_55: device === 'D7' && productScreensize === '55' && isDual === 0,
    d7_55_dual: device === 'D7' && productScreensize === '55' && isDual === 1,
    dten_d7_75: device === 'D7' && productScreensize === '75',
    d7_on:
      device === 'S1' &&
      ['002', '003'].includes(deviceType) &&
      productScreensize === '55',
    // Go & mate
    dten_gomate: device === 'S1' && deviceType === '005',
    dten_me: device === 'S1' && deviceType === '004' && productModel === 'ME',
    dten_me_pro:
      device === 'S1' && deviceType === '004' && productModel === 'ME Pro',
    dten_mate: device === 'Mate'
  }

  let productValue = ''
  Object.keys(allModels).forEach(key => {
    const value = allModels[key]
    if (value) {
      productValue = key
    }
  })

  return productValue
}
