import clsx from 'clsx'
import PropTypes from 'prop-types'

import { forwardRef } from 'react'

import { withStyles } from '@material-ui/core/styles'

import capitalize from '@Utils/capitalize'

const styles = {
  /* Styles applied to the root element. */
  root: {
    margin: 0,
    htmlFontSize: 16,
    fontFamily: '"Lato Regular", "Lato-Regular", "Lato", sans-serif',
    fontSize: 14,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 700,
    color: '#5F5F5F'
  },
  h1: {
    fontFamily: '"Lato Regular", "Lato-Regular", "Lato", sans-serif',
    fontWeight: 300,
    fontSize: '6rem',
    lineHeight: 1.167,
    letterSpacing: '-0.01562em'
  },
  h2: {
    fontFamily: '"Lato Regular", "Lato-Regular", "Lato", sans-serif',
    fontWeight: 300,
    fontSize: '3.75rem',
    lineHeight: 1.2,
    letterSpacing: '-0.00833em'
  },
  h3: {
    fontFamily: '"Lato Regular", "Lato-Regular", "Lato", sans-serif',
    fontWeight: 400,
    fontSize: '3rem',
    lineHeight: 1.167,
    letterSpacing: '0em'
  },
  h4: {
    fontFamily: '"Lato Regular", "Lato-Regular", "Lato", sans-serif',
    fontWeight: 400,
    fontSize: '2.125rem',
    lineHeight: 1.235,
    letterSpacing: '0.00735em'
  },
  h5: {
    fontFamily: '"Lato Regular", "Lato-Regular", "Lato", sans-serif',
    fontWeight: 400,
    fontSize: '1.5rem',
    lineHeight: 1.334,
    letterSpacing: '0em'
  },
  h6: {
    fontFamily: '"Lato Bold", "Lato-Bold", "Lato", sans-serif',
    fontWeight: 'bold',
    fontSize: '1.25rem',
    lineHeight: 1.6,
    letterSpacing: '0.0075em'
  },
  subtitle1: {
    fontFamily: '"Lato Regular", "Lato-Regular", "Lato", sans-serif',
    fontWeight: 400,
    fontSize: '1rem',
    lineHeight: 1.75,
    letterSpacing: '0.00938em'
  },
  subtitle2: {
    fontFamily: '"Lato Regular", "Lato-Regular", "Lato", sans-serif',
    fontWeight: 500,
    fontSize: '0.875rem',
    lineHeight: 1.57,
    letterSpacing: '0.00714em'
  },
  body1: {
    fontFamily: '"Lato Regular", "Lato-Regular", "Lato", sans-serif',
    fontWeight: 400,
    fontSize: '1rem',
    lineHeight: 1.5,
    letterSpacing: '0.00938em'
  },
  body2: {
    fontFamily: '"Lato Regular", "Lato-Regular", "Lato", sans-serif',
    fontWeight: 400,
    fontSize: '0.875rem',
    lineHeight: 1.43,
    letterSpacing: '0.01071em'
  },
  button: {
    fontFamily: '"Lato Regular", "Lato-Regular", "Lato", sans-serif',
    fontWeight: 500,
    fontSize: '0.875rem',
    lineHeight: 1.75,
    letterSpacing: '0.02857em',
    textTransform: 'uppercase'
  },
  caption: {
    fontFamily: '"Lato Regular", "Lato-Regular", "Lato", sans-serif',
    fontWeight: 400,
    fontSize: '0.75rem',
    lineHeight: 1.66,
    letterSpacing: '0.03333em'
  },
  overline: {
    fontFamily: '"Lato Regular", "Lato-Regular", "Lato", sans-serif',
    fontWeight: 400,
    fontSize: '0.75rem',
    lineHeight: 2.66,
    letterSpacing: '0.08333em',
    textTransform: 'uppercase'
  },
  alignLeft: {
    textAlign: 'left'
  },
  /* Styles applied to the root element if `align="center"`. */
  alignCenter: {
    textAlign: 'center'
  },
  /* Styles applied to the root element if `align="right"`. */
  alignRight: {
    textAlign: 'right'
  },
  /* Styles applied to the root element if `align="justify"`. */
  alignJustify: {
    textAlign: 'justify'
  },
  /* Styles applied to the root element if `nowrap={true}`. */
  noWrap: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  },
  /* Styles applied to the root element if `gutterBottom={true}`. */
  gutterBottom: {
    marginBottom: '0.35em'
  },
  /* Styles applied to the root element if `paragraph={true}`. */
  paragraph: {
    marginBottom: 16
  },
  /* Styles applied to the root element if `color="inherit"`. */
  colorInherit: {
    color: 'inherit'
  },
  /* Styles applied to the root element if `color="primary"`. */
  colorPrimary: {
    color: '#3f51b5'
  },
  /* Styles applied to the root element if `color="secondary"`. */
  colorSecondary: {
    color: '#f50057'
  },
  /* Styles applied to the root element if `color="textPrimary"`. */
  colorTextPrimary: {
    color: 'rgba(0, 0, 0, 0.87)'
  },
  /* Styles applied to the root element if `color="textSecondary"`. */
  colorTextSecondary: {
    color: 'rgba(95, 95, 95, 1)'
  },
  /* Styles applied to the root element if `color="error"`. */
  colorError: {
    color: '#f44336'
  },
  /* Styles applied to the root element if `display="inline"`. */
  displayInline: {
    display: 'inline'
  },
  /* Styles applied to the root element if `display="block"`. */
  displayBlock: {
    display: 'block'
  }
}

const defaultVariantMapping = {
  h1: 'h1',
  h2: 'h2',
  h3: 'h3',
  h4: 'h4',
  h5: 'h5',
  h6: 'h6',
  subtitle1: 'h6',
  subtitle2: 'h6',
  body1: 'p',
  body2: 'p'
}

const Typography = forwardRef((props, ref) => {
  const {
    align = 'inherit',
    classes,
    className,
    color = 'initial',
    component,
    display = 'initial',
    gutterBottom = false,
    noWrap = false,
    paragraph = false,
    variant = 'body1',
    variantMapping = defaultVariantMapping,
    ...other
  } = props
  const Component =
    component ||
    (paragraph
      ? 'p'
      : variantMapping[variant] || defaultVariantMapping[variant]) ||
    'span'
  return (
    <Component
      ref={ref}
      className={clsx(
        classes.root,
        {
          [classes[variant]]: variant !== 'inherit',
          [classes[`color${capitalize(color)}`]]: color !== 'initial',
          [classes.noWrap]: noWrap,
          [classes.gutterBottom]: gutterBottom,
          [classes.paragraph]: paragraph,
          [classes[`align${capitalize(align)}`]]: align !== 'inherit',
          [classes[`display${capitalize(display)}`]]: display !== 'initial'
        },
        className
      )}
      {...other}
    />
  )
})

Typography.propTypes = {
  align: PropTypes.oneOf(['center', 'inherit', 'justify', 'left', 'right']),
  children: PropTypes.node,
  classes: PropTypes.object,
  className: PropTypes.string,
  color: PropTypes.oneOf([
    'error',
    'inherit',
    'initial',
    'primary',
    'secondary',
    'textPrimary',
    'textSecondary'
  ]),
  component: PropTypes.elementType,
  display: PropTypes.oneOf(['block', 'initial', 'inline']),
  gutterBottom: PropTypes.bool,
  noWrap: PropTypes.bool,
  paragraph: PropTypes.bool,
  variant: PropTypes.oneOf([
    'body1',
    'body2',
    'button',
    'caption',
    'h1',
    'h2',
    'h3',
    'h4',
    'h5',
    'h6',
    'inherit',
    'overline',
    'subtitle1',
    'subtitle2'
  ]),
  variantMapping: PropTypes.shape({
    body1: PropTypes.string,
    body2: PropTypes.string,
    button: PropTypes.string,
    caption: PropTypes.string,
    h1: PropTypes.string,
    h2: PropTypes.string,
    h3: PropTypes.string,
    h4: PropTypes.string,
    h5: PropTypes.string,
    h6: PropTypes.string,
    overline: PropTypes.string,
    subtitle1: PropTypes.string,
    subtitle2: PropTypes.string
  })
}

Typography.displayName = 'Typography'

export default withStyles(styles)(Typography)
