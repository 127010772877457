import PropTypes from 'prop-types'

import DialogContent from '@Components/DialogContent/DialogContent'

import BoxText from './BoxText'

const UserGuideChooseMergeNextDialog = ({
  closeHandle,
  confirmHandle,
  ...rest
}) => {
  const { dialogInfo, gaTitle } = rest
  const { uniqueId, key } = dialogInfo
  return (
    <DialogContent
      cancelText={`Back`}
      closeHandle={closeHandle}
      confirmHandle={confirmHandle}
      confirmText={`Confirm`}
      dialogContent={<BoxText />}
      dialogTitle={key}
      gaTitle={gaTitle}
      uniqueId={uniqueId}
      width="505px"
      cancel
      {...rest}
    />
  )
}

UserGuideChooseMergeNextDialog.propTypes = {
  closeHandle: PropTypes.func,
  confirmHandle: PropTypes.func
}

export default UserGuideChooseMergeNextDialog
