// import { whiteColor } from '../index'

const feedbackLayoutStyle = theme => ({
  root: {
    width: '100%',
    height: '100%',
    position: 'relative'
  },
  main: {
    width: '100%',
    height: '100%',
    // background: whiteColor,
    background: '#F4F6F7',
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 30,
      paddingRight: 30
    }
  },
  content: {
    width: '100%',
    height: '100%',
    overflow: 'auto'
  }
})

export default feedbackLayoutStyle
