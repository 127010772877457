import PropTypes from 'prop-types'

import CssBaseline from '@material-ui/core/CssBaseline'
import Hidden from '@material-ui/core/Hidden'
// @material-ui/core Components
import { makeStyles } from '@material-ui/core/styles'

import Footer from '@Components/Footer/Footer'
// Custom Components
import GridContainer from '@Components/Grid/GridContainer'
import GridItem from '@Components/Grid/GridItem'

import dtenImg from '@Common/images/DTEN.png'
import LogoImg from '@Common/images/orbitLogo.png'
// Custom Css
import registrationLayoutStyle from '@Common/jss/layouts/registrationLayoutStyle'

import { getZendesk } from 'src/Utils/auth'

const useStyles = makeStyles(registrationLayoutStyle)

const isDten = getZendesk().isZendesk
const logo = isDten ? dtenImg : LogoImg

const RegistrationLayout = props => {
  const { children } = props
  const classes = useStyles()
  return (
    <GridContainer className={classes.root}>
      <GridItem className={classes.content}>
        <GridContainer component="main">
          <CssBaseline />
          <Hidden xsDown>
            <GridItem
              className={classes.guide}
              lg={9}
              md={8}
              sm={6}
              xs={false}
            />
          </Hidden>
          <GridItem
            className={classes.handle}
            elevation={6}
            lg={3}
            md={4}
            sm={6}
            square="true"
            xs={12}
          >
            <div className={classes.paper}>
              <div className={classes.logo}>
                <img
                  alt="logo"
                  src={logo}
                />
              </div>
              {children}
            </div>
          </GridItem>
        </GridContainer>
      </GridItem>
      <GridItem className={classes.footWrapper}>
        <Footer />
      </GridItem>
    </GridContainer>
  )
}
RegistrationLayout.propTypes = {
  children: PropTypes.node
}
export default RegistrationLayout
