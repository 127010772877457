import { Base64 } from 'js-base64'

import { getCookiesToken } from './cache'

const setUserId = () => {
  const token = getCookiesToken()
  if (token && token.split('.').length > 2) {
    const tokenString = Base64.decode(token.split('.')[1])
    const payloads = JSON.parse(tokenString)
    let { sub } = payloads
    const reg = /"userId"[\s]*:[\s]*(\d+)/
    let subInfo =
      typeof sub === 'string'
        ? JSON.parse(sub.replace(reg, '"userId":"$1"'))
        : sub
    return subInfo && subInfo.userId ? subInfo.userId : ''
  }
  return ''
}

export default setUserId
