import PropTypes from 'prop-types'

import { forwardRef } from 'react'
import { Helmet } from 'react-helmet'

const Page = forwardRef(({ title, children, ...rest }, ref) => {
  // const location = useLocation()
  //
  // const sendPageViewEvent = useCallback(() => {
  //   track.pageview({
  //     page_path: location.pathname
  //   })
  //   console.log(
  //     track.pageview({
  //       page_path: location.pathname
  //     })
  //   )
  // }, [location])
  //
  // useEffect(() => {
  //   sendPageViewEvent()
  // }, [sendPageViewEvent])

  return (
    <div
      ref={ref}
      {...rest}
    >
      <Helmet>
        <title>{title}</title>
      </Helmet>
      {children}
    </div>
  )
})

Page.displayName = 'Page'

Page.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string
}

export default Page
