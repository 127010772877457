import { getLocationQuery } from 'src/Utils'
import { removeLocalInfo } from 'src/Utils/cache'
import {
  PRODUCTION_DOMAIN,
  PRODUCTION_ZENDESK_DOMAIN,
  SPACE_ORDER_ID_KEY
} from 'src/Utils/constants'

import { getCookiesToken } from './cache'

const auth = () => {
  return {
    isAuthenticated: () => {
      return !!getCookiesToken()
    }
  }
}

/**
 * @description: space 龙武 邮件会带入地址栏参数?key, 如果登录则退出
 * 在用户登录时，把orderId传入登录接口
 * @param {*}
 * @return {*}
 */
export const isSpace = () => {
  if (
    window.location.href.includes('/links/accredit') ||
    window.location.href.includes('/device/e-authorization')
  ) {
    return false
  }
  const spaceOrderId = getLocationQuery('key')
  // console.log('key', spaceOrderId)
  if (spaceOrderId) {
    console.log('清除 登录状态')
    window.sessionStorage.setItem(SPACE_ORDER_ID_KEY, spaceOrderId)
    removeLocalInfo()
  }
  return !!spaceOrderId
}

export const getSpaceOrderIdSession = () => {
  const spaceOrderId = window.sessionStorage.getItem(SPACE_ORDER_ID_KEY)
  return spaceOrderId
}

export const clearIsSpace = () => {
  window.sessionStorage.removeItem(SPACE_ORDER_ID_KEY)
}

/**
 * @description:
 *
 * @param {*}
 * @return {*}
 */
export const getZendesk = info => {
  let jwt = ''
  if (info) {
    jwt = info.jwt
  }
  let zendeskBrandId = getLocationQuery('brand_id')
  let returnToDomain = getLocationQuery('return_to')
  if (returnToDomain) {
    try {
      returnToDomain = `${new URL(returnToDomain).origin}/`
    } catch (error) {
      console.log(error)
    }
  }
  // 海外生产需要硬编码固定域名
  if (window.location.host === PRODUCTION_DOMAIN) {
    returnToDomain = PRODUCTION_ZENDESK_DOMAIN
  }

  let return_to = ''
  if (window.location.search.includes('return_to')) {
    return_to = window.location.search.split('return_to=')[1]
    return_to = decodeURIComponent(return_to)
    console.log('return_to=====', decodeURIComponent(return_to))
  } else if (window.location.search.includes('redirect=')) {
    return_to = window.location.search.split('redirect=')[1]
  }

  const locationLink = window.location.search

  const removeLoginInfo = () => {
    if (getLocationQuery('return_to')) {
      console.log('清除 登录状态')
      removeLocalInfo()
    }
  }
  /*
	zendesk 跳转地址
	https://dten.zendesk.com/access/jwt?jwt=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJUT0tFTl9GTEFHIjoic2VjdXJpdHktaXNzdWVkLTRqd3QiLCJuYW1lIjoiY2wgbWEiLCJleHRlcm5hbF9pZCI6IjIwMDYxOTIwMzQzNzExODkiLCJpYXQiOjE2NDMwOTM2NzYsImVtYWlsIjoiY2xtYUBjbi5kdGVuLmNvbSIsImp0aSI6ImExZTgyY2I2LTViMDMtNGIyMi05ZGM0LTczODYzOThmMDI5MyJ9.haAqAXjc3dTw5jVbqUDkNSDqGxzsCbWL46ycBGQD7PQ&return_to=https%3A%2F%2Fhelp.dten.com%2Fhc%2Fzh-cn
	*/
  const zendeskUri = `${returnToDomain}access/jwt?jwt=${jwt}&return_to=${return_to}`
  const redirectUri = zendeskBrandId ? zendeskUri : return_to
  // console.log('redirectUri', redirectUri)
  return {
    isZendesk: zendeskBrandId ? true : false,
    zendeskBrandId,
    redirectUri,
    locationLink,
    removeLoginInfo
  }
}

export default auth
