import { fontRegular, grayColor, successColor } from '../index'

const footerStyle = {
  footer: {
    width: '100%',
    padding: '15px 32px'
  },
  border: {
    borderTop: '1px solid rgba(0,0,0,.0625)'
  },
  positionFixed: {
    position: 'fixed',
    bottom: 0,
    left: 'auto',
    right: 0,
    '@media print': {
      // Prevent the app bar to be visible on each printed page.
      position: 'absolute'
    }
  },
  /* Styles applied to the root element if `position="absolute"`. */
  positionAbsolute: {
    position: 'absolute',
    bottom: 0,
    left: 'auto',
    right: 0
  },
  /* Styles applied to the root element if `position="sticky"`. */
  positionSticky: {
    // ⚠️ sticky is not supported by IE 11.
    position: 'sticky',
    bottom: 0,
    left: 'auto',
    right: 0
  },
  /* Styles applied to the root element if `position="static"`. */
  positionStatic: {
    position: 'static'
  },
  /* Styles applied to the root element if `position="relative"`. */
  positionRelative: {
    position: 'relative'
  },
  footerContent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    color: grayColor[0],
    flexWrap: 'wrap',
    fontSize: '14px',
    '& > p': {
      fontSize: '12px',
      color: grayColor[1]
    },
    '&$linkWrapper, p': {
      lineHeight: '30px'
    },
    '&$address, &$hotLine, &$link': {
      ...fontRegular,
      fontWeight: 400,
      color: grayColor[1]
    }
  },
  line: {
    width: '1px',
    height: '14px',
    margin: '0 10px',
    background: grayColor[0],
    display: 'inline-block',
    verticalAlign: 'middle'
  },
  linkWrapper: {
    fontSize: '14px',
    padding: '0 30px',
    '& > a': {
      '&:hover': {
        color: successColor[0]
      }
    }
  },
  link: {},
  address: {
    textTransform: 'uppercase',
    margin: '0 30px'
  },
  addressScan: {
    textTransform: 'uppercase',
    marginRight: 23
  },
  hotLine: {},
  icon: {
    width: 12,
    marginRight: 4,
    verticalAlign: 'middle',
    color: grayColor[0]
  },
  text: {
    verticalAlign: 'middle'
  }
}

export default footerStyle
