import classNames from 'clsx'
import PropTypes from 'prop-types'

import CircularProgress from '@material-ui/core/CircularProgress'
import { makeStyles } from '@material-ui/core/styles'

// Inspired by the former Facebook spinners.
const useStylesFacebook = makeStyles(theme => ({
  root: {
    position: 'relative'
  },
  bottom: {
    color: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700]
  },
  top: {
    color: '#4EB857',
    animationDuration: '550ms',
    position: 'absolute',
    left: 0
  }
}))

const FacebookCircularProgress = props => {
  const { className, ...rest } = props
  const classes = useStylesFacebook()

  return (
    <div className={classNames(classes.root, className)}>
      <CircularProgress
        className={classes.bottom}
        size={30}
        thickness={2}
        variant="determinate"
        {...rest}
        value={100}
      />
      <CircularProgress
        className={classes.top}
        size={30}
        thickness={2}
        value={80}
        variant="determinate"
        classes={{
          circle: classes.circle
        }}
        disableShrink
        {...rest}
      />
    </div>
  )
}

FacebookCircularProgress.propTypes = {
  className: PropTypes.string
}

export default FacebookCircularProgress
