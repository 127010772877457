import { cloneDeep } from 'lodash'
import { action, makeObservable, observable } from 'mobx'

// import DateDayjsUtils from '@date-io/dayjs'
// const dateFns = new DateDayjsUtils()
// const date = dateFns.date()
// const initDates = [date.startOf('month'), date.startOf('day')]
class UsageStore {
  constructor(rootStore) {
    makeObservable(this, {
      queryParams: observable,
      setQueryParams: action.bound
    })

    this.rootStore = rootStore
  }

  queryParams = localStorage.getItem('usageQueryParams')
    ? JSON.parse(localStorage.getItem('usageQueryParams'))
    : {
        total: 0,
        limit: 50,
        page: 1
      }

  setQueryParams(values) {
    const result = { ...cloneDeep(this.queryParams), ...values }
    this.queryParams = result
    localStorage.setItem('usageQueryParams', JSON.stringify(result))
  }
}

export default UsageStore
