/**
 * @desc: PickersArrowSwitcher Component
 * @author: OurTime...
 * @date: 2021/1/8
 */
import clsx from 'clsx'
import PropTypes from 'prop-types'

import { forwardRef, memo } from 'react'

import { IconButton, useTheme } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
// eslint-disable-next-line no-restricted-imports
import { ArrowLeftIcon } from '@material-ui/pickers/_shared/icons/ArrowLeftIcon'
// eslint-disable-next-line no-restricted-imports
import { ArrowRightIcon } from '@material-ui/pickers/_shared/icons/ArrowRightIcon'

import Typography from '../Typography/Typography'

const styles = theme => ({
  root: {
    display: 'flex'
  },
  spacer: {
    width: theme.spacing(3)
  },
  hidden: {
    visibility: 'hidden'
  }
})

const PickersArrowSwitcher = forwardRef((props, ref) => {
  const {
    children,
    classes,
    className,
    components = {},
    componentsProps = {},
    isLeftDisabled,
    isLeftHidden,
    isRightDisabled,
    isRightHidden,
    leftArrowButtonText,
    onLeftClick,
    onRightClick,
    rightArrowButtonText,
    ...other
  } = props

  const theme = useTheme()
  const isRtl = theme.direction === 'rtl'

  const LeftArrowButton = components.LeftArrowButton || IconButton
  const leftArrowButtonProps = componentsProps.leftArrowButton || {}
  const LeftArrowIcon = components.LeftArrowIcon || ArrowLeftIcon

  const RightArrowButton = components.RightArrowButton || IconButton
  const rightArrowButtonProps = componentsProps.rightArrowButton || {}
  const RightArrowIcon = components.RightArrowIcon || ArrowRightIcon

  return (
    <div
      ref={ref}
      className={clsx(classes.root, className)}
      {...other}
    >
      <LeftArrowButton
        aria-hidden={isLeftHidden}
        aria-label={leftArrowButtonText}
        disabled={isLeftDisabled}
        edge="end"
        size="small"
        title={leftArrowButtonText}
        onClick={onLeftClick}
        {...leftArrowButtonProps}
        className={clsx(leftArrowButtonProps.className, {
          [classes.hidden]: isLeftHidden
        })}
      >
        {isRtl ? <RightArrowIcon /> : <LeftArrowIcon />}
      </LeftArrowButton>
      {children ? (
        <Typography
          component="span"
          variant="subtitle1"
        >
          {children}
        </Typography>
      ) : (
        <div className={classes.spacer} />
      )}
      <RightArrowButton
        aria-hidden={isRightHidden}
        aria-label={rightArrowButtonText}
        disabled={isRightDisabled}
        edge="start"
        size="small"
        title={rightArrowButtonText}
        onClick={onRightClick}
        {...rightArrowButtonProps}
        className={clsx(rightArrowButtonProps.className, {
          [classes.hidden]: isRightHidden
        })}
      >
        {isRtl ? <LeftArrowIcon /> : <RightArrowIcon />}
      </RightArrowButton>
    </div>
  )
})

PickersArrowSwitcher.propTypes = {
  children: PropTypes.node,
  classes: PropTypes.object,
  className: PropTypes.string,
  components: PropTypes.object,
  componentsProps: PropTypes.object,
  isLeftDisabled: PropTypes.bool,
  isLeftHidden: PropTypes.bool,
  isRightDisabled: PropTypes.bool,
  isRightHidden: PropTypes.bool,
  leftArrowButtonText: PropTypes.string,
  onLeftClick: PropTypes.func,
  onRightClick: PropTypes.func,
  rightArrowButtonText: PropTypes.string
}

PickersArrowSwitcher.displayName = 'PickersArrowSwitcher'

export default memo(
  withStyles(styles, { name: 'CustomPickersArrowSwitcher' })(
    PickersArrowSwitcher
  )
)
