import registrationBgMin from '../../images/registrationBg-min.jpg'
import { defaultFont } from '../index'

const registrationLayoutStyle = {
  root: {
    width: '100vw',
    height: '100vh',
    msOverflowStyle: 'none',
    overflow: '-moz-scrollbars-none',
    overflowY: 'auto'
  },
  content: {
    '&, & main': {
      width: '100%',
      height: '100%'
    }
  },
  footWrapper: {
    width: '100%',
    height: '60px',
    position: 'absolute',
    bottom: 0,
    background: '#FFF',
    zIndex: 9
  },
  guide: {
    background: `url(${registrationBgMin}) no-repeat center`,
    backgroundSize: 'cover',
    minHeight: 866
    // height: '100%'
  },
  handle: {
    //box-shadow: none,
  },
  paper: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    padding: '16px 25px 70px',
    height: '100%',
    position: 'relative',
    maxWidth: '360px',
    margin: '0 auto'
  },
  logo: {
    // width: '166px',
    height: '27px',
    margin: '18px 0',
    '& img': {
      // width: '100%',
      height: '100%'
    }
  },
  contact: {
    position: 'absolute',
    bottom: '0',
    left: '0',
    width: '100%',
    height: '58px',
    padding: '0 32px',
    color: '#7A7A7A',
    ...defaultFont,
    display: 'flex',
    alignItems: 'center'
  },
  contactUs: {
    marginLeft: '4px'
  }
}

export default registrationLayoutStyle
