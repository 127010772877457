import { styled, Typography } from '@material-ui/core'

import { fontRegular } from '@Common/jss/'

const ContentTypographyHead = styled(Typography)(() => ({
  color: '#2C2C2C',
  fontSize: '14px',
  ...fontRegular,
  lineHeight: '21px',
  fontWeight: 400
}))

const ContentTypographyFoot = styled(Typography)(() => ({
  color: '#9B9B9B',
  fontSize: '14px',
  ...fontRegular,
  lineHeight: '21px',
  fontWeight: 400,
  paddingTop: 30
}))

const BoxText = () => {
  return (
    <>
      <ContentTypographyHead>
        {`By choosing not to merge your accounts, your organization's accounts will continue to be used independently and the process will no longer remind you. Do you confirm?`}
      </ContentTypographyHead>

      <ContentTypographyFoot>
        {`* If you have chosen not to merge your accounts by mistake and wish to make a change, please contact DTEN.`}
      </ContentTypographyFoot>
    </>
  )
}

export default BoxText
