import PropTypes from 'prop-types'

// import classNames from 'clsx'
// @material-ui/core components
import Tooltip from '@material-ui/core/Tooltip'
import { makeStyles } from '@material-ui/core/styles'

// Css in Js
import tooltipStyle from '@Common/jss/components/tooltipStyle'

// Custom Utils
import elementAcceptingRef from './Utils/elementAcceptingRef'

const useStyles = makeStyles(tooltipStyle)

const CustomTooltip = props => {
  const {
    title,
    children,
    className,
    arrow,
    forwardedRef,
    toolTipClasses,
    hideTips = false,
    ...other
  } = props
  let classes = useStyles()
  if (toolTipClasses) {
    Object.assign(classes, toolTipClasses)
  }
  return hideTips ? (
    children
  ) : (
    <Tooltip
      ref={forwardedRef}
      arrow={arrow}
      classes={classes}
      title={title}
      {...other}
      className={className}
      interactive
    >
      {children}
    </Tooltip>
  )
}

CustomTooltip.defaultProps = {
  arrow: true
}

CustomTooltip.propTypes = {
  title: PropTypes.node.isRequired,
  children: elementAcceptingRef.isRequired,
  arrow: PropTypes.bool,
  className: PropTypes.string,
  forwardedRef: PropTypes.node,
  toolTipClasses: PropTypes.object,
  hideTips: PropTypes.bool
}

export default CustomTooltip
