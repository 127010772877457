import clsx from 'clsx'
import PropTypes from 'prop-types'

import { forwardRef } from 'react'

// CSS Module
import classes from './toolbarStyles.module.scss'

const Toolbar = forwardRef((props, ref) => {
  const {
    className,
    component: Component = 'div',
    disableGutters = false,
    variant = 'regular',
    ...other
  } = props

  return (
    <Component
      ref={ref}
      className={clsx(
        classes.root,
        classes[variant],
        {
          [classes.gutters]: !disableGutters
        },
        className
      )}
      {...other}
    />
  )
})

Toolbar.displayName = 'Toolbar'

Toolbar.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  component: PropTypes.elementType,
  disableGutters: PropTypes.bool,
  variant: PropTypes.oneOf(['regular', 'dense'])
}
export default Toolbar
