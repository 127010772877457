import classNames from 'clsx'
import PropTypes from 'prop-types'

import { forwardRef } from 'react'

// Css Module
import classes from './cardContentStyle.module.scss'

const CardContent = forwardRef((props, ref) => {
  const { className, component: Component = 'div', ...other } = props

  return (
    <Component
      ref={ref}
      className={classNames(classes.root, className)}
      {...other}
    />
  )
})

CardContent.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  component: PropTypes.elementType
}

CardContent.displayName = 'CardContent'

export default CardContent
