import PropTypes from 'prop-types'

// @material-ui/core
import { makeStyles } from '@material-ui/core/styles'

// Custom Components
import Card from '@Components/Card/Card'
import ActivateFooter from '@Components/Footer/ActivateFooter'
import Header from '@Components/Navbar/Header'

// CSS IN JS
import activateLayoutStyle from '@Common/jss/layouts/activateLayoutStyle'

const useStyles = makeStyles(activateLayoutStyle)
const ActivateLayout = props => {
  const { children } = props
  const classes = useStyles()
  return (
    <div className={classes.activeLayout}>
      <Header
        color={'transparent'}
        changeColorOnScroll={{
          height: 400,
          color: 'info'
        }}
        fixed
      />
      <main className={classes.main}>
        <Card className={classes.content}>{children}</Card>
      </main>
      <ActivateFooter />
    </div>
  )
}
ActivateLayout.propTypes = {
  children: PropTypes.node
}
export default ActivateLayout
