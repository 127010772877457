import classNames from 'clsx'
import { Base64 } from 'js-base64'
import PropTypes from 'prop-types'

import { forwardRef, Fragment, useEffect, useState } from 'react'
import {
  NavLink as RouterNavLink,
  useLocation,
  withRouter
} from 'react-router-dom'

import { List } from '@material-ui/core'
import Collapse from '@material-ui/core/Collapse'
import { makeStyles } from '@material-ui/core/styles'

import { useStore } from '@Hooks/useStore'

import { successColor } from '@Common/jss'

import SidebarCategory from './SidebarCategory'
import SidebarLink from './SidebarLink'

const useStyles = makeStyles(theme => {
  return {
    root: {},
    item: {
      display: 'flex',
      paddingTop: 0,
      paddingBottom: 0,
      '& a': {
        fontSize: '14px',
        fontWeight: 'bold',
        fontFamily: '"Lato Bold", "Lato-Bold", "Lato", sans-serif'
      }
    },
    button: {
      color: '#5F5F5F',
      padding: '5px 10px 5px 32px',
      justifyContent: 'flex-start',
      textTransform: 'none',
      // letterSpacing: 0,
      width: '100%',
      fontWeight: theme.typography.fontWeightBold,
      borderRadius: 0,
      fontSize: 14,
      fontFamily: '"Lato Bold", "Lato-Bold", "Lato", sans-serif'
    },
    beta: {
      fontWeight: 'normal',
      letterSpacing: 'normal',
      display: 'none'
    },
    node: {
      justifyContent: 'space-between',
      '& svg': {
        color: '#909399',
        width: '1rem',
        height: '1rem'
      }
    },
    icon: {
      color: theme.palette.icon,
      width: 24,
      height: 24,
      display: 'flex',
      alignItems: 'center',
      marginRight: theme.spacing(1),
      fontSize: 16,
      '& .fab,& .fas,& .far,& .fal,& svg,& .material-icons': {
        fontSize: 16
      }
    },
    active: {
      // color: 'rgba(95,95,95,1)',
      // background: 'rgba(244,245,247,1)',
      // borderLeft: '4px solid rgba(78,184,87,1)',
      color: successColor[0],
      fontFamily: '"Lato Bold", "Lato-Bold", "Lato", sans-serif',
      fontWeight: 'bold',
      '& $icon': {
        color: 'rgba(95,95,95,1)'
      }
    },
    section: {
      fontSize: '14px',
      fontFamily: '"Lato Regular", "Lato-Regular"", "Lato", sans-serif',
      fontWeight: 400,
      color: 'rgba(155,155,155,1)',
      lineHeight: '17px',
      padding: '16px 24px 4px'
    }
  }
})

const CustomRouterLink = forwardRef((props, ref) => (
  <RouterNavLink
    innerRef={ref}
    {...props}
  />
))

// 高亮左侧菜单
const getSpecialLink = (urlPath, itemUrl, locationHref, includePath) => {
  return itemUrl === urlPath && locationHref.includes(includePath)
}

const SidebarNav = props => {
  const { pages, className, location } = props
  const locations = useLocation()
  const classes = useStyles()
  const {
    route: { queryParams }
  } = useStore()

  const getChildrenOpen = itemUrl => {
    const { from } = queryParams
      ? JSON.parse(Base64.decode(queryParams))
      : { form: '' }
    const locationHref = window.location.href
    if (
      itemUrl === '/device/manage' &&
      locationHref.includes('detail') &&
      from === 'manage'
    ) {
      return true
    } else if (
      getSpecialLink('/authority', itemUrl, locationHref, '/authority')
    ) {
      return true
    } else if (
      getSpecialLink('/members/users', itemUrl, locationHref, '/pending')
    ) {
      return true
    } else if (
      getSpecialLink(
        '/service/partner/profile',
        itemUrl,
        locationHref,
        '/service/partner/permission'
      )
    ) {
      return true
    } else if (
      getSpecialLink(
        '/service/partner/profile',
        itemUrl,
        locationHref,
        '/service/partner/profile/search'
      )
    ) {
      return true
    } else if (
      getSpecialLink(
        '/service/partner/profile',
        itemUrl,
        locationHref,
        '/service/partner/profile/invite'
      )
    ) {
      return true
    } else if (
      getSpecialLink('/service/agreement', itemUrl, locationHref, '/change')
    ) {
      return true
    } else if (
      getSpecialLink('/service/agreement', itemUrl, locationHref, '/info')
    ) {
      return true
    } else if (
      getSpecialLink('/applications', itemUrl, locationHref, '/sign-on')
    ) {
      return true
    } else {
      return false
    }
  }

  const initOpenRoutes = () => {
    /* Open collapse element that matches current url */
    const { pathname } = location
    let _routes = {}

    pages.forEach((route, index) => {
      const isActive = () => {
        if (route.routes.length > 0) {
          const node = route.routes.some(item => item.url === pathname)
          if (node) {
            return node
          } else {
            const nodeDetail = route.routes.some(item =>
              getChildrenOpen(item.url)
            )
            if (nodeDetail) {
              return true
            }
          }
          return node
        }
        return pathname.indexOf(route.path) === 0
      }
      const isOpen = route.open
      const isHome = !!(pages.containsHome && pathname === '/')
      _routes = Object.assign({}, _routes, {
        [index]: isActive() || isOpen || isHome
      })
    })
    return _routes
  }
  const [openRoutes, setOpenRoutes] = useState(() => initOpenRoutes())
  const toggle = index => {
    // Collapse all elements
    Object.keys(openRoutes).forEach(
      item =>
        openRoutes[index] ||
        setOpenRoutes(openRoutes =>
          Object.assign({}, openRoutes, { [item]: false })
        )
    )
    // Toggle selected element
    setOpenRoutes(openRoutes =>
      Object.assign({}, openRoutes, { [index]: !openRoutes[index] })
    )
  }
  useEffect(() => {
    setOpenRoutes(() => initOpenRoutes())
  }, [locations])
  return (
    <List
      className={classNames(classes.root, className)}
      disablePadding
    >
      {pages.map((category, index) => {
        return (
          <Fragment key={index}>
            {category.routes && category.routes.length > 0 ? (
              <Fragment key={index}>
                {category.header ? (
                  <p className={classes.section}>{category.header}</p>
                ) : null}
                <SidebarCategory
                  button={true}
                  classes={classes}
                  icon={category.icon}
                  isCollapsable={true}
                  isOpen={!openRoutes[index]}
                  name={category.name}
                  path={category.url}
                  onClick={() => toggle(index)}
                />
                <Collapse
                  in={openRoutes[index]}
                  timeout="auto"
                  unmountOnExit
                >
                  {category.routes.map((route, index) => (
                    <SidebarLink
                      key={index}
                      badge={route.badge}
                      component={CustomRouterLink}
                      icon={route.icon}
                      name={route.name}
                      to={route.url}
                    />
                  ))}
                </Collapse>
              </Fragment>
            ) : (
              <SidebarCategory
                badge={category.badge}
                classes={classes}
                component={CustomRouterLink}
                icon={category.icon}
                name={category.name}
                path={category.url}
              />
            )}
          </Fragment>
        )
      })}
    </List>
  )
}

CustomRouterLink.displayName = 'CustomRouterLink'

SidebarNav.propTypes = {
  className: PropTypes.string,
  pages: PropTypes.array.isRequired,
  location: PropTypes.object
}

export default withRouter(SidebarNav)
