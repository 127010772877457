import { action, makeObservable, observable } from 'mobx'

class App {
  loadingApp = false

  constructor() {
    makeObservable(this, {
      loadingApp: observable,
      showLoadingApp: action.bound,
      hideLoadingApp: action.bound
    })
  }

  showLoadingApp() {
    this.loadingApp = true
  }

  hideLoadingApp() {
    this.loadingApp = false
  }
}

export default App
