import { useCallback, useDebugValue, useEffect, useMemo, useState } from 'react'

import { useOpenState } from './useOpenState'
import { useNow, useUtils } from './useUtils'

export function usePickerState(props, valueManager) {
  const {
    inputFormat,
    disabled,
    readOnly,
    onAccept,
    onChange,
    disableCloseOnSelect,
    value
  } = props

  if (!inputFormat) {
    throw new Error('inputFormat prop is required')
  }

  const now = useNow()
  const utils = useUtils()
  const { isOpen, setIsOpen } = useOpenState(props)
  const [pickerDate, setPickerDate] = useState(
    valueManager.parseInput(utils, props)
  )

  // Mobile keyboard view is a special case.
  // When it's open picker should work like closed, cause we are just showing text field
  const [isMobileKeyboardViewOpen, setMobileKeyboardViewOpen] = useState(false)

  useEffect(() => {
    const parsedDateValue = valueManager.parseInput(utils, props)
    setPickerDate(currentPickerDate => {
      if (
        !valueManager.areValuesEqual(utils, currentPickerDate, parsedDateValue)
      ) {
        return parsedDateValue
      }

      return currentPickerDate
    })
    // We need to react only on value change, because `date` could potentially return new Date() on each render
  }, [value, utils]) // eslint-disable-line

  const acceptDate = useCallback(
    (acceptedDate, needClosePicker) => {
      onChange(acceptedDate)

      if (needClosePicker) {
        setIsOpen(false)

        if (onAccept) {
          onAccept(acceptedDate)
        }
      }
    },
    [onAccept, onChange, setIsOpen]
  )

  const wrapperProps = useMemo(
    () => ({
      open: isOpen,
      onClear: () => acceptDate(valueManager.emptyValue, true),
      onAccept: () => acceptDate(pickerDate, true),
      onDismiss: () => setIsOpen(false),
      onSetToday: () => {
        setPickerDate(now)
        acceptDate(now, !disableCloseOnSelect)
      }
    }),
    [
      acceptDate,
      disableCloseOnSelect,
      isOpen,
      now,
      pickerDate,
      setIsOpen,
      valueManager.emptyValue
    ]
  )

  const pickerProps = useMemo(
    () => ({
      date: pickerDate,
      isMobileKeyboardViewOpen,
      toggleMobileKeyboardView: () =>
        setMobileKeyboardViewOpen(!isMobileKeyboardViewOpen),
      onDateChange: (newDate, wrapperVariant, selectionState = 'partial') => {
        setPickerDate(newDate)
        if (selectionState === 'partial') {
          acceptDate(newDate, false)
        }

        if (selectionState === 'finish') {
          const shouldCloseOnSelect = !(
            disableCloseOnSelect ?? wrapperVariant === 'mobile'
          )
          acceptDate(newDate, shouldCloseOnSelect)
        }
      }
    }),
    [acceptDate, disableCloseOnSelect, isMobileKeyboardViewOpen, pickerDate]
  )

  const inputProps = useMemo(
    () => ({
      onChange,
      inputFormat,
      open: isOpen,
      rawValue: value,
      openPicker: () => !readOnly && !disabled && setIsOpen(true)
    }),
    [onChange, inputFormat, isOpen, value, readOnly, disabled, setIsOpen]
  )

  const pickerState = { pickerProps, inputProps, wrapperProps }
  useDebugValue(pickerState, () => ({
    MuiPickerState: {
      pickerDate,
      other: pickerState
    }
  }))

  return pickerState
}
