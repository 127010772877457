import clsx from 'clsx'
import PropTypes from 'prop-types'

import { makeStyles } from '@material-ui/core/styles'

const styles = () => ({
  line: {
    width: '100%',
    borderBottom: '1px solid #EBEBEB',
    margin: 0,
    borderWidth: '0px 0px thin',
    borderStyle: 'solid',
    borderColor: '#EBEBEB'
  }
})

const useStyles = makeStyles(styles)

const Divider = props => {
  const classes = useStyles()
  const { style, classesDivider } = props
  return (
    <hr
      className={clsx(classes.line, classesDivider)}
      style={style}
    />
  )
}

Divider.defaultProps = {
  style: {}
}
Divider.propTypes = {
  style: PropTypes.object,
  classesDivider: PropTypes.string
}

export default Divider
