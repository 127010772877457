import { whiteColor } from '../index'

const tooltipStyle = {
  tooltip: {
    backgroundColor: whiteColor,
    fontSize: '14px',
    fontFamily: '"Lato Regular", "Lato-Regular", "Lato", sans-serif',
    fontWeight: '400',
    color: 'rgba(121, 125, 122, 1)',
    lineHeight: '24px',
    boxShadow: '0 2px 15px 0 rgba(128,128,128,0.16)',
    borderRadius: '4px',
    textAlign: 'left',
    maxWidth: '380px'
  },
  arrow: {
    backgroundColor: 'transparent',
    color: whiteColor
    // boxShadow: '0 2px 15px 0 rgba(128,128,128,0.16)'
  }
}

export default tooltipStyle
