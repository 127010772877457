import { fontBold, fontRegular, grayColor } from '../index'

const addDevicesStyles = () => ({
  root: {
    '& .MuiSvgIcon-root': {
      fontSize: 16
    }
  },
  addDevice: {
    ...fontBold,
    fontSize: '16px',
    textTransform: 'none',
    padding: '7px 21px'
  },
  contentDetails: {
    paddingTop: '20px'
  },
  codesNums: {},
  desc: {
    color: grayColor[18],
    lineHeight: '24px',
    wordWrap: 'break-word'
    // wordBreak: 'break-all'
  },
  remain: {
    color: grayColor[18]
  },
  content: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'left',
    padding: '20px 0 10px',
    flexDirection: 'column'
  },
  regulations: {
    ...fontRegular,
    fontWeight: 400,
    fontSize: 14,
    lineHeight: '24px',
    color: grayColor[1]
  },
  regulation1: {
    marginTop: 5,
    marginBottom: 36
  },
  regulation2: {
    marginTop: 2,
    marginBottom: 26
  },
  generate: {
    maxWidth: '130px'
  },
  sendCodesWrapper: {},
  box: {
    width: '100%',
    maxHeight: '132px',
    overflowY: 'auto',
    background: '#fafafa',
    padding: '16px',
    marginTop: '6px',
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'stretch',
    flexWrap: 'wrap'
  },
  codeItem: {
    flex: '0 0 33.33%',
    lineHeight: '22px',
    fontFamily: '"Lato Regular", "Lato-Regular", "Lato", sans-serif',
    '&:nth-child(3n+3)': {
      paddingLeft: 30
    },
    '&:nth-child(3n+2)': {
      paddingLeft: 25
    }
  },
  serialNumber: {
    color: '#939393',
    fontSize: '12px',
    marginRight: '5px',
    textAlign: 'right',
    width: '20px',
    display: 'inline-block'
  },
  code: {
    color: '#525252',
    fontSize: '14px'
  },
  fulfilled: {},
  fullwidth: {
    width: '100%',
    maxWidth: '100%',
    marginTop: 30
  },
  resend: {
    textDecoration: 'underline',
    cursor: 'pointer'
  },
  radios: {
    '& .MuiTypography-body1': {
      color: '#2C2C2C',
      fontSize: 14
    },
    '& .MuiFormControlLabel-root': {
      marginRight: 35
    }
  },
  label: {
    color: '#2C2C2C',
    fontSize: 14
  },
  expireDaysDescribe: {
    color: '#2C2C2C',
    fontSize: 14,
    '& .p-dropdown:not(.p-disabled):hover': {
      borderColor: '#D3D8DC'
    },
    '& .p-dropdown .p-dropdown-label': {
      padding: '2px 26px 2px 0',
      color: '#2C2C2C'
    },
    '& .MuiSvgIcon-root': {
      fontSize: 24
    }
  },
  select: {
    border: '1px solid #D3D8DC',
    borderRadius: '4px',
    fontSize: '14px',
    padding: '0 5px',
    margin: '0 6px'
  }
})

export default addDevicesStyles
