import axios from 'axios'
import dayjs from 'dayjs'
import Qs from 'qs'

import { warning } from '@Components/Snackbar/Snackbar'

import { getCookiesToken, removeLocalInfo } from '../Utils/cache'
import { API_ROOT } from '../config'

const timezone =
  (dayjs().utcOffset() || 0 - new Date().getTimezoneOffset()) / 60

// 未登录的接口，不需要拦截冻结用户
const filterFrozenApi = [
  '/login',
  '/register',
  '/signOut',
  '/catch/resetnewpassword',
  '/catch/sendresetpassword',
  '/user/checkUserExist'
]

const getHttpPath = str => {
  if (str.includes('?')) {
    return str.split('?')[0]
  } else {
    return str
  }
}

/**
 * @description: 获取url请求是否需要拦截冻结用户，跳转到登录页
 * 为了保证健壮性，全转为小写比较
 * @param {*} httpUrl http 请求地址
 * @return {*} bool
 */
const isFilterFrozenApi = httpUrl => {
  const apiUrl = getHttpPath(httpUrl).toLowerCase()
  const filtersFrozenApiArray = filterFrozenApi.map(item => item.toLowerCase())
  return filtersFrozenApiArray.includes(apiUrl)
}

const fetchRequest = axios.create({
  timeout: 30000,
  baseURL: API_ROOT
  // `transformRequest` allows changes to the request data before it is sent to the server
  // This is only applicable for request methods 'PUT', 'POST', 'PATCH' and 'DELETE'
  // The last function in the array must return a string or an instance of Buffer, ArrayBuffer,
  // FormData or Stream
  // You may modify the headers object.
})

fetchRequest.interceptors.request.use(
  config => {
    if (config.baseURL) {
      axios.defaults.baseURL = config.baseURL
    }
    config.headers = config.headers || {}
    // const userInfo = localStorage.getItem('userInfo')
    //   ? JSON.parse(localStorage.getItem('userInfo'))
    //   : { userKey: cookiesToken }
    // if (userInfo && userInfo.userKey)
    //   config.headers['X-Token'] = `${userInfo.userKey}`
    const cookiesToken = getCookiesToken()
    if (cookiesToken) {
      config.headers['X-Token'] = cookiesToken
    }
    if (config.method === 'get') {
      config.params = {
        timezone: timezone,
        ...config.params
      }
    } else {
      // 文件上传 ‘POST’
      if (config.data instanceof FormData) {
        config.data.append('timezone', timezone)
        Object.assign(config.headers, { 'Content-Type': 'multipart/form-data' })
      } else {
        // `transformRequest` 允许在向服务器发送前，修改请求数据
        // 只能用在 'PUT', 'POST' 和 'PATCH' 这几个请求方法
        // console.log('config', config)
        config.url = config.url.includes('?')
          ? `${config.url}&timezone=${timezone}`
          : `${config.url}?timezone=${timezone}`

        if (config.method === 'post') {
          config.transformRequest = [
            function (data) {
              if (config.headers['Content-Type'] === 'application/json') {
                return JSON.stringify(data)
              } else {
                return Qs.stringify(data)
              }
            }
          ]
        } else if (config.method === 'put') {
          config.transformRequest = [
            function (data) {
              return JSON.stringify(data)
            }
          ]
          Object.assign(config.headers, {
            'Content-Type': 'application/json'
          })
        }
      }
    }

    return config
  },
  error => {
    return Promise.reject(error)
  }
)
fetchRequest.interceptors.response.use(
  response => {
    const {
      data: { code, success }
    } = response
    if (!success && code === 'loginError') {
      window.location.href = '/registration/login'
    } else if (
      !success &&
      code === 'user_0016' &&
      !isFilterFrozenApi(response.config.url)
    ) {
      removeLocalInfo()
      window.location.pathname = '/'
    }

    if (code === 4003 || code === '4003') {
      warning(`Sorry, but you have no permission to do this. `)
      removeLocalInfo()
      window.location.pathname = '/'
    }

    if (code === 403) {
      removeLocalInfo()
      window.location.pathname = '/'
    }

    return response
  },
  error => {
    let response = error.response || ''
    let status = response.status || ''
    switch (status) {
      case 401:
      case 403:
        // feedback 未登录不需要自动重定向
        /* 此路由 /help/productsurvey 为重复路由，2022-02-10 帮张明明解决 邮件 404 问题 后续计划删除 */
        if (
          window.location.pathname.includes('/feedback/') ||
          window.location.pathname.includes('/help/')
        ) {
          return Promise.reject(error)
        }
        if (
          !Object.is(window.location.pathname, '/') &&
          !response.config.url.includes('/resetPasswordByVerifyCode')
        ) {
          removeLocalInfo()
          window.location.pathname = '/'
        }
        break
      case 408:
        console.log('error 408')
        break
      case 404:
        console.log('error 404')
        break
      case 500:
        console.log('error 500')
        break
      case 504:
        console.log('error 504')
        break
      default:
        console.log('error default')
    }
    return Promise.reject(error)
  }
)
export default fetchRequest
