import PropTypes from 'prop-types'

import { makeStyles } from '@material-ui/core/styles'

// CSS Modules
import feedbackLayoutStyle from '@Common/jss/layouts/feedbackLayoutStyle'

const useStyles = makeStyles(feedbackLayoutStyle)

const FeedbackLayout = props => {
  const { children } = props
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <main className={classes.main}>
        <div className={classes.content}>{children}</div>
      </main>
    </div>
  )
}

FeedbackLayout.propTypes = {
  children: PropTypes.element.isRequired
}

export default FeedbackLayout
