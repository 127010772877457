/* eslint-disable no-constant-condition */
import * as React from 'react'

import setRef from './setRef'
import useControlled from './useControlled'
import useEventCallback from './useEventCallback'
import useId from './useId'

// Give up on IE 11 support for this feature
function stripDiacritics(string) {
  return typeof string.normalize !== 'undefined'
    ? string.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
    : string
}

export function createFilterOptions(config = {}) {
  const {
    ignoreAccents = true,
    ignoreCase = true,
    limit,
    matchFrom = 'any',
    stringify,
    trim = false
  } = config

  return (options, { inputValue, getOptionLabel }) => {
    let input = trim ? inputValue.trim() : inputValue
    if (ignoreCase) {
      input = input.toLowerCase()
    }
    if (ignoreAccents) {
      input = stripDiacritics(input)
    }

    const filteredOptions = options.filter(option => {
      let candidate = (stringify || getOptionLabel)(option)
      if (ignoreCase) {
        candidate = candidate.toLowerCase()
      }
      if (ignoreAccents) {
        candidate = stripDiacritics(candidate)
      }

      return matchFrom === 'start'
        ? candidate.indexOf(input) === 0
        : candidate.indexOf(input) > -1
    })

    return typeof limit === 'number'
      ? filteredOptions.slice(0, limit)
      : filteredOptions
  }
}

// To replace with .findIndex() once we stop IE 11 support.
function findIndex(array, comp) {
  for (let i = 0; i < array.length; i += 1) {
    if (comp(array[i])) {
      return i
    }
  }

  return -1
}

const defaultFilterOptions = createFilterOptions()

// Number of options to jump in list box when pageup and pagedown keys are used.
const pageSize = 5

export default function useAutocomplete(props) {
  const {
    autoComplete = false,
    autoHighlight = false,
    autoSelect = false,
    blurOnSelect = false,
    clearOnBlur = !props.freeSolo,
    clearOnEscape = false,
    componentName = 'useAutocomplete',
    debug = false,
    defaultValue = props.multiple ? [] : null,
    disableClearable = false,
    disableCloseOnSelect = false,
    disabledItemsFocusable = false,
    disableListWrap = false,
    filterOptions = defaultFilterOptions,
    filterSelectedOptions = false,
    freeSolo = false,
    getOptionDisabled,
    getOptionLabel: getOptionLabelProp = option => option.label ?? option,
    getOptionSelected = (option, value) => option === value,
    groupBy,
    handleHomeEndKeys = !props.freeSolo,
    id: idProp,
    includeInputInList = false,
    inputValue: inputValueProp,
    multiple = false,
    onChange,
    onClose,
    onClear,
    onHighlightChange,
    onInputChange,
    onOpen,
    open: openProp,
    openOnFocus = true,
    options,
    selectOnFocus = !props.freeSolo,
    value: valueProp
  } = props

  const id = useId(idProp)

  let getOptionLabel = getOptionLabelProp

  if (process.env.NODE_ENV !== 'production') {
    getOptionLabel = option => {
      const optionLabel = getOptionLabelProp(option)
      if (typeof optionLabel !== 'string') {
        const erroneousReturn =
          optionLabel === undefined
            ? 'undefined'
            : `${typeof optionLabel} (${optionLabel})`
        console.error(
          `Material-UI: The \`getOptionLabel\` method of ${componentName} returned ${erroneousReturn} instead of a string for ${JSON.stringify(
            option
          )}.`
        )
      }
      return optionLabel
    }
  }

  const ignoreFocus = React.useRef(false)
  const firstFocus = React.useRef(true)
  const inputRef = React.useRef(null)
  const listboxRef = React.useRef(null)
  const [anchorEl, setAnchorEl] = React.useState(null)

  const [focusedTag, setFocusedTag] = React.useState(-1)
  const defaultHighlighted = autoHighlight ? 0 : -1
  const highlightedIndexRef = React.useRef(defaultHighlighted)

  const [value, setValueState] = useControlled({
    controlled: valueProp,
    default: defaultValue,
    name: componentName
  })
  const [inputValue, setInputValueState] = useControlled({
    controlled: inputValueProp,
    default: '',
    name: componentName,
    state: 'inputValue'
  })

  const [focused, setFocused] = React.useState(false)

  const resetInputValue = useEventCallback((event, newValue) => {
    let newInputValue
    if (multiple) {
      newInputValue = ''
    } else if (newValue == null) {
      newInputValue = ''
    } else {
      const optionLabel = getOptionLabel(newValue)
      newInputValue = typeof optionLabel === 'string' ? optionLabel : ''
    }

    if (inputValue === newInputValue) {
      return
    }

    setInputValueState(newInputValue)

    if (onInputChange) {
      onInputChange(event, newInputValue, 'reset')
    }
  })

  React.useEffect(() => {
    resetInputValue(null, value)
  }, [value, resetInputValue])

  const [open, setOpenState] = useControlled({
    controlled: openProp,
    default: false,
    name: componentName,
    state: 'open'
  })

  const [inputPristine, setInputPristine] = React.useState(true)

  const inputValueIsSelectedValue =
    !multiple && value != null && inputValue === getOptionLabel(value)

  const popupOpen = open

  const filteredOptions = popupOpen
    ? filterOptions(
        options.filter(option => {
          return !(
            filterSelectedOptions &&
            (multiple ? value : [value]).some(
              value2 => value2 !== null && getOptionSelected(option, value2)
            )
          )
        }),
        // we use the empty string to manipulate `filterOptions` to not filter any options
        // i.e. the filter predicate always returns true
        {
          inputValue:
            inputValueIsSelectedValue && inputPristine ? '' : inputValue,
          getOptionLabel
        }
      )
    : []

  const listboxAvailable = open && filteredOptions.length > 0

  if (process.env.NODE_ENV !== 'production') {
    if (value !== null && !freeSolo && options.length > 0) {
      const missingValue = (multiple ? value : [value]).filter(
        value2 => !options.some(option => getOptionSelected(option, value2))
      )

      if (missingValue.length > 0) {
        console.warn(
          [
            `Material-UI: The value provided to ${componentName} is invalid.`,
            `None of the options match with \`${
              missingValue.length > 1
                ? JSON.stringify(missingValue)
                : JSON.stringify(missingValue[0])
            }\`.`,
            'You can use the `getOptionSelected` prop to customize the equality test.'
          ].join('\n')
        )
      }
    }
  }

  const focusTag = useEventCallback(tagToFocus => {
    if (tagToFocus === -1) {
      inputRef.current.focus()
    } else {
      anchorEl.querySelector(`[data-tag-index="${tagToFocus}"]`).focus()
    }
  })

  // Ensure the focusedTag is never inconsistent
  React.useEffect(() => {
    if (multiple && focusedTag > value.length - 1) {
      setFocusedTag(-1)
      focusTag(-1)
    }
  }, [value, multiple, focusedTag, focusTag])

  function validOptionIndex(index, direction) {
    if (!listboxRef.current || index === -1) {
      return -1
    }

    let nextFocus = index

    while (true) {
      // Out of range
      if (
        (direction === 'next' && nextFocus === filteredOptions.length) ||
        (direction === 'previous' && nextFocus === -1)
      ) {
        return -1
      }

      const option = listboxRef.current.querySelector(
        `[data-option-index="${nextFocus}"]`
      )

      // Same logic as MenuList.js
      const nextFocusDisabled = disabledItemsFocusable
        ? false
        : !option ||
          option.disabled ||
          option.getAttribute('aria-disabled') === 'true'

      if ((option && !option.hasAttribute('tabindex')) || nextFocusDisabled) {
        // Move to the next element.
        nextFocus += direction === 'next' ? 1 : -1
      } else {
        return nextFocus
      }
    }
  }

  const setHighlightedIndex = useEventCallback(
    ({ event, index, reason = 'auto' }) => {
      highlightedIndexRef.current = index

      // does the index exist?
      if (index === -1) {
        inputRef.current.removeAttribute('aria-activedescendant')
      } else {
        inputRef.current.setAttribute(
          'aria-activedescendant',
          `${id}-option-${index}`
        )
      }

      if (onHighlightChange) {
        onHighlightChange(
          event,
          index === -1 ? null : filteredOptions[index],
          reason
        )
      }

      if (!listboxRef.current) {
        return
      }

      const prev = listboxRef.current.querySelector('[data-focus]')
      if (prev) {
        prev.removeAttribute('data-focus')
      }

      const listboxNode =
        listboxRef.current.parentElement.querySelector('[role="listbox"]')

      // "No results"
      if (!listboxNode) {
        return
      }

      if (index === -1) {
        listboxNode.scrollTop = 0
        return
      }

      const option = listboxRef.current.querySelector(
        `[data-option-index="${index}"]`
      )

      if (!option) {
        return
      }

      option.setAttribute('data-focus', 'true')

      // Scroll active descendant into view.
      // Logic copied from https://www.w3.org/TR/wai-aria-practices/examples/listbox/js/listbox.js
      //
      // Consider this API instead once it has a better browser support:
      // .scrollIntoView({ scrollMode: 'if-needed', block: 'nearest' });
      if (
        listboxNode.scrollHeight > listboxNode.clientHeight &&
        reason !== 'mouse'
      ) {
        const element = option

        const scrollBottom = listboxNode.clientHeight + listboxNode.scrollTop
        const elementBottom = element.offsetTop + element.offsetHeight
        if (elementBottom > scrollBottom) {
          listboxNode.scrollTop = elementBottom - listboxNode.clientHeight
        } else if (
          element.offsetTop - element.offsetHeight * (groupBy ? 1.3 : 0) <
          listboxNode.scrollTop
        ) {
          listboxNode.scrollTop =
            element.offsetTop - element.offsetHeight * (groupBy ? 1.3 : 0)
        }
      }
    }
  )

  const changeHighlightedIndex = useEventCallback(
    ({ event, diff, direction = 'next', reason = 'auto' }) => {
      if (!popupOpen) {
        return
      }

      const getNextIndex = () => {
        const maxIndex = filteredOptions.length - 1

        if (diff === 'reset') {
          return defaultHighlighted
        }

        if (diff === 'start') {
          return 0
        }

        if (diff === 'end') {
          return maxIndex
        }

        const newIndex = highlightedIndexRef.current + diff

        if (newIndex < 0) {
          if (newIndex === -1 && includeInputInList) {
            return -1
          }

          if (
            (disableListWrap && highlightedIndexRef.current !== -1) ||
            Math.abs(diff) > 1
          ) {
            return 0
          }

          return maxIndex
        }

        if (newIndex > maxIndex) {
          if (newIndex === maxIndex + 1 && includeInputInList) {
            return -1
          }

          if (disableListWrap || Math.abs(diff) > 1) {
            return maxIndex
          }

          return 0
        }

        return newIndex
      }

      const nextIndex = validOptionIndex(getNextIndex(), direction)
      setHighlightedIndex({ index: nextIndex, reason, event })

      // Sync the content of the input with the highlighted option.
      if (autoComplete && diff !== 'reset') {
        if (nextIndex === -1) {
          inputRef.current.value = inputValue
        } else {
          const option = getOptionLabel(filteredOptions[nextIndex])
          inputRef.current.value = option

          // The portion of the selected suggestion that has not been typed by the user,
          // a completion string, appears inline after the input cursor in the textbox.
          const index = option.toLowerCase().indexOf(inputValue.toLowerCase())
          if (index === 0 && inputValue.length > 0) {
            inputRef.current.setSelectionRange(inputValue.length, option.length)
          }
        }
      }
    }
  )

  const syncHighlightedIndex = React.useCallback(() => {
    if (!popupOpen) {
      return
    }

    const valueItem = multiple ? value[0] : value

    // The popup is empty, reset
    if (filteredOptions.length === 0 || valueItem == null) {
      changeHighlightedIndex({ diff: 'reset' })
      return
    }

    if (!listboxRef.current) {
      return
    }

    // Synchronize the value with the highlighted index
    if (valueItem != null) {
      const currentOption = filteredOptions[highlightedIndexRef.current]

      // Keep the current highlighted index if possible
      if (
        multiple &&
        currentOption &&
        findIndex(value, val => getOptionSelected(currentOption, val)) !== -1
      ) {
        return
      }

      const itemIndex = findIndex(filteredOptions, optionItem =>
        getOptionSelected(optionItem, valueItem)
      )
      if (itemIndex === -1) {
        changeHighlightedIndex({ diff: 'reset' })
      } else {
        setHighlightedIndex({ index: itemIndex })
      }
      return
    }

    // Prevent the highlighted index to leak outside the boundaries.
    if (highlightedIndexRef.current >= filteredOptions.length - 1) {
      setHighlightedIndex({ index: filteredOptions.length - 1 })
      return
    }

    // Restore the focus to the previous index.
    setHighlightedIndex({ index: highlightedIndexRef.current })
    // Ignore filteredOptions (and options, getOptionSelected, getOptionLabel) not to break the scroll position
  }, [
    // Only sync the highlighted index when the option switch between empty and not
    filteredOptions.length === 0,
    // Don't sync the highlighted index with the value when multiple
    multiple ? false : value,
    filterSelectedOptions,
    changeHighlightedIndex,
    setHighlightedIndex,
    popupOpen,
    inputValue,
    multiple
  ])

  const handleListboxRef = useEventCallback(node => {
    setRef(listboxRef, node)

    if (!node) {
      return
    }

    syncHighlightedIndex()
  })

  React.useEffect(() => {
    syncHighlightedIndex()
  }, [syncHighlightedIndex])

  const handleOpen = event => {
    if (open) {
      return
    }

    setOpenState(true)
    setInputPristine(true)

    if (onOpen) {
      onOpen(event)
    }
  }

  const handleClose = (event, reason) => {
    if (!open) {
      return
    }

    setOpenState(false)

    if (onClose) {
      onClose(event, reason)
    }
  }

  const handleValue = (event, newValue, reason, details) => {
    if (value === newValue) {
      return
    }

    if (onChange) {
      onChange(event, newValue, reason, details)
    }

    setValueState(newValue)
  }

  const isTouch = React.useRef(false)

  const selectNewValue = (
    event,
    option,
    reasonProp = 'select-option',
    origin = 'options'
  ) => {
    let reason = reasonProp
    let newValue = option

    if (multiple) {
      newValue = Array.isArray(value) ? value.slice() : []

      if (process.env.NODE_ENV !== 'production') {
        const matches = newValue.filter(val => getOptionSelected(option, val))

        if (matches.length > 1) {
          console.error(
            [
              `Material-UI: The \`getOptionSelected\` method of ${componentName} do not handle the arguments correctly.`,
              `The component expects a single value to match a given option but found ${matches.length} matches.`
            ].join('\n')
          )
        }
      }

      const itemIndex = findIndex(newValue, valueItem =>
        getOptionSelected(option, valueItem)
      )

      if (itemIndex === -1) {
        newValue.push(option)
      } else if (origin !== 'freeSolo') {
        newValue.splice(itemIndex, 1)
        reason = 'remove-option'
      }
    }

    resetInputValue(event, newValue)

    handleValue(event, newValue, reason, { option })
    if (!disableCloseOnSelect && !event.ctrlKey && !event.metaKey) {
      handleClose(event, reason)
    }

    if (
      blurOnSelect === true ||
      (blurOnSelect === 'touch' && isTouch.current) ||
      (blurOnSelect === 'mouse' && !isTouch.current)
    ) {
      inputRef.current.blur()
    }
  }

  function validTagIndex(index, direction) {
    if (index === -1) {
      return -1
    }

    let nextFocus = index

    while (true) {
      // Out of range
      if (
        (direction === 'next' && nextFocus === value.length) ||
        (direction === 'previous' && nextFocus === -1)
      ) {
        return -1
      }

      const option = anchorEl.querySelector(`[data-tag-index="${nextFocus}"]`)

      // Same logic as MenuList.js
      if (
        !option ||
        !option.hasAttribute('tabindex') ||
        option.disabled ||
        option.getAttribute('aria-disabled') === 'true'
      ) {
        nextFocus += direction === 'next' ? 1 : -1
      } else {
        return nextFocus
      }
    }
  }

  const handleFocusTag = (event, direction) => {
    if (!multiple) {
      return
    }

    handleClose(event, 'toggleInput')

    let nextTag = focusedTag

    if (focusedTag === -1) {
      if (inputValue === '' && direction === 'previous') {
        nextTag = value.length - 1
      }
    } else {
      nextTag += direction === 'next' ? 1 : -1

      if (nextTag < 0) {
        nextTag = 0
      }

      if (nextTag === value.length) {
        nextTag = -1
      }
    }

    nextTag = validTagIndex(nextTag, direction)

    setFocusedTag(nextTag)
    focusTag(nextTag)
  }

  const handleClear = event => {
    ignoreFocus.current = true
    setInputValueState('')

    if (onInputChange) {
      onInputChange(event, '', 'clear')
    }

    handleValue(event, multiple ? [] : null, 'clear')
    if (onClear) {
      onClear()
    }
  }

  const handleKeyDown = other => event => {
    if (
      focusedTag !== -1 &&
      ['ArrowLeft', 'ArrowRight'].indexOf(event.key) === -1
    ) {
      setFocusedTag(-1)
      focusTag(-1)
    }

    switch (event.key) {
      case 'Home':
        if (popupOpen && handleHomeEndKeys) {
          // Prevent scroll of the page
          event.preventDefault()
          changeHighlightedIndex({
            diff: 'start',
            direction: 'next',
            reason: 'keyboard',
            event
          })
        }
        break
      case 'End':
        if (popupOpen && handleHomeEndKeys) {
          // Prevent scroll of the page
          event.preventDefault()
          changeHighlightedIndex({
            diff: 'end',
            direction: 'previous',
            reason: 'keyboard',
            event
          })
        }
        break
      case 'PageUp':
        // Prevent scroll of the page
        event.preventDefault()
        changeHighlightedIndex({
          diff: -pageSize,
          direction: 'previous',
          reason: 'keyboard',
          event
        })
        handleOpen(event)
        break
      case 'PageDown':
        // Prevent scroll of the page
        event.preventDefault()
        changeHighlightedIndex({
          diff: pageSize,
          direction: 'next',
          reason: 'keyboard',
          event
        })
        handleOpen(event)
        break
      case 'ArrowDown':
        // Prevent cursor move
        event.preventDefault()
        changeHighlightedIndex({
          diff: 1,
          direction: 'next',
          reason: 'keyboard',
          event
        })
        handleOpen(event)
        break
      case 'ArrowUp':
        // Prevent cursor move
        event.preventDefault()
        changeHighlightedIndex({
          diff: -1,
          direction: 'previous',
          reason: 'keyboard',
          event
        })
        handleOpen(event)
        break
      case 'ArrowLeft':
        handleFocusTag(event, 'previous')
        break
      case 'ArrowRight':
        handleFocusTag(event, 'next')
        break
      case 'Enter':
        // Wait until IME is settled.
        if (event.which === 229) {
          break
        }
        if (highlightedIndexRef.current !== -1 && popupOpen) {
          const option = filteredOptions[highlightedIndexRef.current]
          const disabled = getOptionDisabled ? getOptionDisabled(option) : false

          // We don't want to validate the form.
          event.preventDefault()

          if (disabled) {
            return
          }

          selectNewValue(event, option, 'select-option')

          // Move the selection to the end.
          if (autoComplete) {
            inputRef.current.setSelectionRange(
              inputRef.current.value.length,
              inputRef.current.value.length
            )
          }
        } else if (
          freeSolo &&
          inputValue !== '' &&
          inputValueIsSelectedValue === false
        ) {
          if (multiple) {
            // Allow people to add new values before they submit the form.
            event.preventDefault()
          }
          selectNewValue(event, inputValue, 'create-option', 'freeSolo')
        }
        break
      case 'Escape':
        if (popupOpen) {
          // Avoid Opera to exit fullscreen mode.
          event.preventDefault()
          // Avoid the Modal to handle the event.
          event.stopPropagation()
          handleClose(event, 'escape')
        } else if (
          clearOnEscape &&
          (inputValue !== '' || (multiple && value.length > 0))
        ) {
          // Avoid Opera to exit fullscreen mode.
          event.preventDefault()
          // Avoid the Modal to handle the event.
          event.stopPropagation()
          handleClear(event)
        }
        break
      case 'Backspace':
        if (multiple && inputValue === '' && value.length > 0) {
          const index = focusedTag === -1 ? value.length - 1 : focusedTag
          const newValue = value.slice()
          newValue.splice(index, 1)
          handleValue(event, newValue, 'remove-option', {
            option: value[index]
          })
        }
        break
      default:
    }

    if (other.onKeyDown) {
      other.onKeyDown(event)
    }
  }

  const handleFocus = event => {
    setFocused(true)

    if (openOnFocus && !ignoreFocus.current) {
      handleOpen(event)
    }
  }

  const handleBlur = event => {
    // Ignore the event when using the scrollbar with IE 11
    if (
      listboxRef.current !== null &&
      listboxRef.current.parentElement.contains(document.activeElement)
    ) {
      inputRef.current.focus()
      return
    }

    setFocused(false)
    firstFocus.current = true
    ignoreFocus.current = false

    if (debug && inputValue !== '') {
      return
    }

    if (autoSelect && highlightedIndexRef.current !== -1 && popupOpen) {
      selectNewValue(
        event,
        filteredOptions[highlightedIndexRef.current],
        'blur'
      )
    } else if (autoSelect && freeSolo && inputValue !== '') {
      selectNewValue(event, inputValue, 'blur', 'freeSolo')
    } else if (clearOnBlur) {
      resetInputValue(event, value)
    }

    handleClose(event, 'blur')
  }

  const handleInputChange = event => {
    const newValue = event.target.value

    if (inputValue !== newValue) {
      setInputValueState(newValue)
      setInputPristine(false)

      if (onInputChange) {
        onInputChange(event, newValue, 'input')
      }
    }

    if (newValue === '') {
      if (!disableClearable && !multiple) {
        handleValue(event, null, 'clear')
      }
    } else {
      handleOpen(event)
    }
  }

  const handleOptionMouseOver = event => {
    setHighlightedIndex({
      event,
      index: Number(event.currentTarget.getAttribute('data-option-index')),
      reason: 'mouse'
    })
  }

  const handleOptionTouchStart = () => {
    isTouch.current = true
  }

  const handleOptionClick = event => {
    const index = Number(event.currentTarget.getAttribute('data-option-index'))
    selectNewValue(event, filteredOptions[index], 'select-option')

    isTouch.current = false
  }

  const handleTagDelete = index => event => {
    const newValue = value.slice()
    newValue.splice(index, 1)
    handleValue(event, newValue, 'remove-option', {
      option: value[index]
    })
  }

  const handlePopupIndicator = event => {
    if (open) {
      handleClose(event, 'toggleInput')
    } else {
      handleOpen(event)
    }
  }

  // Prevent input blur when interacting with the combobox
  const handleMouseDown = event => {
    if (event.target.getAttribute('id') !== id) {
      event.preventDefault()
    }
  }

  // Focus the input when interacting with the combobox
  const handleClick = () => {
    inputRef.current.focus()

    if (
      selectOnFocus &&
      firstFocus.current &&
      inputRef.current.selectionEnd - inputRef.current.selectionStart === 0
    ) {
      inputRef.current.select()
    }

    firstFocus.current = false
  }

  // eslint-disable-next-line no-unused-vars
  const handleInputMouseDown = event => {
    if ((inputValue === '' || !open) && openOnFocus) {
      handlePopupIndicator(event)
    }
  }

  let dirty = freeSolo && inputValue.length > 0
  dirty = dirty || (multiple ? value.length > 0 : value !== null)

  let groupedOptions = filteredOptions
  if (groupBy) {
    // used to keep track of key and indexes in the result array
    const indexBy = new Map()
    let warn = false

    groupedOptions = filteredOptions.reduce((acc, option, index) => {
      const group = groupBy(option)

      if (acc.length > 0 && acc[acc.length - 1].group === group) {
        acc[acc.length - 1].options.push(option)
      } else {
        if (process.env.NODE_ENV !== 'production') {
          if (indexBy.get(group) && !warn) {
            console.warn(
              `Material-UI: The options provided combined with the \`groupBy\` method of ${componentName} returns duplicated headers.`,
              'You can solve the issue by sorting the options with the output of `groupBy`.'
            )
            warn = true
          }
          indexBy.set(group, true)
        }

        acc.push({
          key: index,
          index,
          group,
          options: [option]
        })
      }

      return acc
    }, [])
  }

  return {
    getRootProps: (other = {}) => ({
      'aria-owns': listboxAvailable ? `${id}-listbox` : null,
      role: 'combobox',
      'aria-expanded': listboxAvailable,
      ...other,
      onKeyDown: handleKeyDown(other),
      onMouseDown: handleMouseDown,
      onClick: handleClick
    }),
    getInputLabelProps: () => ({
      id: `${id}-label`,
      htmlFor: id
    }),
    getInputProps: () => ({
      id,
      value: inputValue,
      onBlur: handleBlur,
      onFocus: handleFocus,
      onChange: handleInputChange,
      onMouseDown: handleInputMouseDown,
      // if open then this is handled imperativeley so don't let react override
      // only have an opinion about this when closed
      'aria-activedescendant': popupOpen ? '' : null,
      'aria-autocomplete': autoComplete ? 'both' : 'list',
      'aria-controls': listboxAvailable ? `${id}-listbox` : null,
      // Disable browser's suggestion that might overlap with the popup.
      // Handle autocomplete but not autofill.
      autoComplete: 'off',
      ref: inputRef,
      autoCapitalize: 'none',
      spellCheck: 'false'
    }),
    getClearProps: () => ({
      tabIndex: -1,
      onClick: handleClear
    }),
    getPopupIndicatorProps: () => ({
      tabIndex: -1,
      onClick: handlePopupIndicator
    }),
    getTagProps: ({ index }) => ({
      key: index,
      'data-tag-index': index,
      tabIndex: -1,
      onDelete: handleTagDelete(index)
    }),
    getListboxProps: () => ({
      role: 'listbox',
      id: `${id}-listbox`,
      'aria-labelledby': `${id}-label`,
      ref: handleListboxRef,
      onMouseDown: event => {
        // Prevent blur
        event.preventDefault()
      }
    }),
    getOptionProps: ({ option, index }) => {
      const selected = (multiple ? value : [value]).some(
        value2 => value2 != null && getOptionSelected(option, value2)
      )
      const disabled = getOptionDisabled ? getOptionDisabled(option) : false

      return {
        key: index,
        tabIndex: -1,
        role: 'option',
        id: `${id}-option-${index}`,
        onMouseOver: handleOptionMouseOver,
        onClick: handleOptionClick,
        onTouchStart: handleOptionTouchStart,
        'data-option-index': index,
        'aria-disabled': disabled,
        'aria-selected': selected
      }
    },
    id,
    inputValue,
    value,
    dirty,
    popupOpen,
    focused: focused || focusedTag !== -1,
    anchorEl,
    setAnchorEl,
    focusedTag,
    groupedOptions
  }
}
