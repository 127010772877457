import { defaultFont, drawerWidth } from '../index'

const adminLayoutStyle = theme => ({
  root: {
    height: '100%',
    paddingTop: '60px'
  },
  shiftContent: {
    paddingLeft: drawerWidth
  },
  logsContent: {},
  mainPanel: {
    width: '100%',
    height: '100%',
    overflow: 'hidden',
    position: 'relative'
  },
  innerContainer: {
    position: 'absolute',
    left: 0,
    top: 0,
    overflowX: 'hidden',
    overflowY: 'scroll',
    height: '100%',
    width: '100%',
    background: 'rgba(244,246,247,1)',
    transition: 'all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1)',
    overflowScrolling: 'touch',
    transitionDuration: '.2s, .2s, .35s',
    transitionProperty: 'top, bottom, width',
    transitionTimingFunction: 'linear, linear, ease',
    msOverflowStyle: 'none',
    overflow: '-moz-scrollbars-none',
    '&::-webkit-scrollbar': {
      display: 'none'
    }
  },
  content: {
    height: '100%',
    minHeight: 'calc(100vh - 125px)',
    background: 'rgba(244,246,247,1)',
    padding: '20px 47px 20px 30px',
    [theme.breakpoints.down('xs')]: {
      padding: '15px'
    },
    [theme.breakpoints.up('sm')]: {
      padding: '30px'
    },
    [theme.breakpoints.up('md')]: {
      padding: '30px'
    }
  },
  logContent: {
    height: '100%',
    minHeight: 'calc(100vh - 125px)',
    background: 'rgba(244,246,247,1)',
    padding: '20px 47px 20px 30px',
    [theme.breakpoints.down('xs')]: {
      padding: '15px'
    },
    [theme.breakpoints.up('sm')]: {
      padding: '30px'
    },
    [theme.breakpoints.up('md')]: {
      padding: '48px 200px 63px 200px'
    }
  },
  brandTitle: {
    ...defaultFont,
    lineHeight: '30px',
    fontSize: '18px',
    borderRadius: '3px',
    textTransform: 'none',
    color: 'inherit',
    paddingTop: '0.625rem',
    paddingBottom: '0.625rem',
    margin: '0 !important',
    letterSpacing: 'unset',
    '&:hover,&:focus': {
      background: 'transparent'
    }
  }
})

export default adminLayoutStyle
