const customTabsStyle = theme => ({
  customTab: {
    width: '100%',
    overflow: 'hidden',
    paddingBottom: 8,
    display: 'flex',
    marginBottom: 20,
    borderBottom: '1px solid #ebebeb',
    [theme.breakpoints.down('xs')]: {
      paddingLeft: 0,
      paddingRight: 0
    }
  },
  tabHeader: {
    display: 'flex',
    flex: 1
  },
  tabSecondaryAction: {
    display: 'flex'
  },
  btn: {
    borderRadius: 0,
    marginRight: '60px',
    fontSize: '16px',
    fontFamily: '"Lato Semibold", "Lato-Semibold", "Lato", sans-serif',
    fontWeight: 600,
    color: '#5f5f5f',
    width: 'auto',
    padding: '12px 0',
    marginBottom: -8,
    '&:last-child': {
      marginRight: 0
    }
  },
  active: {
    color: 'rgba(78,184,87,1)',
    borderBottom: '2px solid #4eb857'
  },
  content: {
    minHeight: '380px',
    position: 'relative'
  }
})

export default customTabsStyle
