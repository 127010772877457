import { useMemo } from 'react'

import { useUtils } from './useUtils'

export function useParsedDate(possiblyUnparsedValue) {
  const utils = useUtils()
  return useMemo(
    () =>
      typeof possiblyUnparsedValue === 'undefined'
        ? undefined
        : utils.date(possiblyUnparsedValue),
    [possiblyUnparsedValue, utils]
  )
}

export function useNextMonthDisabled(month, { disableFuture, maxDate }) {
  const utils = useUtils()
  return useMemo(() => {
    const now = utils.date()
    const lastEnabledMonth = utils.startOfMonth(
      disableFuture && utils.isBefore(now, maxDate) ? now : maxDate
    )
    return !utils.isAfter(lastEnabledMonth, month)
  }, [disableFuture, maxDate, month, utils])
}

export function usePreviousMonthDisabled(month, { disablePast, minDate }) {
  const utils = useUtils()

  return useMemo(() => {
    const now = utils.date()
    const firstEnabledMonth = utils.startOfMonth(
      disablePast && utils.isAfter(now, minDate) ? now : minDate
    )
    return !utils.isBefore(firstEnabledMonth, month)
  }, [disablePast, minDate, month, utils])
}
