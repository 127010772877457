// nodejs library that concatenates classes
import classNames from 'clsx'
// nodejs library to set properties for components
import PropTypes from 'prop-types'

import { forwardRef } from 'react'

// material-ui components
import ButtonBase from '@material-ui/core/ButtonBase'
import { makeStyles } from '@material-ui/core/styles'

import buttonStyle from '@Common/jss/components/buttonStyle'

const useStyles = makeStyles(buttonStyle)

const RegularButton = forwardRef((props, ref) => {
  const {
    color,
    round,
    children,
    fullWidth,
    disabled,
    simple,
    size,
    block,
    link,
    justIcon,
    className,
    // muiClasses,
    ...rest
  } = props
  const classes = useStyles()
  const btnClasses = classNames({
    [classes.button]: true,
    [classes[size]]: size,
    [classes[color]]: color,
    [classes.round]: round,
    [classes.fullWidth]: fullWidth,
    [classes.disabled]: disabled,
    [classes.simple]: simple,
    [classes.block]: block,
    [classes.link]: link,
    [classes.justIcon]: justIcon,
    [className]: className
  })
  // classes={muiClasses}
  return (
    <ButtonBase
      {...rest}
      ref={ref}
      className={btnClasses}
    >
      {children}
    </ButtonBase>
  )
})
RegularButton.displayName = 'RegularButton'
RegularButton.propTypes = {
  color: PropTypes.oneOf([
    'primary',
    'info',
    'success',
    'warning',
    'danger',
    'rose',
    'white',
    'twitter',
    'facebook',
    'google',
    'linkedin',
    'pinterest',
    'youtube',
    'tumblr',
    'github',
    'behance',
    'dribbble',
    'reddit',
    'transparent',
    'dTen',
    'dTenGreen',
    'dTenLinkText',
    'dTenDanger',
    'grayBtn'
  ]),
  size: PropTypes.oneOf(['sm', 'lg']),
  simple: PropTypes.bool,
  round: PropTypes.bool,
  fullWidth: PropTypes.bool,
  disabled: PropTypes.bool,
  block: PropTypes.bool,
  link: PropTypes.bool,
  justIcon: PropTypes.bool,
  className: PropTypes.string,
  muiClasses: PropTypes.object,
  children: PropTypes.node
}

export default RegularButton
