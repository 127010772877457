import { useState } from 'react'

const useDtenDialog = () => {
  const [dialogType, setDialogType] = useState(null)
  const [dialogInfo, setDialogInfo] = useState(null)
  const [visible, setVisible] = useState('visible')
  const [open, setOpen] = useState(false)

  const showDialog = () => {
    setOpen(true)
  }

  const closeDialog = () => {
    setOpen(false)
  }

  const showVisible = () => {
    setVisible('visible')
  }

  const hideVisible = () => {
    setVisible('hidden')
  }

  return {
    dialogType,
    setDialogType,
    dialogInfo,
    setDialogInfo,
    open,
    showDialog,
    closeDialog,
    visible,
    showVisible,
    hideVisible
  }
}

export default useDtenDialog
