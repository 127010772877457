// import { whiteColor } from '../index'

const authLayoutStyle = theme => ({
  root: {
    width: '100%',
    height: '100%',
    position: 'relative'
  },
  main: {
    width: '100%',
    height: '100%',
    // background: whiteColor,
    background: '#F4F6F7',
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 0,
      paddingRight: 0
    }
  },
  content: {
    width: '100%',
    height: '100%',
    overflow: 'auto'
  }
})

export default authLayoutStyle
