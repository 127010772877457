/**
 * @desc: DateRangePickerDay Component
 * @author: OurTime...
 * @date: 2021/1/8
 */
import clsx from 'clsx'
import PropTypes from 'prop-types'

import { forwardRef, memo } from 'react'

// @material-ui/core components
import { withStyles } from '@material-ui/core/styles'

import { DAY_MARGIN } from '../../Utils/constants'
import { useUtils } from './Hooks/useUtils'
import PickersDay, { areDayPropsEqual } from './PickersDay'

const endBorderStyle = {
  borderTopRightRadius: '50%',
  borderBottomRightRadius: '50%'
}

const startBorderStyle = {
  borderTopLeftRadius: '50%',
  borderBottomLeftRadius: '50%'
}

const styles = theme => ({
  root: {
    '&:first-child $rangeIntervalDayPreview': {
      ...startBorderStyle,
      borderLeftColor: theme.palette.divider
    },
    '&:last-child $rangeIntervalDayPreview': {
      ...endBorderStyle,
      borderRightColor: theme.palette.divider
    }
  },
  rangeIntervalDayHighlight: {
    borderRadius: 0,
    color: theme.palette.primary.contrastText,
    backgroundColor: '#EBFAED',
    '&:first-child': startBorderStyle,
    '&:last-child': endBorderStyle
  },
  rangeIntervalDayHighlightStart: {
    ...startBorderStyle,
    paddingLeft: 0,
    marginLeft: DAY_MARGIN / 2
  },
  rangeIntervalDayHighlightEnd: {
    ...endBorderStyle,
    paddingRight: 0,
    marginRight: DAY_MARGIN / 2
  },
  day: {
    // Required to overlap preview border
    transform: 'scale(1.1)',
    '& > *': {
      transform: 'scale(0.9)'
    }
  },
  dayOutsideRangeInterval: {
    '&:hover': {
      border: `1px solid ${theme.palette.grey[500]}`
    }
  },
  dayInsideRangeInterval: {
    color: '#6D6E71'
  },
  notSelectedDate: {
    backgroundColor: 'transparent'
  },
  rangeIntervalPreview: {
    // replace default day component margin with transparent border to avoid jumping on preview
    border: '2px solid transparent'
  },
  rangeIntervalDayPreview: {
    borderRadius: 0,
    border: `2px dashed ${theme.palette.divider}`,
    borderLeftColor: 'transparent',
    borderRightColor: 'transparent',
    '&$rangeIntervalDayPreviewStart': {
      borderLeftColor: theme.palette.divider,
      ...startBorderStyle
    },
    '&$rangeIntervalDayPreviewEnd': {
      borderRightColor: theme.palette.divider,
      ...endBorderStyle
    }
  },
  rangeIntervalDayPreviewStart: {},
  rangeIntervalDayPreviewEnd: {}
})

const DateRangePickerDay = forwardRef((props, ref) => {
  const {
    classes,
    className,
    day,
    outsideCurrentMonth,
    isEndOfHighlighting,
    isEndOfPreviewing,
    isHighlighting,
    isPreviewing,
    isStartOfHighlighting,
    isStartOfPreviewing,
    selected,
    ...other
  } = props
  const utils = useUtils()

  const isEndOfMonth = utils.isSameDay(day, utils.endOfMonth(day))
  const isStartOfMonth = utils.isSameDay(day, utils.startOfMonth(day))

  const shouldRenderHighlight = isHighlighting && !outsideCurrentMonth
  const shouldRenderPreview = isPreviewing && !outsideCurrentMonth
  return (
    <div
      data-mui-test={shouldRenderHighlight ? 'DateRangeHighlight' : undefined}
      className={clsx(classes.root, className, {
        [classes.rangeIntervalDayHighlight]: shouldRenderHighlight,
        [classes.rangeIntervalDayHighlightEnd]:
          isEndOfHighlighting || isEndOfMonth,
        [classes.rangeIntervalDayHighlightStart]:
          isStartOfHighlighting || isStartOfMonth
      })}
    >
      <div
        data-mui-test={shouldRenderPreview ? 'DateRangePreview' : undefined}
        className={clsx(classes.rangeIntervalPreview, {
          [classes.rangeIntervalDayPreview]: shouldRenderPreview,
          [classes.rangeIntervalDayPreviewEnd]:
            isEndOfPreviewing || isEndOfMonth,
          [classes.rangeIntervalDayPreviewStart]:
            isStartOfPreviewing || isStartOfMonth
        })}
      >
        <PickersDay
          {...other}
          ref={ref}
          allowKeyboardControl={false}
          data-mui-test="DateRangeDay"
          day={day}
          outsideCurrentMonth={outsideCurrentMonth}
          selected={selected}
          className={clsx(classes.day, {
            [classes.notSelectedDate]: !selected,
            [classes.dayOutsideRangeInterval]: !isHighlighting,
            [classes.dayInsideRangeInterval]: !selected && isHighlighting
          })}
          allowSameDateSelection
          disableMargin
        />
      </div>
    </div>
  )
})

DateRangePickerDay.displayName = 'DateRangePickerDay'

DateRangePickerDay.propTypes = {
  /**
   * The content of the component.
   */
  children: PropTypes.node,
  /**
   * @ignore
   */
  classes: PropTypes.object.isRequired,
  /**
   * @ignore
   */
  className: PropTypes.string,
  /**
   * The date to show.
   */
  day: PropTypes.any.isRequired,
  /**
   * @ignore
   */
  isEndOfHighlighting: PropTypes.bool.isRequired,
  /**
   * @ignore
   */
  isEndOfPreviewing: PropTypes.bool.isRequired,
  /**
   * @ignore
   */
  isHighlighting: PropTypes.bool.isRequired,
  /**
   * @ignore
   */
  isPreviewing: PropTypes.bool.isRequired,
  /**
   * @ignore
   */
  isStartOfHighlighting: PropTypes.bool.isRequired,
  /**
   * @ignore
   */
  isStartOfPreviewing: PropTypes.bool.isRequired,
  /**
   * If `true`, day is outside of month and will be hidden.
   */
  outsideCurrentMonth: PropTypes.bool.isRequired,
  /**
   * If `true`, renders as selected.
   */
  selected: PropTypes.bool
}

export default withStyles(styles, { name: 'CustomDateRangePickerDay' })(
  memo(DateRangePickerDay, (prevProps, nextProps) => {
    return (
      prevProps.isHighlighting === nextProps.isHighlighting &&
      prevProps.isEndOfHighlighting === nextProps.isEndOfHighlighting &&
      prevProps.isStartOfHighlighting === nextProps.isStartOfHighlighting &&
      prevProps.isPreviewing === nextProps.isPreviewing &&
      prevProps.isEndOfPreviewing === nextProps.isEndOfPreviewing &&
      prevProps.isStartOfPreviewing === nextProps.isStartOfPreviewing &&
      areDayPropsEqual(prevProps, nextProps)
    )
  })
)
