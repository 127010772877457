const sidebarFooterStyle = () => ({
  help: {
    width: 20,
    height: 20,
    color: 'rgba(155,155,155,1)'
  },
  copyright: {
    fontFamily: '"Lato Regular", "Lato-Regular", "Lato", sans-serif',
    fontSize: '12px',
    fontWeight: '400',
    color: 'rgba(155,155,155,1)',
    lineHeight: '16px'
  },
  linkContent: {
    lineHeight: '13px',
    textDecoration: 'underline',
    cursor: 'pointer'
  },
  link: {
    fontFamily: '"Lato Regular", "Lato-Regular", "Lato", sans-serif',
    fontSize: '12px',
    fontWeight: '400',
    color: 'rgba(155,155,155,1)',
    lineHeight: '14px',
    '&:hover': {
      color: '#4EB857'
    }
  },
  support: {
    marginBottom: '7px'
  },
  supportcontent: {
    fontFamily: '"Lato Regular", "Lato-Regular", "Lato", sans-serif',
    color: '#9B9B9B',
    fontSize: '12px',
    fontWeight: '400',
    lineHeight: '25px'
  },
  supportIcon: {
    fontSize: '12px',
    color: '#9B9B9B',
    marginRight: '8px'
  },
  supportTitle: {
    borderBottom: '1px solid rgba(0, 0, 0, .0625)',
    padding: '15px 20px',
    fontFamily: '"Lato Regular", "Lato-Regular", "Lato", sans-serif',
    height: '50px',
    width: '100%',
    textAlign: 'center',
    fontSize: 16
  },
  desc: {
    fontFamily: '"Lato Regular", "Lato-Regular", "Lato", sans-serif',
    lineHeight: '35px',
    textAlign: 'center',
    fontSize: 14
  }
})

export default sidebarFooterStyle
