import { drawerWidth, fontRegular } from '../index'

const sidebarStyle = theme => ({
  drawer: {
    width: drawerWidth,
    border: 'none',
    [theme.breakpoints.up('lg')]: {
      marginTop: 60,
      height: 'calc(100% - 60px)'
    }
  },
  root: {
    backgroundColor: 'rgba(232,234,235,1)',
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    height: '100%'
  },
  divider: {
    margin: theme.spacing(2, 0)
  },
  nav: {
    marginBottom: theme.spacing(2)
  },
  extension: {
    padding: '24px 24px 40px 24px',
    // position: 'absolute',
    // bottom: 0,
    // left: 0,
    width: '100%',
    backgroundColor: 'rgba(232,234,235,1)'
  },
  header: {
    ...fontRegular,
    fontSize: '14px',
    fontWeight: '400',
    color: 'rgba(155,155,155,1)',
    lineHeight: '17px',
    padding: '16px 32px'
  },
  logo: {
    padding: '10px 0px 14px',
    margin: '0',
    display: 'block',
    position: 'relative',
    zIndex: '4',
    '&:after': {
      content: '""',
      position: 'absolute',
      bottom: '0',
      height: '1px',
      right: '15px',
      width: 'calc(100% - 30px)',
      backgroundColor: 'hsla(0,0%,100%,.3)'
    }
  },
  logoMini: {
    transition: 'all 300ms linear',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    textAlign: 'center',
    maxHeight: '34px',
    marginLeft: '16px',
    marginRight: '18px',
    marginTop: '7px',
    color: 'inherit'
  },
  img: {
    width: '166px',
    verticalAlign: 'middle',
    border: '0'
  },
  sidebarWrapper: {
    position: 'relative',
    height: 'calc(100vh - 61px)',
    overflow: 'auto',
    width: '260px',
    zIndex: '4',
    overflowScrolling: 'touch',
    transitionProperty: 'top, bottom, width',
    transitionDuration: '.2s, .2s, .35s',
    transitionTimingFunction: 'linear, linear, ease',
    color: 'inherit',
    // paddingBottom: '30px',
    backgroundColor: 'rgba(232,234,235,1)'
  },
  links: {
    width: '100%'
  },
  personal: {
    paddingTop: 14,
    [theme.breakpoints.down('md')]: {
      paddingTop: 8
    }
  },
  admin: {
    paddingTop: 40
  }
})

export default sidebarStyle
