import clsx from 'clsx'
import PropTypes from 'prop-types'

import { Box, makeStyles } from '@material-ui/core'
import { HelpOutline } from '@material-ui/icons'

import Button from '@Components/CustomButtons/Button'
import CustomTooltip from '@Components/Tooltip/Tooltip'

import { fontBold, fontRegular, whiteColor } from '@Common/jss'
import tooltipStyle from '@Common/jss/tooltipStyle'

const useStylesToolTip = makeStyles(
  tooltipStyle({
    tooltip: { maxWidth: 360, color: '#797D7A', padding: '10px 15px' }
  })
)

const useStyles = makeStyles(() => ({
  linkTextBtn: {
    color: '#2096F3',
    ...fontRegular,
    fontSize: 14,
    padding: 0,
    '&:hover': {
      textDecoration: 'underline'
    },
    '&:hover, &:focus': {
      color: '#2096F3'
    }
  },
  actions: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  save: {
    fontSize: '14px',
    ...fontBold,
    lineHeight: '20px',
    minWidth: 82
    // paddingTop: '3px',
    // paddingBottom: '3px'
  },
  cancel: {
    fontSize: '14px',
    ...fontBold,
    color: 'rgba(109,110,113,1)',
    background: 'transparent',
    boxShadow: 'none',
    border: '1px solid rgba(109,110,113,1)',
    lineHeight: '20px',
    marginRight: 10,
    minWidth: 82,
    // paddingTop: '3px',
    // paddingBottom: '3px',
    '&:hover, &:active': {
      color: whiteColor,
      backgroundColor: 'rgba(109, 110, 113, 1)'
    }
  }
}))

const Reason = () => {
  return (
    <Box>
      By selecting [Do not merge accounts], DTEN will retain the independence of
      your account and will not perform merge alerts.
    </Box>
  )
}

const Actions = props => {
  const {
    title,
    closeHandle,
    confirmHandle,
    // formik,
    dialogInfo
  } = props
  const classes = useStyles()
  const toolTipClasses = useStylesToolTip()

  let cancelText = `Ignore (next login reminder)`
  let confirmText = `Next step`

  const { uniqueId } = dialogInfo

  // console.log('formik.isSubmitting', formik.isSubmitting)

  return (
    <div className={classes.actions}>
      <Box>
        <Button
          className={classes.linkTextBtn}
          color="dTenLinkText"
          id="Link_View_Permissions_Granted"
          size={'sm'}
          disableRipple
        >{`No account consolidation`}</Button>{' '}
        <CustomTooltip
          placement="top"
          title={<Reason />}
          toolTipClasses={toolTipClasses}
        >
          <Button
            className={classes.helpBtn}
            color={'transparent'}
            size={'sm'}
            disableRipple
            justIcon
          >
            <HelpOutline />
          </Button>
        </CustomTooltip>
      </Box>

      <Box>
        <Button
          className={classes.cancel}
          color="dTen"
          id={`btnCancel${title}_${cancelText}_${uniqueId}`}
          size={'sm'}
          onClick={closeHandle}
        >
          {cancelText}
        </Button>

        <Button
          color={`success`}
          // disabled={!formik.isValid || formik.isSubmitting}
          // id={`btnConfirm${title}_${confirmText}_${uniqueId}`}
          size={'sm'}
          className={clsx({
            [classes.save]: true
          })}
          onClick={confirmHandle}
        >
          {confirmText}
        </Button>
      </Box>
    </div>
  )
}

Actions.propTypes = {
  title: PropTypes.string.isRequired,
  closeHandle: PropTypes.func.isRequired,
  confirmHandle: PropTypes.func.isRequired,
  dialogInfo: PropTypes.object.isRequired
}
export default Actions
