import ReactGA from 'react-ga'

import { isTestUser } from 'src/Utils'
import { getCookiesToken } from 'src/Utils/cache'

import setUserId from './setUserId'

const TRACKING_ID = document.domain.includes('.dten.com')
  ? 'G-0M0ENC7ERH'
  : 'G-WN9X0MKSP1'

// const TRACKING_ID = 'G-WN9X0MKSP1'

function init() {
  // Enable debug mode on the local development environment
  const isDev = !process.env.NODE_ENV || process.env.NODE_ENV === 'development'
  const userId = setUserId()
  ReactGA.initialize(TRACKING_ID, {
    gaOptions: {
      userId: userId ? userId : 'Not-Logged'
    },
    debug: isDev,
    standardImplementation: true
  })
}

export function sendEvent(payload) {
  ReactGA.event(payload)
}

function sendPageView(path) {
  ReactGA.set({ page: path })
  ReactGA.pageview(path)
}

/**
 * @description: 原生ga方法
 * @param {*} actionTxt
 * @param {*} params
 * @return {*}
 */
function gaEvent(actionTxt = 'devices_awaiting_update', params) {
  const userKeyLocal = getCookiesToken()
  if (userKeyLocal) {
    const userInfo = JSON.parse(localStorage.getItem('userInfo'))
    if (userInfo) {
      const isTest = isTestUser(userInfo.emailAddress)
      console.log(`isTest`, isTest)
      const dataLayer = window.dataLayer || []

      dataLayer.push({
        event: actionTxt,
        userId: setUserId(),
        ...params,
        isTest
      })
      // ReactGA.ga('event', actionTxt, {
      //   user_id: setUserId(),
      //   ...params,
      //   isTest
      // })
    }
  }
}

export default {
  init,
  sendEvent,
  sendPageView,
  gaEvent,
  ga: ReactGA.ga
}
