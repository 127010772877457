import PropTypes from 'prop-types'

import { forwardRef } from 'react'
import { NavLink as RouterNavLink } from 'react-router-dom'

import { makeStyles } from '@material-ui/core/styles'

import customTabsStyle from '@Common/jss/components/customTabsStyle'

import CustomButton from '../CustomButtons/Button'
import GridContainer from '../Grid/GridContainer'
import GridItem from '../Grid/GridItem'

const CustomRouterLink = forwardRef((props, ref) => (
  <RouterNavLink
    innerRef={ref}
    {...props}
  />
))

CustomRouterLink.displayName = 'CustomRouterLink'

const useStyles = makeStyles(customTabsStyle)

const CustomTabs = props => {
  const { tabs, children, tabSecondaryAction } = props
  const classes = useStyles()
  return (
    <GridContainer className={classes.root}>
      <GridItem sm={12}>
        <div
          className={classes.customTab}
          style={{ overflowX: 'auto' }}
        >
          <div className={classes.tabHeader}>
            {Array.isArray(tabs) &&
              tabs.map(tab => (
                <CustomButton
                  key={tab.id}
                  activeClassName={classes.active}
                  className={classes.btn}
                  color="transparent"
                  component={CustomRouterLink}
                  id={`tabs_users_${tab.id}`}
                  to={tab.routes.pathname}
                >
                  {tab.name}
                </CustomButton>
              ))}
          </div>
          <div className={classes.tabSecondaryAction}>{tabSecondaryAction}</div>
        </div>
      </GridItem>
      <GridItem
        className={classes.content}
        sm={12}
      >
        {children}
      </GridItem>
    </GridContainer>
  )
}
CustomTabs.displayName = 'CustomRouterLink'

CustomTabs.propTypes = {
  tabs: PropTypes.array,
  tabSecondaryAction: PropTypes.element,
  children: PropTypes.node
}

export default CustomTabs
