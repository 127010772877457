import { Base64 } from 'js-base64'
import { cloneDeep, isEqual } from 'lodash'
import { action, flow, makeObservable, observable } from 'mobx'

import { LIMIT } from 'src/Utils/constants'

import {
  getDeviceDetails,
  getDeviceTimeZone,
  loadLocationData
} from '../Service/api'
import { formatLocationData } from '../Utils/formatLocationData'
import { fillGMT, isD7 } from '../Views/Admin/Utils'

class DevicesStore {
  constructor(rootStore) {
    makeObservable(this, {
      fetchDevicesState: observable,
      geographicLists: observable,
      fetchDeviceInfoState: observable,
      devicesInfo: observable,
      filtrateFields: observable,
      setFiltrateFields: action.bound,
      setFiltrateFieldsEmpty: action.bound,
      fetchLocationsLists: action.bound,
      fetchDeviceInfo: action.bound,
      updateDeviceInfo: action.bound,
      fetchCurrentDeviceTimeZone: action.bound
    })

    this.rootStore = rootStore
  }

  fetchDevicesState = 'pending'
  geographicLists = []
  fetchDeviceInfoState = 'pending'
  devicesInfo = null
  filtrateFields = localStorage.getItem('filtrateFields')
    ? JSON.parse(localStorage.getItem('filtrateFields'))
    : {
        searchParam: '',
        total: 0,
        status: '', // 0离线 1在线
        // type: '', // 3 4 5 6
        limit: LIMIT,
        page: 1
      }

  setFiltrateFields(values) {
    const result = { ...cloneDeep(this.filtrateFields), ...values }
    this.filtrateFields = result
    localStorage.setItem('filtrateFields', JSON.stringify(result))
  }

  setFiltrateFieldsEmpty() {
    this.filtrateFields = {}
    localStorage.removeItem('filtrateFields')
  }

  fetchLocationsLists = flow(function* () {
    const self = this
    const {
      user: { userInfo }
    } = self.rootStore
    self.geographicLists = []
    self.fetchDevicesState = 'pending'
    try {
      const response = yield loadLocationData(userInfo.userKey)
      if (response.data.success) {
        const { data } = response.data
        self.geographicLists = formatLocationData([
          {
            id: 'all',
            label: 'All'
          },
          ...data
        ])
      }
    } catch (e) {
      self.fetchDevicesState = 'error'
    }
  })

  fetchDeviceInfo = flow(function* (callback) {
    const self = this
    const {
      route: { queryParams }
    } = self.rootStore
    const { id } = JSON.parse(Base64.decode(queryParams))
    if (!id) return false
    self.fetchDeviceInfoState = 'pending'
    try {
      const response = yield getDeviceDetails({ id: id })
      if (response.data.success) {
        let { data } = response.data
        data = {
          ...data,
          apps: data?.apps?.map(item => {
            return {
              ...item,
              idleStatus: data.idleStatus
            }
          })
        }
        if (data && !isEqual(self.devicesInfo, data)) {
          const { country, city, floor, room } = data
          self.devicesInfo = {
            ...data,
            country: country ? country : '',
            city: city ? city : '',
            floor: floor ? floor : '',
            room: room ? room : '',
            timeZone: (self.devicesInfo && self.devicesInfo.timeZone) || ''
          }
        }
        self.fetchDeviceInfoState = 'done'
        callback && callback(response.data)
      } else {
        self.fetchDeviceInfoState = 'rejected'
        callback && callback(response.data)
      }
    } catch (e) {
      self.fetchDeviceInfoState = 'error'
      console.log(e.toString())
    }
  })

  updateDeviceInfo = values => {
    if (!isEqual(this.devicesInfo, values)) {
      this.devicesInfo = { ...this.devicesInfo, ...values }
    }
  }

  // D7 timezone特殊处理，直接使用devicesInfo.deviceTimezone
  fetchCurrentDeviceTimeZone = flow(function* () {
    const self = this
    if (!self.devicesInfo) return false
    try {
      const timeZoneId = self.devicesInfo.timeZoneId || 0
      if (isD7(self.devicesInfo.device)) {
        self.devicesInfo = {
          ...self.devicesInfo,
          timeZone: {
            timezone: fillGMT(self.devicesInfo.deviceTimezone),
            googleName: ''
          }
        }
        return false
      }
      const response = yield getDeviceTimeZone(timeZoneId)
      if (response.data.success) {
        const { data } = response.data
        if (Object.keys(data).length > 0)
          self.devicesInfo = { ...self.devicesInfo, timeZone: data }
      }
    } catch (e) {
      console.log(e.toString())
    }
  })
}

export default DevicesStore
