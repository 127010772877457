import { styled, Typography } from '@material-ui/core'

import { fontBold, fontRegular } from '@Common/jss/'

const ContentTypographyHead = styled(Typography)(() => ({
  color: '#2C2C2C',
  fontSize: '14px',
  ...fontBold,
  lineHeight: '21px',
  fontWeight: 'bold'
}))

const ContentTypographyFoot = styled(Typography)(() => ({
  color: '#2C2C2C',
  fontSize: '14px',
  ...fontRegular,
  lineHeight: '21px',
  fontWeight: 400,
  paddingTop: 20
}))

const GreenEmailTypography = styled(Typography)(() => ({
  color: '#4EB857',
  fontSize: '14px',
  ...fontRegular,
  lineHeight: '21px',
  fontWeight: 400
}))

const BoxText = () => {
  return (
    <>
      <ContentTypographyHead>
        {`Organizational accounts merged successfully`}
      </ContentTypographyHead>

      <ContentTypographyFoot component="div">
        {`The current Owner of the organization account is:  `}
        <GreenEmailTypography>leiwang@dten.com.cn</GreenEmailTypography>
      </ContentTypographyFoot>
    </>
  )
}

export default BoxText
