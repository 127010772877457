import PropTypes from 'prop-types'

// @material-ui/core
import { makeStyles } from '@material-ui/core/styles'

import GenerateActivationCode from '../../Containers/AddDevices/GenerateActivationCode'
// Custom Components
import Typography from '../Typography/Typography'

const useStyles = makeStyles({
  root: {
    width: '100%',
    height: '100%',
    color: '#2C2C2C',
    fontFamily: '"Lato Regular", "Lato-Regular", "Lato", sans-serif',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column'
  },
  title: {
    fontWeight: 'bold',
    fontSize: '24px',
    lineHeight: '40px'
  },
  subtitle: {
    fontSize: '16px',
    lineHeight: '40px',
    textAlign: 'center',
    marginBottom: 20
  },
  btn: {
    padding: '7px 10px',
    marginRight: '20px',
    borderRadius: '5px',
    fontWeight: 500
  }
})
const CustomFullScreenModal = props => {
  const { title, subtitle } = props
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <Typography
        className={classes.title}
        variant="h6"
      >
        {title}
      </Typography>
      <Typography
        className={classes.subtitle}
        variant="body2"
      >
        {subtitle}
      </Typography>
      <GenerateActivationCode btnTxt="Generate Activation Code" />
    </div>
  )
}
CustomFullScreenModal.defaultProps = {
  title: 'Welcome to DTEN Orbit Service',
  subtitle:
    'To activate your DTEN product warranty, please generate a code and enter the code on your DTEN device when prompted.',
  generateDesc: 'Generate Activation Code'
}
CustomFullScreenModal.propTypes = {
  title: PropTypes.node,
  subtitle: PropTypes.node,
  generateDesc: PropTypes.string
}
export default CustomFullScreenModal
