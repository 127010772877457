import { Base64 } from 'js-base64'
import { cloneDeep, isEqual } from 'lodash'
import { action, makeObservable, observable } from 'mobx'

import { getActivationScanInfo, setActivationScanInfo } from '../Utils/cache'

const activationBase = getActivationScanInfo()

class ActivateStore {
  constructor(rootStore) {
    makeObservable(this, {
      activationScanInfo: observable,
      setActivationScanInfo: action.bound
    })

    this.rootStore = rootStore
  }

  activationScanInfo = activationBase
    ? JSON.parse(Base64.decode(activationBase))
    : {
        state: 0,
        deviceId: ''
      }

  setActivationScanInfo(values) {
    const updatedInfo = { ...cloneDeep(this.activationScanInfo), ...values }
    if (!isEqual(updatedInfo, this.activationScanInfo)) {
      this.activationScanInfo = updatedInfo
      const encodeData = Base64.encode(JSON.stringify(updatedInfo))
      setActivationScanInfo(encodeData)
    }
  }
}

export default ActivateStore
