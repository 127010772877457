import activateBg from '../../images/activateBg.png'
import { whiteColor } from '../index'

const scanLayoutStyle = theme => ({
  layout: {
    width: '100%',
    height: '100%',
    position: 'relative',
    background: `url(${activateBg}) no-repeat center`,
    backgroundSize: 'cover',
    padding: '80px 0 54px'
  },
  main: {
    width: '100%',
    // height: '100%',
    display: 'flex',
    // alignItems: 'center',
    justifyContent: 'center',
    minHeight: 403,
    // overflow: 'hidden',
    [theme.breakpoints.down('sm')]: {
      padding: ' 0 20px'
    }
  },
  content: {
    maxWidth: '590px',
    maxHeight: '670px',
    padding: '45px 0 54px 0',
    background: whiteColor,
    boxShadow: '0px 3px 4px 0px rgba(0,0,0,0.15)',
    borderRadius: '8px'
  },
  btn: {
    '&,&:focus,&:hover': {
      color: '#6D6E71',
      fontFamily: '"Lato Regular", "Lato-Regular", "Lato", sans-serif',
      fontSize: '1rem',
      fontWeight: '400'
    }
  }
})

export default scanLayoutStyle
