import { DEVICE_TABLE_COLUMNS } from '@Constants'
import { OktaAuth } from '@okta/okta-auth-js'
import { Base64 } from 'js-base64'
import Cookies from 'js-cookie'
import { cloneDeep } from 'lodash'
import { action, flow, makeObservable, observable, runInAction } from 'mobx'

import { isEmptyObject } from '@Utils'
import { setCookies } from '@Utils/cache'
import { DOMAIN_CURRENT, getCookiesToken, removeLocalInfo } from '@Utils/cache'

import authClientPingFederate from 'src/Service/pingFederateSdk'

import {
  changeProxyApi,
  getHasD7Api,
  getOIDCSetting,
  loadPartnerPermissionsApi,
  loadUserApi,
  loginApi,
  logoutApi,
  oktaAuto,
  salesDemoRestoreApi,
  salesDemoResetAgreementApi
} from '@Service/api'

const defaultValue = {
  resendNum: 0,
  resetNum: 0,
  registered: false,
  userKey: getCookiesToken()
}

const initOktaAuth = { pkce: false, responseType: 'code' }
class UserStore {
  state = ''
  isLogout = ''
  // 有权限的模块
  permissions = []
  userInfo = localStorage.getItem('userInfo')
    ? {
        ...JSON.parse(localStorage.getItem('userInfo')),
        userKey: getCookiesToken()
      }
    : defaultValue
  oktaAuth = localStorage.getItem('oktaAuthInfo')
    ? JSON.parse(localStorage.getItem('oktaAuthInfo'))
    : {}

  deviceColumnsDisplay = []

  constructor(rootStore) {
    makeObservable(this, {
      state: observable,
      isLogout: observable,
      permissions: observable,
      userInfo: observable,
      deviceColumnsDisplay: observable,
      recordResendOrResetTime: action.bound,
      getIsPartnerSwitched: action.bound,
      getIsPartner: action.bound,
      setDeviceColumns: action.bound,
      setUserInfo: action.bound,
      setUserInfoEmpty: action.bound,
      fetchLogin: action.bound,
      fetchLoadUserInfo: action.bound,
      fetchLogOut: action.bound,
      fetchSalesDemoRestore: action.bound,
      fetchSalesDemoResetAgreement: action.bound,
      fetchSSOAuthInfo: action.bound,
      fetchSSOAuto: action.bound,
      fetchChangeProxy: action.bound,
      fetchPermissions: action.bound
    })

    this.rootStore = rootStore
  }

  recordResendOrResetTime(flag, email) {
    const result = { ...this.userInfo }
    result[flag]++
    if (flag === 'resetNum' && email) {
      result.emailAddress = email
    }
    this.userInfo = result
    localStorage.setItem('userInfo', JSON.stringify(result))
  }

  setUserInfo(values) {
    const result = { ...cloneDeep(this.userInfo), ...values }
    this.userInfo = result
    localStorage.setItem('userInfo', JSON.stringify(result))
  }

  setUserInfoEmpty() {
    this.userInfo = {}
    localStorage.setItem('userInfo', '{}')
  }

  // 当前partner已切换到对应的客户
  getIsPartnerSwitched() {
    const userInfo = this.userInfo
    return Object.prototype.hasOwnProperty.call(userInfo, 'proxyAccountInfo')
  }

  getIsPartner() {
    const userInfo = this.userInfo
    return userInfo.partnerFlag === 'PARTNER'
  }

  // 初始化和更新设备列表列
  setDeviceColumns() {
    const userInfo = this.userInfo
    let columns = cloneDeep(DEVICE_TABLE_COLUMNS)
    if (userInfo.deviceListColumn) {
      columns = DEVICE_TABLE_COLUMNS.filter(item =>
        userInfo.deviceListColumn.includes(item.keyName)
      )
    }
    this.deviceColumnsDisplay = columns
  }

  async fetchLogin(baseData, callback, failedCallBack, catchCallBack) {
    this.state = 'pending'
    Cookies.remove('token')
    Cookies.remove('token', { path: '/', domain: DOMAIN_CURRENT })
    try {
      const response = await loginApi(baseData)
      if (response.data.success) {
        const { data } = response.data
        const shallowCopyInfo = { ...this.userInfo, ...data }
        runInAction(() => {
          this.state = 'done'
          this.userInfo = shallowCopyInfo
          localStorage.setItem('userInfo', JSON.stringify(data))
          if (callback) callback(shallowCopyInfo)
        })
      } else {
        if (failedCallBack) failedCallBack(response.data)
      }
    } catch (error) {
      runInAction(() => {
        this.state = 'error'
      })
      if (catchCallBack) catchCallBack(error)
    }
  }

  fetchLoadUserInfo = flow(function* (callback) {
    const self = this
    const { userKey } = self.userInfo
    try {
      const resHasD7 = yield getHasD7Api()
      let hasD7Device = false
      if (resHasD7.data.success) {
        hasD7Device = resHasD7.data.data
      }
      const response = yield loadUserApi(userKey)
      if (response.data.success) {
        const { data } = response.data
        const userInfoObj = JSON.parse(Base64.decode(data))
        self.userInfo = {
          ...self.userInfo,
          ...userInfoObj,
          hasD7Device
        }
        if (
          !Object.prototype.hasOwnProperty.call(userInfoObj, 'proxyAccountInfo')
        ) {
          delete self.userInfo.proxyAccountInfo
        }
        this.setDeviceColumns()
        localStorage.setItem('userInfo', JSON.stringify(self.userInfo))
        window.orbit.isPartner = self.getIsPartner()
        window.orbit.switched = self.getIsPartnerSwitched()
        callback && callback()
      }
    } catch (error) {
      console.log(error)
    }
  })

  /**
   * @description: 根据 proxyAccountInfo 获取对应customer的权限
   * @param {*} function
   * @return {*}
   */
  fetchPermissions = flow(function* (callback) {
    const self = this
    if (!self.getIsPartnerSwitched()) {
      return false
    }
    try {
      const params = {
        customerId: self.userInfo.proxyAccountInfo.accountNumber
      }
      const res = yield loadPartnerPermissionsApi(params)
      if (res.data.success) {
        if (res.data.data.serviceContractList.length > 0) {
          const arr =
            res.data.data.serviceContractList[0].permissions?.split(',')
          if (Array.isArray(arr)) {
            self.permissions = arr
            window.orbit.permissions = arr
          }
        }
        callback && callback()
      }
    } catch (error) {
      console.log(error)
    }
  })

  async fetchLogOut(callback) {
    this.isLogout = 'pending'
    const { userKey } = this.userInfo
    try {
      const response = await logoutApi(userKey)
      if (response.data.success) {
        if (!Object.is(window.location.pathname, '/')) {
          removeLocalInfo()
          runInAction(() => {
            this.isLogout = 'done'
            this.userInfo = {}
            removeLocalInfo()
          })
          if (callback) callback()
          window.location.pathname = '/'
        }
        if (callback) callback()
      }
    } catch (error) {
      runInAction(() => {
        this.isLogout = 'error'
      })
    }
  }

  async fetchSalesDemoRestore(callback) {
    this.isLogout = 'pending'
    try {
      const response = await salesDemoRestoreApi()
      if (response.data.success) {
        if (callback) callback()
        window.location.reload()
      }
    } catch (error) {
      runInAction(() => {
        this.isLogout = 'error'
      })
    }
  }

  async fetchSalesDemoResetAgreement(callback) {
    this.isLogout = 'pending'
    try {
      const response = await salesDemoResetAgreementApi()
      if (response.data.success) {
        if (callback) callback()
        window.location.reload()
      }
    } catch (error) {
      runInAction(() => {
        this.isLogout = 'error'
      })
    }
  }

  oktaAuthorize(data, successCallback) {
    runInAction(() => (this.oktaAuth = data))
    const oktaOidcAuth = new OktaAuth({ ...data, ...initOktaAuth })
    oktaOidcAuth.signInWithRedirect().then(() => {
      if (successCallback) successCallback()
    })
    localStorage.setItem(
      'oktaAuthInfo',
      JSON.stringify({ ...data, ...initOktaAuth })
    )
  }

  pingFederateAuthorize(data) {
    authClientPingFederate.authorize(data)
  }

  // orbit 登录 sso
  async fetchSSOAuthInfo(
    values,
    successCallback,
    failedCallBack,
    catchCallBack
  ) {
    try {
      const self = this
      const response = await getOIDCSetting(values)
      if (response.data.success) {
        const { data } = response.data
        console.log(`data`, data)
        if (data) {
          if (data.type === 'SAML2') {
            //handle saml2
            const form = document.createElement('form')
            form.name = 'samlForm'
            document.body.insertBefore(form, document.body.firstChild)

            document.forms['samlForm'].action = data.setting.singleSignOnUrl

            const input = document.createElement('input')
            input.name = data.setting.authnRequestKey
            input.value = data.setting.authnRequest
            input.type = 'hidden'

            form.appendChild(input)

            // document.forms['samlForm'][data.setting.authnRequestKey] = btoa(
            //   data.setting.authnRequest
            // )
            // document.forms['samlForm'].AuthnRequest = btoa(
            //   data.setting.authnRequest
            // )
            document.forms['samlForm'].method = data.setting.binding
            document.forms['samlForm'].submit()
          } else {
            if (data.setting.idp === 'PingFederate') {
              self.pingFederateAuthorize(data.setting)
            } else {
              self.oktaAuthorize(data.setting)
            }
          }
        }
      } else {
        if (failedCallBack) failedCallBack(response && response.data)
      }
    } catch (e) {
      if (catchCallBack) catchCallBack(e.toString())
    }
  }

  /**
   * @description: okta pingone 已经登录，在okta后台直接点击orbit，自动登录
   * @param {*} params
   * @param {*} successCallback
   * @param {*} failedCallBack
   * @param {*} catchCallBack
   * @return {*}
   */
  async fetchSSOAuto(params, successCallback, failedCallBack, catchCallBack) {
    const self = this
    try {
      // setLoadingAuto(true)
      const res = await oktaAuto(params)
      if (res.data.success) {
        const { data } = res.data
        if (data) {
          const { idp, redirectUri } = data
          if (idp === 'PingFederate') {
            console.log(redirectUri)
            self.pingFederateAuthorize(data)
          } else {
            self.oktaAuthorize(data)
            // runInAction(() => (this.oktaAuth = data))
            // const oktaOidcAuth = new OktaAuth({ ...data, ...initOktaAuth })
            // oktaOidcAuth.signInWithRedirect().then(() => {})
            // localStorage.setItem(
            //   'oktaAuthInfo',
            //   JSON.stringify({ ...data, ...initOktaAuth })
            // )
          }
        }
      } else {
        if (failedCallBack) failedCallBack()
      }
    } catch (e) {
      if (catchCallBack) catchCallBack(e.toString())
    }
  }

  /**
   * @description: 切换customer group
   * @param {*} params
   * @return {*}
   */
  async fetchChangeProxy(params) {
    try {
      const res = await changeProxyApi(params)
      if (res.data.success) {
        const token = res.data.data
        setCookies('token', token)
        if (isEmptyObject(params)) {
          delete this.userInfo.proxyAccountInfo
        }
        this.setUserInfo({ userKey: token })
      }
      return res
    } catch (error) {
      console.log(error.toString())
    }
  }
}

export default UserStore
