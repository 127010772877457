import Cookies from 'js-cookie'

import { useEffect, useState } from 'react'

import { Box, Link, makeStyles, Portal } from '@material-ui/core'

import { LINK_PRIVACY_POLICY_COOKIE } from 'src/Utils/constants'

import Button from '../CustomButtons/Button'
import Typography from '../Typography/Typography'

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: 'rgba(0, 0, 0, 0.79)',
    color: theme.palette.common.white,
    width: '100%',
    height: '120px',
    position: 'fixed',
    bottom: 0,
    left: 0,
    outline: 'none',
    zIndex: 2000,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexWrap: 'wrap',
    padding: '15px',
    fontFamily: "'Lato Regular', Lato Regular, Lato"
  },
  typography: {
    fontFamily: "'Lato Regular', Lato Regular, Lato"
  },
  action: {
    height: '60px',
    width: '140px',
    backgroundColor: '#4eb857',
    color: theme.palette.common.white,
    padding: '4px 25px',
    fontSize: '18px',
    fontFamily: '"Lato Bold", "Lato-Bold", "Lato"',
    fontWeight: 'bold',
    marginLeft: '2px'
    // color: theme.palette.common.black
  }
}))

function CookiesNotification() {
  const classes = useStyles()
  const [open, setOpen] = useState(false)

  const handleClose = () => {
    Cookies.set('consent', 'true', { expires: 7 })
    setOpen(false)
  }

  useEffect(() => {
    const consent = Cookies.get('consent')

    if (!consent) {
      setOpen(true)
    }
  }, [])

  if (!open) {
    return null
  }

  return (
    <Portal>
      <div className={classes.root}>
        <Typography
          className={classes.typography}
          color="inherit"
          variant="body1"
        >
          We use Cookies to ensure that we give you the best experience on our
          website. Read our{' '}
          <Link
            color="inherit"
            component="a"
            href={LINK_PRIVACY_POLICY_COOKIE}
            target="_blank"
            underline="always"
          >
            Privacy Policy
          </Link>
          .
        </Typography>
        <Box
          display="flex"
          justifyContent="flex-end"
          ml={2}
        >
          <Button
            className={classes.action}
            color="dTen"
            id="btnCookiesAccept"
            size={'sm'}
            variant="contained"
            onClick={handleClose}
          >
            Accept
          </Button>
        </Box>
      </div>
    </Portal>
  )
}

export default CookiesNotification
