export function calculateRangeChange({
  utils,
  range,
  newDate: selectedDate,
  currentlySelectingRangeEnd
}) {
  const [start, end] = range

  if (utils.isAfter(selectedDate, end)) {
    return { nextSelection: 'end', newRange: [start, selectedDate] }
  }

  if (utils.isBefore(selectedDate, start)) {
    return { nextSelection: 'start', newRange: [selectedDate, end] }
  }

  if (currentlySelectingRangeEnd === 'start') {
    return Boolean(end) && utils.isAfter(selectedDate, end)
      ? { nextSelection: 'end', newRange: [selectedDate, null] }
      : { nextSelection: 'end', newRange: [selectedDate, end] }
  }

  return Boolean(start) && utils.isBefore(selectedDate, start)
    ? { nextSelection: 'end', newRange: [selectedDate, null] }
    : { nextSelection: 'start', newRange: [start, selectedDate] }
}

export function calculateRangePreview(options) {
  if (!options.newDate) {
    return [null, null]
  }

  const [start, end] = options.range
  const { newRange } = calculateRangeChange(options)

  if (!start || !end) {
    return newRange
  }

  const [previewStart, previewEnd] = newRange
  return options.currentlySelectingRangeEnd === 'end'
    ? [end, previewEnd]
    : [previewStart, start]
}
