import classNames from 'clsx'
import PropTypes from 'prop-types'

import { forwardRef } from 'react'

import { withStyles } from '@material-ui/core/styles'

import {
  dangerColor,
  fontRegular,
  infoColor,
  primaryColor,
  successColor,
  warningColor,
  whiteColor
} from '../../Common/jss'

export const styles = theme => {
  return {
    /* Styles applied to the root element. */
    root: {
      ...fontRegular,
      fontSize: '0.75rem',
      display: 'inline-flex',
      alignItems: 'center',
      justifyContent: 'center',
      whiteSpace: 'nowrap',
      backgroundColor: '#e0e0e0',
      transition: theme.transitions.create(['background-color', 'box-shadow']),
      cursor: 'default',
      outline: 0,
      textDecoration: 'none',
      border: 'none', // Remove `button` border
      padding: '0 8px', // Remove `button` padding
      margin: '0 6px',
      verticalAlign: 'middle',
      boxSizing: 'border-box',
      borderRadius: 4
    },
    round: {
      // 当border-radius的值远远大于所需半径。那么就会做到胶囊圆角
      borderRadius: '10rem'
    },
    primary: {
      color: whiteColor,
      backgroundColor: primaryColor[0]
    },
    info: {
      color: whiteColor,
      backgroundColor: infoColor[0]
    },
    gray: {
      backgroundColor: '#F4F4F4',
      color: '#6D6E71'
    },
    success: {
      backgroundColor: '#F0FCF1',
      color: successColor[0]
    },
    warning: {
      backgroundColor: '#FFF9F4',
      color: warningColor[0]
    },
    danger: {
      backgroundColor: '#FFF0F0',
      color: dangerColor[0]
    },
    lg: {
      padding: '4px 12px'
    },
    sm: {
      padding: '0 4px'
    }
  }
}

const CustomTag = forwardRef((props, ref) => {
  const {
    color,
    size,
    round,
    component: Component = 'div',
    classes,
    className,
    children
  } = props
  const tagClasses = classNames({
    [classes.root]: true,
    [classes[size]]: size,
    [classes[color]]: color,
    [classes.round]: round,
    [className]: className
  })
  return (
    <Component
      ref={ref}
      className={tagClasses}
    >
      {children}
    </Component>
  )
})
CustomTag.displayName = 'CustomTag'
CustomTag.propTypes = {
  component: PropTypes.elementType,
  color: PropTypes.oneOf([
    'primary',
    'info',
    'gray',
    'success',
    'warning',
    'danger'
  ]),
  size: PropTypes.oneOf(['sm', 'lg']),
  simple: PropTypes.bool,
  round: PropTypes.bool,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  classes: PropTypes.object
}
export default withStyles(styles, { name: 'CustomTag' })(CustomTag)
