import Cookies from 'js-cookie'

export const DOMAIN_NAME = document.domain
export const DOMAIN_CURRENT = DOMAIN_NAME.includes('.displayten.com.cn')
  ? '.displayten.com.cn'
  : DOMAIN_NAME.includes('.dtencn.com')
  ? '.dtencn.com'
  : DOMAIN_NAME.includes('.dten.dev')
  ? '.dten.dev'
  : '.dten.com'

export const clearCookies = () => {
  Cookies.remove('token', { path: '/' })
  Cookies.remove('token', { domain: DOMAIN_CURRENT, path: '/' })
  Cookies.remove('loginDomain', { path: '/' })
  Cookies.remove('loginDomain', { domain: DOMAIN_CURRENT, path: '/' })
  Cookies.remove('canary', { path: '/' })
  Cookies.remove('canary', { domain: DOMAIN_CURRENT, path: '/' })
  removeActivationInfo()
}

export const removeLocalInfo = () => {
  clearCookies()
  localStorage.clear()
}
// 设置Cookies
// isSession true cookies生命周期为会话级别， false 生命周期为1天(记住密码)
export const setCookies = (key, values, isSession = false) => {
  const hostname = window.location.hostname
  const cookieSetting = isSession ? { path: '/' } : { expires: 1, path: '/' }

  if (hostname.includes('.dten.com')) {
    Cookies.set(key, values, {
      ...cookieSetting,
      domain: '.dten.com'
    })
  } else if (hostname.includes('.dten.dev')) {
    Cookies.set(key, values, {
      ...cookieSetting,
      domain: '.dten.dev'
    })
  } else if (hostname.includes('.displayten.com.cn')) {
    Cookies.set(key, values, {
      ...cookieSetting,
      domain: '.displayten.com.cn'
    })
  } else {
    Cookies.set(key, values, { ...cookieSetting })
  }
}

export function setActivationScanInfo(values) {
  Cookies.set('activationScanInfo', values, { expires: 1, path: '/' })
}

export function getActivationScanInfo() {
  return Cookies.get('activationScanInfo') !== 'undefined'
    ? Cookies.get('activationScanInfo')
    : null
}

export function removeActivationScanInfo() {
  Cookies.remove('activationScanInfo', { path: '/' })
}

export function setActivationInfo(values) {
  Cookies.set('activationInfo', values, { expires: 1, path: '/' })
}

export function getActivationInfo() {
  return Cookies.get('activationInfo') !== 'undefined'
    ? Cookies.get('activationInfo')
    : null
}

export function removeActivationInfo() {
  Cookies.remove('activationInfo', { path: '/' })
}

const getCookiesByKey = key => {
  const token = Cookies.get()
    ? Cookies.get(key)
    : Cookies.get(key, { domain: '.dten.com' })
    ? Cookies.get(key, { domain: '.dten.com' })
    : Cookies.get(key, { domain: '.dten.dev' })
    ? Cookies.get(key, { domain: '.dten.dev' })
    : ''
  return token
}

// 获取Cookies存储token
// export const cookiesToken = () => {
// 	return getCookiesByKey('token')
// }

export const getCookiesToken = () => {
  return getCookiesByKey('token')
}

export const getCookiesLoginDomain = () => {
  return getCookiesByKey('loginDomain')
}
// export const cookiesLoginDomain = getCookiesByKey('loginDomain')
