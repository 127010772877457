import PropTypes from 'prop-types'

import { lazy, Suspense } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'

import Loading from '../../Components/Loading/Loading'
import Exception404 from '../Exception/Exception404'

const EmailActivate = lazy(() => import('./Sections/EmailActivate'))
const Failure = lazy(() => import('./Failure/Failure'))
const EmailConfirm = lazy(() => import('./Sections/EmailConfirm'))
const ReplenishInfo = lazy(() => import('./ReplenishInfo/ReplenishInfo'))
const ActivationSuccess = lazy(() =>
  import('./ActivationSuccess/ActivationSuccess')
)
const ForgetPassword = lazy(() => import('./ForgetPassword/ForgetPassword'))
const ForgetSentEmail = lazy(() => import('./ForgetPassword/ForgetSentEmail'))

const ResetPassword = lazy(() => import('./ResetPassword/ResetPassword'))

const ScanActivate = props => {
  const { match } = props
  return (
    <Suspense fallback={<Loading />}>
      <Switch>
        <Redirect
          from="/scan"
          to="/scan/warranty"
          exact
        />
        <Route
          path={`${match.url}/warranty`}
          render={props => <EmailActivate {...props} />}
          exact
          strict
        />
        <Route
          path={`${match.url}/activate-success`}
          render={props => <ActivationSuccess {...props} />}
          exact
          strict
        />
        <Route
          path={`${match.url}/fail`}
          render={props => <Failure {...props} />}
          exact
          strict
        />
        <Route
          path={`${match.url}/confirm-email`}
          render={props => <EmailConfirm {...props} />}
          exact
          strict
        />
        <Route
          path={`${match.url}/replenish`}
          render={props => <ReplenishInfo {...props} />}
          exact
          strict
        />
        <Route
          path={`${match.url}/forget`}
          render={props => <ForgetPassword {...props} />}
          exact
          strict
        />
        <Route
          path={`${match.url}/forget/sent`}
          render={props => <ForgetSentEmail {...props} />}
          exact
          strict
        />
        <Route
          path={`${match.url}/reset`}
          render={props => <ResetPassword {...props} />}
          exact
          strict
        />
        <Route
          path="*"
          render={props => <Exception404 {...props} />}
        />
      </Switch>
    </Suspense>
  )
}

ScanActivate.propTypes = {
  match: PropTypes.object
}

export default ScanActivate
