export const formatLocationData = (node, pInfo) => {
  for (let i = 0; i < node.length; i++) {
    let current = node[i]
    if (current.key) {
      const format = {
        ...pInfo,
        [current.key]: current.value
      }
      current.format = format
      if (current.children && current.children.length > 0) {
        formatLocationData(current.children, format)
      } else {
        delete current.children
      }
    }
  }
  return node
}

export const getCurrentInfo = (nodes, target, condition) => {
  const cond = condition ? condition : 'id'
  const current = nodes.find(item => item[cond] === target)
  if (current) return current
  for (let i = 0; i < nodes.length; i++) {
    const node = nodes[i]
    if (node.children && node.children.length > 0) {
      const result = getCurrentInfo(node.children, target, cond)
      if (result) return result
    }
  }
}

// 获取数组对象交集
export const getIntersection = (reference, dataLists) => {
  for (let i = 0; i < reference.length; i++) {
    console.log(reference[i])
  }
  console.log(dataLists)
}

/**
 * @Desc 设置用户名显示规则
 * @param accountInfo
 * @returns {string|string}
 */
export const setAccountNamespace = accountInfo => {
  const { nickName, userName, emailAddress } = accountInfo
  return userName
    ? userName.substr(0, 1).toUpperCase()
    : nickName
    ? nickName.substr(0, 1).toUpperCase()
    : emailAddress
    ? emailAddress.substr(0, 1).toUpperCase()
    : 'CA'
}
