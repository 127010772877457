import useLoadingApp from '@Hooks/useLoadingApp'

import BackdropAll from 'src/Components/BackdropAll'

export const LoadingInit = () => {
  const { loadingInit } = useLoadingApp()
  return <BackdropAll open={loadingInit} />
}

export default LoadingInit
