import { action, flow, makeObservable, observable } from 'mobx'

import { getSimplestDeviceList } from '@Service/api'

class SupportStore {
  simplestDeviceList = []

  constructor(rootStore) {
    makeObservable(this, {
      simplestDeviceList: observable,
      fetchSimplestDeviceList: action.bound
    })

    this.rootStore = rootStore
  }

  fetchSimplestDeviceList = flow(function* (callback) {
    const self = this
    try {
      const response = yield getSimplestDeviceList()
      if (response.data.success) {
        const { data } = response.data
        self.simplestDeviceList = data
        callback && callback()
      }
    } catch (error) {
      console.log(error)
    }
  })
}

export default SupportStore
