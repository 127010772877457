import { isUsProduction } from 'src/config'

// '2022/16/11', 只显示年月日
export const GLOBAL_FORMAT_DAY = `YYYY-MM-DD`

export const GLOBAL_PLACEHOLDER_FORMAT = `yyyy-mm-dd`

// 'Nov 16, 2022'
export const GLOBAL_FORMAT_DAY_MMM = `MMM D, YYYY`

// 'Wed, Nov 16, 2:48PM'
export const GLOBAL_FORMAT_WEEKDAY_MMM_AM_PM = `ddd, MMM D, h:mmA`

/* 授权管理页面 时间格式化规则 */
export const GLOBAL_FORMAT_MINUTE = `${GLOBAL_FORMAT_DAY} HH:mm`

export const GLOBAL_FORMAT_SECOND = `${GLOBAL_FORMAT_DAY} HH:mm:ss`

export const CLOCK_WIDTH = 220
export const CLOCK_HOUR_WIDTH = 36
export const DAY_SIZE = 28
export const DAY_MARGIN = 2
export const DIALOG_WIDTH = 320
export const VIEW_HEIGHT = 358
export const SUPPORT_EMAIL = 'Support@dten.com'
export const SUPPORT_PHONE = '1.866.936.3836'
export const LINK_TERMS_OF_USE = 'https://www.dten.com/terms-of-use/'
export const LINK_PRIVACY_POLICY_COOKIE = 'https://www.dten.com/privacy-policy/'
// space 地址栏参数orderId
export const SPACE_ORDER_ID_KEY = 'spaceOrderId'
// 海外生产需要硬编码固定域名
export const PRODUCTION_DOMAIN = 'orbit.dten.com'
export const PRODUCTION_ZENDESK_DOMAIN = 'https://dten.zendesk.com/'

export const BETA_USER = 'BETA_USER'

export const PERMISSION_SET_TOP_BAR = ['SuperAdmin', 'Owner', 'Admin']

// 设备列表 和 updateHistory 和 customer group 默认分页个数
export const LIMIT = 20

export const ROWS_PERPAGE_OPTIONS = [10, 20, 50, 100]

/*
	Grace Period设置时间包括：1 minute、5 minute、15 minute、30 minute、60 minute，默认时间为5分钟；
*/
export const TIMER_PICKER_SETTING_LIST = [1, 5, 15, 30, 60]

/*
邮件合并时间段包括： 1 hour、 3 hours、6 hour、12 hour、24 hour ，默认为6 hour；
*/
export const TIMER_PICKER_EMAILD_ELIVERY = [1, 3, 6, 12, 24]

export const TIMER_PICKER_WEEK = [
  { label: 'Monday', value: 1 },
  { label: 'Tuesday', value: 2 },
  { label: 'Wednesday', value: 3 },
  { label: 'Thursday', value: 4 },
  { label: 'Friday', value: 5 },
  { label: 'Saturday', value: 6 },
  { label: 'Sunday', value: 7 }
]

export const TIMER_POWER_IDLETIME = [
  { label: '5 Minutes', value: 5 },
  { label: '15 Minutes', value: 15 },
  { label: '30 Minutes', value: 30 },
  { label: '1 Hour', value: 60 },
  { label: '2 Hours', value: 120 },
  { label: '3 Hours', value: 180 }
]

/* 显示Permission Management 菜单 /device/manage-authorization 的 accountNumber */
export const PERMISSION_SHOW_ACCOUNTS = isUsProduction
  ? [1000005528, 1000002573, 1000000453, 1000000040, 1000004821, 1000003718]
  : [1000000039, 1000000023, 1000000106, 1000000043]

export const MAX_LEN_GROUP_DESC = 200
export const MAX_LEN_GROUP_NAME = 100

/* 默认必须选中的 */
export const FORCED_SELECTED_PERMISSONS = [
  { id: 'permit_dashboard_01', label: 'Dashboard' },
  { id: 'permit_service_01', label: 'Service' }
]
/* 默认不允许选中的 */
export const FORCED_CANNOT_SELECTED_PERMISSONS = [
  { id: 'permit_device_management_01', label: 'Add devices' },
  { id: 'permit_device_management_02', label: 'Remove devices' },
  // { id: 'permit_device_management_08', label: 'Ticket' },
  { id: 'permit_windows_security_update_01', label: 'Windows Security Update' },
  { id: 'permission_management_01', label: 'Permission Management' },
  { id: 'permit_settings_03', label: 'Update Schedule' },
  { id: 'permit_users_management_01', label: 'Add User' },
  { id: 'permit_users_management_02', label: 'Enable/Disable' },
  {
    id: 'permit_security_01',
    label: 'Single Sign-On(SSO)'
  }
]

/* 默认不允许选中的大菜单 */
export const FORCE_CANNOT_SELECTED_PERMISSONS_KEYS = [
  { key: 'windowsSecurityUpdate' },
  { key: 'permissionManagement' },
  { key: 'usersManagement' },
  { key: 'security' }
]

export const STANDARDS = [
  'Dashboard',
  'Device Management',
  'Device Group',
  'Settings',
  'Account Management',
  'Support'
]

export const STANDARDS_PERMISSIONS =
  'permit_dashboard_01,permit_device_management_03,permit_device_management_04,permit_device_management_05,permit_device_management_08,permit_device_group1,permit_device_group2,permit_settings_02,permit_settings_04,permit_settings_05,permit_account_management_01,permit_account_management_02,permit_service_01'

export const DTEN_SYSTEM = `[DTEN System]`

export const COMPANY_NAME_MAX_LENGTH = 64

/* 是否显示health check 功能 */
export const DISPLAY_HEALTHCHECK = false

/* 设备列表表头 */

const COLUMNS = [
  {
    label: 'Device Name',
    keyName: 'deviceName',
    sortName: 'deviceName',
    readonly: true,
    sort: true
  },
  {
    label: 'Status',
    keyName: 'status',
    sortName: 'status',
    readonly: false,
    sort: true
  },
  {
    label: 'Os Version',
    keyName: 'osVersion',
    sortName: 'versionCode',
    readonly: false,
    sort: true
  },
  {
    label: 'App Version',
    keyName: 'appVersion',
    readonly: false
  },
  {
    label: 'Group',
    keyName: 'group',
    sortName: 'groupName',
    readonly: false,
    sort: true
  },
  {
    label: 'Orbit Plan',
    keyName: 'orbitPlan',
    readonly: false
  },
  {
    label: 'Expiration Date',
    keyName: 'expirationDate',
    readonly: false
  },
  {
    label: 'Last Check',
    keyName: 'lastCheck',
    readonly: false
  },
  {
    label: 'DTEN ID',
    keyName: 'dtenID',
    sortName: 'deviceId',
    readonly: false,
    sort: true
  }
]

export const DEVICE_TABLE_COLUMNS = DISPLAY_HEALTHCHECK
  ? COLUMNS
  : COLUMNS.filter(item => item.keyName !== 'lastCheck')

//
export const PLAN_TYPE = {
  1: 'Basic',
  2: 'Pro',
  3: 'Premium',
  4: 'Plus'
}
