import PropTypes from 'prop-types'

import { lazy, Suspense } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'

// Custom Components
import Loading from '@Components/Loading/Loading'

import Exception404 from '../Exception/Exception404'

// Section for this page
const EmailActivate = lazy(() => import('./Sections/EmailActivate'))
const CodeActivate = lazy(() => import('./Sections/CodeActivate'))
const EmailConfirm = lazy(() => import('./Sections/EmailConfirm'))
const ReplenishInfo = lazy(() => import('./ReplenishInfo/ReplenishInfo'))
const Registered = lazy(() => import('./Sections/Registered'))
const SendEmail = lazy(() => import('./ForgetPassword/SendEmail'))
const LinkExpired = lazy(() => import('./Failure/failure'))
const Frozen = lazy(() => import('./Frozen/Frozen'))
// 激活后多次点击激活链接、撤销邀请Pending状态的用户
const Repeal = lazy(() => import('./NoPendingInvitation/Repeal'))
const ResetAccountInfo = lazy(() =>
  import('./ResetAccountInfo/ResetAccountInfo')
)
const AutomaticLogin = lazy(() => import('./AutomaticLogin/AutomaticLogin'))
const Activation = props => {
  const { match } = props
  return (
    <Suspense fallback={<Loading />}>
      <Switch>
        <Redirect
          from="/activation"
          to="/activation/warranty"
          exact
        />
        <Route
          path={`${match.url}/warranty`}
          render={props => <EmailActivate {...props} />}
          exact
          strict
        />
        <Route
          path={`${match.url}/active-code`}
          render={props => <CodeActivate {...props} />}
          exact
          strict
        />
        <Route
          path={`${match.url}/automatic`}
          render={props => <AutomaticLogin {...props} />}
          exact
          strict
        />
        <Route
          path={`${match.url}/send`}
          render={props => <SendEmail {...props} />}
          exact
          strict
        />
        <Route
          path={`${match.url}/reset`}
          render={props => <ResetAccountInfo {...props} />}
          exact
          strict
        />
        <Route
          path={`${match.url}/failure`}
          render={props => <LinkExpired {...props} />}
          exact
          strict
        />
        <Route
          path={`${match.url}/confirm-email`}
          render={props => <EmailConfirm {...props} />}
          exact
          strict
        />
        <Route
          path={`${match.url}/replenish`}
          render={props => <ReplenishInfo {...props} />}
          exact
          strict
        />
        <Route
          path={`${match.url}/frozen`}
          render={props => <Frozen {...props} />}
          exact
          strict
        />
        <Route
          path={`${match.url}/registered`}
          render={props => <Registered {...props} />}
          exact
          strict
        />
        <Route
          path={`${match.url}/repeal`}
          render={props => <Repeal {...props} />}
          exact
          strict
        />
        <Route
          path="*"
          render={props => <Exception404 {...props} />}
        />
      </Switch>
    </Suspense>
  )
}

Activation.propTypes = {
  match: PropTypes.object
}

export default Activation
