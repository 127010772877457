import { cloneDeep } from 'lodash'
import { action, makeObservable, observable } from 'mobx'

import { LIMIT } from 'src/Utils/constants'

const defaultParams = {
  size: LIMIT,
  current: 1
}

class BillingStore {
  constructor(rootStore) {
    makeObservable(this, {
      queryParamsBilling: observable,
      setQueryParamsBilling: action.bound,
      setQueryBillingEmpty: action.bound,
      setQueryBillingDefault: action.bound
    })

    this.rootStore = rootStore
  }

  queryParamsBilling = localStorage.getItem('queryParamsBilling')
    ? JSON.parse(localStorage.getItem('queryParamsBilling'))
    : { ...defaultParams }

  setQueryParamsBilling(values) {
    const result = { ...cloneDeep(this.queryParamsBilling), ...values }
    this.queryParamsBilling = result
    localStorage.setItem('queryParamsBilling', JSON.stringify(result))
  }

  setQueryBillingEmpty() {
    this.queryParamsBilling = {}
    localStorage.removeItem('queryParamsBilling')
  }

  setQueryBillingDefault() {
    this.queryParamsBilling = { ...defaultParams }
    localStorage.setItem('queryParamsBilling', JSON.stringify(defaultParams))
  }
}

export default BillingStore
