import activateBg from '../../images/activateBg.png'
import { whiteColor } from '../index'

const activateLayoutStyle = theme => ({
  activeLayout: {
    width: '100%',
    height: '100%',
    position: 'relative',
    background: `url(${activateBg}) no-repeat center`,
    backgroundSize: 'cover',
    padding: '64px 0 54px'
  },
  main: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'hidden',
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 30,
      paddingRight: 30
    }
  },
  content: {
    maxWidth: '590px',
    maxHeight: '670px',
    padding: '40px 0 54px 0',
    background: whiteColor,
    boxShadow: '0px 6px 8px 0px rgba(0, 0, 0, 0.07)',
    [theme.breakpoints.down('sm')]: {
      padding: '30px 0'
    }
  },
  btn: {
    '&,&:focus,&:hover': {
      color: '#6D6E71',
      fontFamily: '"Lato Regular", "Lato-Regular", "Lato", sans-serif',
      fontSize: '1rem',
      fontWeight: '400'
    }
  }
})

export default activateLayoutStyle
