/**
 * @desc: authenticationLayout Component
 * @desc: Third Party Authentication
 * @author: OurTime...
 * @date: 2020/12/23
 */
import PropTypes from 'prop-types'

import { makeStyles } from '@material-ui/core/styles'

import Footer from '@Components/Footer/Footer'
import Header from '@Components/Navbar/Header'

// CSS Modules
import authLayoutStyle from '@Common/jss/layouts/authLayoutStyle'

const useStyles = makeStyles(authLayoutStyle)

const ThirdPartyAuthLayout = props => {
  const { children } = props
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <Header
        changeColorOnScroll={{
          height: 400,
          color: 'info'
        }}
        fixed
      />
      <main className={classes.main}>
        <div className={classes.content}>{children}</div>
      </main>
      <Footer
        border={false}
        position="static"
      />
    </div>
  )
}

ThirdPartyAuthLayout.propTypes = {
  children: PropTypes.element.isRequired
}

export default ThirdPartyAuthLayout
