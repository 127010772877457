import { authorityLists } from '@Utils/checkPermission'

/**
 * @Desc 设置设备详情页面面包屑标题
 * @param values
 * @returns {string}
 */
export const setLinkTitle = values => {
  return authorityLists.includes(values) ? 'Device List' : 'DTEN Device'
}
