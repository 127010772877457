import { observer } from 'mobx-react'
import PropTypes from 'prop-types'

import { Suspense, useMemo } from 'react'
import { Route, Switch } from 'react-router-dom'

import { useStore } from '@Hooks/useStore'

// Custom Components
import Loading from '../../Components/Loading/Loading'
import RouteWithLayout from '../../Components/RouteWithLayout/RouteWithLayout'
import { setAdminRoutes } from '../../Router/setAdminRoutes'
import Exception404 from '../Exception/Exception404'
import Applications from './Applications'
import FrontEndTest from './FrontEndTest/FrontEndTest'

const Admin = props => {
  const {
    user: { userInfo }
  } = useStore()
  const { roleInAccount } = userInfo
  const routes = useMemo(() => setAdminRoutes(roleInAccount), [roleInAccount])
  // 修复多个账号登录，导致用户信息错乱 ，入口页面做判断 重定向
  const isUserAbnormal = () => {
    if (userInfo && localStorage.getItem('userInfo')) {
      const userInfoStorage = JSON.parse(localStorage.getItem('userInfo'))
      return userInfo.emailAddress !== userInfoStorage.emailAddress
    }
    return false
  }
  if (isUserAbnormal()) {
    window.location.href = '/'
    return false
  }
  console.log('routes are:', routes)
  return userInfo && userInfo.roleInAccount ? (
    <Suspense fallback={<Loading />}>
      <Switch>
        {routes &&
          routes.map(({ key, path, component: Component, layout: Layout }) => {
            if (Layout) {
              return (
                <RouteWithLayout
                  key={key}
                  component={Component}
                  layout={Layout}
                  path={path}
                  exact
                  {...props}
                />
              )
            }
            return (
              <Route
                key={key}
                path={path}
                render={() => <Component {...props} />}
                exact
              />
            )
          })}
        {/*<Route*/}
        {/*  path="/applications"*/}
        {/*  render={props => <Applications {...props} />}*/}
        {/*/>*/}
        {/*<Route*/}
        {/*  path="/siem/alert/config"*/}
        {/*  render={props => <Applications {...props} />}*/}
        {/*/>*/}
        {process.env.NODE_ENV !== 'production' && (
          <Route
            path="/test"
            render={props => <FrontEndTest {...props} />}
          />
        )}
        <Route render={props => <Exception404 {...props} />} />
      </Switch>
    </Suspense>
  ) : (
    <Loading />
  )
}
Admin.propTypes = {
  match: PropTypes.object
}

export default observer(Admin)
