// import { configure } from 'mobx'
import { useLocalObservable } from 'mobx-react'
import PropTypes from 'prop-types'

// Custom Context
import StoreContext from '../Context/StoreContext'
import ActivateStore from './activate'
import ActivateScanStore from './activateScan'
import App from './app'
import BaseStore from './base'
import billingStore from './billing'
import DevicesStore from './device'
import MAgreementStore from './mAgreement'
import MAuthStore from './mAuthorization'
import RouteStore from './route'
import SupportStore from './support'
import UsageStore from './usage'
// Custom store
import UserStore from './user'

// 不允许在动作之外进行状态修改
// configure({
//   enforceActions: 'always',
//   computedRequiresReaction: true,
//   observableRequiresReaction: true,
//   reactionRequiresObservable: true
// })

class RootStore {
  constructor() {
    this.app = new App(this)
    this.user = new UserStore(this)
    this.devices = new DevicesStore(this)
    this.route = new RouteStore(this)
    this.activate = new ActivateStore(this)
    this.activateScan = new ActivateScanStore(this)
    this.usage = new UsageStore(this)
    this.mAuth = new MAuthStore(this)
    this.mAgreement = new MAgreementStore(this)
    this.base = new BaseStore(this)
    this.support = new SupportStore(this)
    this.billing = new billingStore(this)
  }
}

export const StoreProvider = ({ children }) => {
  const store = useLocalObservable(() => ({ ...new RootStore() }))
  return <StoreContext.Provider value={store}>{children}</StoreContext.Provider>
}

StoreProvider.propTypes = {
  children: PropTypes.node.isRequired
}
