import { fontBold, whiteColor } from './index'

const pageStyles = {
  root: {
    width: '100%',
    paddingBottom: 50
  },
  pageTitle: {
    lineHeight: '24px',
    paddingBottom: '25px',
    ...fontBold,
    fontSize: '21px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    '& > svg': {
      color: '#9B9B9B',
      marginLeft: '8px',
      userSelect: 'none',
      marginRight: '8px'
    }
  },
  home: {
    fontSize: 'inherit',
    color: '#5F5F5F',
    fontWeight: 'bold'
  },
  // link: {
  //   fontSize: 'inherit',
  //   color: '#5F5F5F',
  //   fontWeight: 'bold'
  // },
  // muted: {
  //   color: 'currentColor',
  //   fontSize: 'inherit',
  //   ...fontMedium
  // },
  breadCrumbsAcrow: {
    fontSize: 18,
    color: '#9B9B9B',
    margin: '0 10px'
  },
  link: {
    fontSize: 'inherit',
    fontFamily: '"Lato Bold", "Lato-Bold", Lato, sans-serif',
    fontWeight: 'bold',
    color: 'inherit',
    '&:hover': {
      cursor: 'pointer'
    }
  },
  muted: {
    fontSize: 'inherit',
    color: 'inherit'
  },
  pageContent: {
    overflow: 'auto',
    background: whiteColor,
    boxShadow: '0 2px 15px 0 rgba(109, 110, 113, 0.16)',
    borderRadius: '6px',
    borderLeft: '5px solid #4eb857',
    padding: '35px'
  },
  pageDesc: {
    lineHeight: '19px',
    fontSize: '14px',
    fontFamily: '"Lato Regular", "Lato-Regular"", "Lato", sans-serif',
    fontWeight: 400,
    color: 'rgba(109,110,113,1)',
    paddingBottom: 26
  },
  breadcrumb: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center'
  }
}

export default pageStyles
