/**
 * @desc: LocalizationProvider Component
 * @author: OurTime...
 * @date: 2021/1/8
 */
import PropTypes from 'prop-types'

import { createContext, useMemo } from 'react'

export const PickersAdapterContext = createContext(null)

const LocalizationProvider = props => {
  const {
    children,
    dateAdapter: Utils,
    dateFormats,
    dateLibInstance,
    locale
  } = props

  const utils = useMemo(
    () =>
      new Utils({ locale, formats: dateFormats, instance: dateLibInstance }),
    [Utils, locale, dateFormats, dateLibInstance]
  )
  return (
    <PickersAdapterContext.Provider value={utils}>
      {children}
    </PickersAdapterContext.Provider>
  )
}

LocalizationProvider.propTypes = {
  dateAdapter: PropTypes.func.isRequired,
  locale: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  children: PropTypes.node,
  dateFormats: PropTypes.string,
  dateLibInstance: PropTypes.func
}

export default LocalizationProvider
