/*
 * @Author: Tom Cruise
 * @Date: 2022-01-05 10:03:15
 * @LastEditTime: 2022-01-05 10:19:58
 * @LastEditors: Tom
 * @Description:
 */
import { whiteColor } from './index'

const tooltipStyle = ({ tooltip, arrow }) => {
  const defaultTooltipStyle = {
    backgroundColor: whiteColor,
    fontSize: '14px',
    fontFamily: '"Lato Regular", "Lato-Regular", "Lato", sans-serif',
    fontWeight: '400',
    color: 'rgba(121, 125, 122, 1)',
    lineHeight: '24px',
    boxShadow: '0 2px 15px 0 rgba(128,128,128,0.16)',
    borderRadius: '4px',
    textAlign: 'left'
  }
  return {
    tooltip: {
      ...defaultTooltipStyle,
      ...tooltip
    },
    arrow: {
      backgroundColor: 'transparent',
      color: whiteColor,
      ...arrow
    }
  }
}

export default tooltipStyle
