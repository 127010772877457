import PropTypes from 'prop-types'

import Backdrop from '@material-ui/core/Backdrop'
import CircularProgress from '@material-ui/core/CircularProgress'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 9999,
    opacity: '0.5 !important',
    color: '#fff'
  },
  circular: {
    color: '#fff',
    opacity: '0.6'
  }
}))
const BackdropAll = props => {
  const { open } = props
  const classes = useStyles()

  return (
    <Backdrop
      className={classes.backdrop}
      open={open}
    >
      <CircularProgress
        className={classes.circular}
        color="inherit"
      />
    </Backdrop>
  )
}

BackdropAll.propTypes = {
  open: PropTypes.bool
}

export default BackdropAll
