import classNames from 'clsx'
import { observer } from 'mobx-react'
import PropTypes from 'prop-types'

import { Fragment, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import Dialog from '@material-ui/core/Dialog'
import { useMediaQuery } from '@material-ui/core'
// @material-ui/core components
import { useTheme } from '@material-ui/core/styles'
import { makeStyles } from '@material-ui/core/styles'

// Custom Components
import CustomTopbar from '@Components/Navbar/TopBar'

// Custom Hooks
import { useStore } from '@Hooks/useStore'

// CSS Module
import adminLayoutStyle from '@Common/jss/layouts/adminLayoutStyle'

import HeaderDialogs from './HeaderDialogs'

const useStyles = makeStyles(adminLayoutStyle)

const LogAdminLayout = props => {
  const { children } = props
  const {
    user: { fetchLogOut, fetchLoadUserInfo, userInfo },
    devices: { setFiltrateFieldsEmpty }
  } = useStore()
  const history = useHistory()
  // 设备列表页和详情页以外的页面清除查询缓存
  if (
    history.location.pathname.indexOf('/device/manage') !== 0 &&
    history.location.pathname.indexOf('/detail/') !== 0
  ) {
    setFiltrateFieldsEmpty()
  }

  const [dialogType] = useState('logout')
  const [open, setOpen] = useState(false)
  const classes = useStyles()
  // 点击logout 触发弹框
  const handleLogOutClick = () => {
    if (!open) setOpen(true)
  }
  // 点击关闭弹框
  const closeDialogHandle = () => {
    if (open) setOpen(false)
  }
  // 关闭模态框并退出登录
  const logoutHandle = () => {
    setOpen(false)
  }
  // 点击模态框确认触发logout事件
  const logoutConfirmHandle = () => {
    fetchLogOut(logoutHandle)
  }
  // effect instead of componentDidMount, componentDidUpdate and componentWillUnmount
  useEffect(() => {
    fetchLoadUserInfo()
  }, [])

  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
    defaultMatches: true
  })

  const mainPanelClasses = classNames({
    [classes.mainPanel]: true,
    [classes.mainPanelWithPerfectScrollbar]:
      navigator.platform.indexOf('Win') > -1
  })
  const configTopbar = {
    ...props,

    onLogOutClick: handleLogOutClick
  }

  const displayLayoutDialogs = {
    logout: (
      <HeaderDialogs
        closeHandle={closeDialogHandle}
        confirmHandle={logoutConfirmHandle}
        dialogType={dialogType}
        open={open}
      />
    )
  }

  const DisplayDialog = () => {
    if (!Object.keys(displayLayoutDialogs).includes(dialogType)) {
      console.log('Please check displayLayoutDialogs', dialogType)
      return null
    }
    return displayLayoutDialogs[dialogType]
  }

  return (
    <div
      className={classNames({
        [classes.root]: true,
        [classes.logsContent]: isDesktop
      })}
    >
      <CustomTopbar
        {...configTopbar}
        userInfo={userInfo}
        hiddenSmMenu
      />
      <Fragment>
        <main className={mainPanelClasses}>
          <div className={classes.innerContainer}>
            <div className={classes.logContent}>{children}</div>
          </div>
        </main>
      </Fragment>
      <Dialog
        aria-describedby="doubleCheck-description"
        aria-labelledby="doubleCheck-title"
        maxWidth={'md'}
        open={open}
      >
        {<DisplayDialog />}
      </Dialog>

      {/* {displayLayoutDialogs[dialogType]} */}
    </div>
  )
}

LogAdminLayout.propTypes = {
  children: PropTypes.node
}

export default observer(LogAdminLayout)
