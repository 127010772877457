/**
 * PingOne OpenID Connect/OAuth 2 protocol API
 */
const configInit = {
  /**
   * Tom
   * Application authorization details. For more information check "Getting Started" in README.md
   */
  // Specifies the environment’s UUID.
  environmentId: '',
  // Specifies the code or token type returned by an authorization request. Options are token, id_token, and code. Default values is "token id_token". This is a required property.
  responseType: 'code',
  // Specifies the application’s UUID.
  clientId: '',
  // Specifies the application’s secret key
  // Should be used only for testing purposes and not in production!!!
  clientSecret: '',
  // Specifies the grant type of the token request. Options are authorization_code, implicit, and client_credentials
  grantType: 'authorization_code',
  // Specifies the URL that specifies the return entry point of the application. This is a required property.
  redirectUri: '',
  // Specifies an optional parameter that specifies the URL to which the browser is redirected after a logout has been performed.
  logoutRedirectUri: '',
  // Specifies permissions that determine the resources that the application can access. This parameter is not required, but it is needed to specify accessible resources.
  // scope: 'profile address email phone',
  scope: 'openid email',
  // Specifies whether the user is prompted to login for re-authentication. The prompt parameter can be used as a way to check for existing authentication, verifying that the user is still present for the current session. For prompt=none, the user is never prompted to login to re-authenticate, which can result in an error if authentication is required. For prompt=login, if time since last login is greater than the max-age, then the current session is stashed away in the flow state and treated in the flow as if there was no previous existing session. When the flow completes, if the flow’s user is the same as the user from the stashed away session, the stashed away session is updated with the new flow data and persisted (preserving the existing session ID). If the flow’s user is not the same as the user from the stashed away session, the stashed away session is deleted (logout) and the new session is persisted.
  prompt: 'login',
  // Specifies the client authentication methods supported by the token endpoint. This is a required property. Options are none, client_secret_basic, and client_secret_post.
  tokenEndpointAuthMethod: 'client_secret_basic',
  // Specifies the maximum amount of time allowed since the user last authenticated. If the max_age value is exceeded, the user must re-authenticate.
  maxAge: 3600
}
/**
 *  Authorize the client
 *
 * @param state a string that specifies an optional parameter that is used to maintain state between the logout request and the callback to the endpoint specified by the post_logout_redirect_uri query parameter.
 * @param nonce a string that is used to associate a client session with an ID token, and to mitigate replay attacks. The value is passed through unmodified from the authentication request to the ID token.
 */
const authorize = config => {
  const state = generateRandomValue()
  const nonce = generateRandomValue()
  config = { ...configInit, ...config }
  let authUrl = `${
    `${config.issuer}/as/authorization.oauth2?` +
    `client_id=${config.clientId}&` +
    `redirect_uri=${config.redirectUri}&` +
    `response_type=${config.responseType ? config.responseType : 'code'}`
  }${config.prompt ? `&prompt=${config.prompt}` : ''}${
    config.scope ? `&scope=${config.scope}` : 'openid email'
  }${state ? `&state=${state}` : ''}${nonce ? `&nonce=${nonce}` : ''}`
  window.location.replace(authUrl)
}

const generateRandomValue = () => {
  let crypto = window.crypto || window.msCrypto
  let D = new Uint32Array(2)
  crypto.getRandomValues(D)
  return D[0].toString(36)
}

export default {
  authorize,
  generateRandomValue
}
