import { createTheme } from '@material-ui/core/styles'

const theme = createTheme({
  overrides: {
    // Name of the component ⚛️
    MuiTableRow: {
      root: {
        '&.MuiTableRow-hover': {
          '&:hover': {
            background: '#F5F5F5'
          }
        },
        '&.Mui-selected': {
          backgroundColor: '#F0FCF1',
          '&:hover': {
            background: '#F0FCF1 !important'
          }
        },
        '&.even': {
          background: 'rgba(247, 249, 250, 1)'
        }
      }
    },
    MuiTypography: {
      body1: {
        color: '#6D6E71',
        fontFamily: '"Lato Regular", "Lato-Medium", "Lato", sans-serif'
      },
      caption: {
        fontFamily: '"Lato Regular", "Lato-Medium", "Lato", sans-serif'
      },
      body2: {
        fontFamily: '"Lato Regular", "Lato-Medium", "Lato", sans-serif'
      }
    },
    MuiTableCell: {
      root: {
        fontFamily: 'inherit',
        padding: '0.625rem 1rem',
        '&.tdCell': {
          borderBottom: 'none'
        }
      },
      head: {
        fontFamily: '"Lato Semibold", "Lato-Semibold", "Lato", sans-serif',
        fontSize: '1rem',
        color: 'rgba(95,95,95,1)',
        fontWeight: 600
      },
      body: {
        fontFamily: '"Lato Regular", "Lato-Regular", "Lato", sans-serif',
        fontWeight: 400,
        // color: 'rgba(121,125,122,1)',
        color: '#6D6E71'
      },
      stickyHeader: {
        backgroundColor: '#fff'
      }
    },
    MuiListItem: {
      secondaryAction: {
        '@media (max-width: 600px)': {
          paddingRight: 48
        },
        '@media (min-width: 600px) and (max-width: 960px)': {
          paddingRight: 100
        },
        '@media (min-width: 960px)': {
          paddingRight: 210
        }
      }
    },
    MuiNativeSelect: {
      select: {
        '&:focus': {
          backgroundColor: 'transparent'
        }
      }
    },
    MuiSlider: {
      root: {
        color: '#4EB857'
      }
    },
    MuiInputBase: {
      root: {
        fontSize: '14px',
        fontFamily: '"Lato Regular", "Lato-Regular", "Lato", sans-serif',
        fontWeight: 400,
        color: '#5F5F5F'
      }
    },
    MuiOutlinedInput: {
      root: {
        '&:hover': {
          '& > $notchedOutline': {
            borderColor: '#4eb857',
            borderWidth: '1px'
          }
        },
        '&.Mui-focused': {
          '& > $notchedOutline': {
            borderColor: '#4eb857',
            borderWidth: '1px'
          }
        }
      },
      input: {
        padding: '8.5px'
      },
      notchedOutline: {
        borderColor: '#D3D8DC'
      }
    },
    MuiFormLabel: {
      root: {
        '&.Mui-focused': {
          color: '#4eb857'
        }
      }
    },
    MuiFormHelperText: {
      root: {
        letterSpacing: 0
      },
      contained: {
        marginLeft: 0
      }
    },
    MuiCheckbox: {
      root: {
        color: '#D3D8DC'
      },
      colorSecondary: {
        '&.Mui-checked': {
          color: '#4eb857'
        }
      }
    },
    MuiPaginationItem: {
      root: {
        color: '#565E57',
        fontFamily: '"Lato Medium", "Lato-Medium", "Lato", sans-serif',
        '&.Mui-selected': {
          color: '#4EB857'
        }
      }
    },
    MuiSvgIcon: {
      fontSizeSmall: {
        fontSize: '1rem'
      }
    },
    MuiIconButton: {
      colorSecondary: {
        '&:hover': {
          backgroundColor: 'none'
        }
      }
    },
    MuiInputLabel: {
      outlined: {
        '&.MuiInputLabel-shrink': {},
        borderWidth: '1px'
      }
    },
    MuiRadio: {
      colorSecondary: {
        '&.Mui-checked': {
          color: '#4EB857'
        }
      }
    },
    MuiInputAdornment: {
      root: {
        height: 'auto'
      },
      positionEnd: {
        marginLeft: 0
      }
    },
    MuiPaper: {
      root: {
        // 背景色会导致ticket弹窗 异常
        // backgroundColor: '#fff !important'
      },
      rounded: {
        borderRadius: '10px'
      }
    },
    MuiDialog: {
      paper: {
        boxShadow: 'none',
        backgroundColor: 'transparent'
      }
    }
  }
})

export default theme
