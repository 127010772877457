import PropTypes from 'prop-types'

import Box from '@material-ui/core/Box'

const ExpireLockedCopywriting = props => {
  const { isDeviceList = false, classes } = props
  const copywritingArray = [
    'License has expired. Please contact DTEN Support.',
    'Phone: 1.866.936.3836',
    'Email: SUPPORT@DTEN.COM'
  ]
  return isDeviceList ? (
    <>
      <div className={classes.expireLockedTip}>
        {copywritingArray.map((item, index) => {
          return <p key={index}>{item}</p>
        })}
      </div>
    </>
  ) : (
    <>
      <Box className={classes.text}>
        {copywritingArray.map((item, index) => {
          return (
            <Box
              key={index}
              className={index === 0 ? classes.reason : classes.contact}
            >
              {item}
            </Box>
          )
        })}
      </Box>
    </>
  )
}

ExpireLockedCopywriting.propTypes = {
  isDeviceList: PropTypes.bool,
  classes: PropTypes.object
}
export default ExpireLockedCopywriting
