import activation from './en-US/activation'
import component from './en-US/components'
import menu from './en-US/menu'
import particular from './en-US/particular'
import login from './en-US/user'

export default {
  'navBar.lang': 'Languages',
  'layout.user.link.help': 'Help',
  'layout.user.link.privacy': 'Privacy',
  'layout.user.link.terms': 'Terms',
  'app.preview.down.block': 'Download this page to your local project',
  'app.welcome.link.fetch-blocks': 'Get all block',
  'app.welcome.link.block-list':
    'Quickly build standard, pages based on `block` development',
  'com.input.required': 'This field is required.',
  ...login,
  ...menu,
  ...particular,
  ...component,
  ...activation
}
