import { styled, Typography } from '@material-ui/core'

import Divider from '@Components/Divider'

import { fontRegular } from '@Common/jss/'

import Emails from './Emails'

const ContentTypographyHead = styled(Typography)(() => ({
  color: '#2C2C2C',
  fontSize: '14px',
  ...fontRegular,
  lineHeight: '21px',
  fontWeight: 400
}))

const ContentTypographyFoot = styled(Typography)(() => ({
  color: '#9B9B9B',
  fontSize: '14px',
  ...fontRegular,
  lineHeight: '21px',
  fontWeight: 400,
  paddingTop: 30,
  borderTop: '1px solid #EBEBEB'
}))

const BoxText = () => {
  return (
    <>
      <ContentTypographyHead>
        DTEN has detected accounts in the system that may be the same as your
        organization and recommends that you perform an account merge. Below are
        the Owner email addresses of <br />
        accounts that may be identical to your organization, please select one
        of them as the Owner account after account merging.
      </ContentTypographyHead>
      <Divider style={{ margin: '30px 0 0 0' }} />
      <Emails />
      <ContentTypographyFoot>
        {`* When all the above accounts are selected and Emails are the same, the system will merge the organization accounts and use the selected Email as the merged Owner account, and the other accounts will be set as Admin. If there is a discrepancy in the selected Email, all users need to select again. (If you have any doubts or unclear about this step, you can contact DTEN)`}
      </ContentTypographyFoot>
    </>
  )
}

export default BoxText
