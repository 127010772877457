import PropTypes from 'prop-types'

import * as React from 'react'

import Path from './Path'
import {
  VIEWBOX_CENTER_X,
  VIEWBOX_CENTER_Y,
  VIEWBOX_HEIGHT,
  VIEWBOX_HEIGHT_HALF,
  VIEWBOX_WIDTH
} from './constants'

class CircularProgressbar extends React.Component {
  static defaultProps = {
    background: true,
    backgroundPadding: 1,
    circleRatio: 1,
    classes: {
      root: 'CircularProgressbar',
      trail: 'CircularProgressbar-trail',
      path: 'CircularProgressbar-path',
      text: 'CircularProgressbar-text',
      background: 'CircularProgressbar-background'
    },
    counterClockwise: false,
    className: '',
    maxValue: 100,
    minValue: 0,
    strokeWidth: 8,
    styles: {
      root: {},
      trail: {},
      path: {},
      text: {},
      background: {}
    },
    text: ''
  }

  getBackgroundPadding() {
    if (!this.props.background) {
      // Don't add padding if not displaying background
      return 0
    }
    return this.props.backgroundPadding
  }

  getPathRadius() {
    // The radius of the path is defined to be in the middle, so in order for the path to
    // fit perfectly inside the 100x100 viewBox, need to subtract half the strokeWidth
    return (
      VIEWBOX_HEIGHT_HALF -
      this.props.strokeWidth / 2 -
      this.getBackgroundPadding()
    )
  }

  // Ratio of path length to trail length, as a value between 0 and 1
  getPathRatio() {
    const { value, minValue, maxValue } = this.props
    const boundedValue = Math.min(Math.max(value, minValue), maxValue)
    return (boundedValue - minValue) / (maxValue - minValue)
  }

  render() {
    const {
      circleRatio,
      className,
      classes,
      counterClockwise,
      styles,
      strokeWidth,
      text
    } = this.props

    const pathRadius = this.getPathRadius()
    const pathRatio = this.getPathRatio()

    return (
      <svg
        className={`${classes.root} ${className}`}
        data-test-id="CircularProgressbar"
        style={styles.root}
        viewBox={`0 0 ${VIEWBOX_WIDTH} ${VIEWBOX_HEIGHT}`}
      >
        {this.props.background ? (
          <circle
            className={classes.background}
            cx={VIEWBOX_CENTER_X}
            cy={VIEWBOX_CENTER_Y}
            fill="red"
            r={VIEWBOX_HEIGHT_HALF}
            strokeWidth="20"
            style={styles.background}
          />
        ) : null}
        <Path
          className={classes.trail}
          counterClockwise={counterClockwise}
          dashRatio={circleRatio}
          pathRadius={pathRadius}
          strokeWidth={strokeWidth}
          style={styles.trail}
        />

        <Path
          className={classes.path}
          counterClockwise={counterClockwise}
          dashRatio={pathRatio * circleRatio}
          pathRadius={pathRadius}
          stroke="black"
          strokeWidth={strokeWidth}
          style={styles.path}
        />

        {text ? (
          <text
            className={classes.text}
            style={styles.text}
            x={VIEWBOX_CENTER_X}
            y={VIEWBOX_CENTER_Y}
          >
            {text}
          </text>
        ) : null}
      </svg>
    )
  }
}

CircularProgressbar.propTypes = {
  background: PropTypes.bool,
  backgroundPadding: PropTypes.number,
  strokeWidth: PropTypes.number,
  value: PropTypes.number,
  minValue: PropTypes.number,
  maxValue: PropTypes.number,
  circleRatio: PropTypes.number,
  className: PropTypes.string,
  classes: PropTypes.object,
  styles: PropTypes.object,
  counterClockwise: PropTypes.bool,
  text: PropTypes.string
}
export default CircularProgressbar
