import PropTypes from 'prop-types'

import Paper from '@material-ui/core/Paper'
import { makeStyles } from '@material-ui/core/styles'

const paperStyles = makeStyles({
  root: {
    borderRadius: '4px'
  }
})

const CustomPaper = ({ children, props }) => {
  const classes = paperStyles()
  return (
    <Paper
      className={classes.root}
      {...props}
    >
      {children}
    </Paper>
  )
}

CustomPaper.propTypes = {
  children: PropTypes.node,
  props: PropTypes.object
}

export default CustomPaper
