import PropTypes from 'prop-types'

import DialogContent from '@Components/DialogContent/DialogContent'

import Actions from './Actions'
import BoxText from './BoxText'

const UserGuideWarn = props => {
  const { confirmHandle, closeHandle, ...rest } = props
  const { dialogInfo, gaTitle } = rest
  const { uniqueId, key } = dialogInfo

  return (
    <DialogContent
      cancel={false}
      closeHandle={closeHandle}
      confirm={false}
      confirmHandle={confirmHandle}
      dialogContent={<BoxText />}
      dialogTitle={key}
      gaTitle={gaTitle}
      uniqueId={uniqueId}
      width="690px"
      actions={
        <Actions
          {...rest}
          closeHandle={closeHandle}
          confirmHandle={() => {}}
          confirmSaveAndAnother={() => {}}
        />
      }
      {...rest}
    />
  )
}

UserGuideWarn.propTypes = {
  open: PropTypes.bool,
  closeHandle: PropTypes.func,
  confirmHandle: PropTypes.func
}

export default UserGuideWarn
