export default {
  'user.login.email': 'Email',
  'user.login.password': 'Password',
  'user.login.remember-me': 'Remember me',
  'user.login.do-not-have-account': "Don't have an account?",
  'user.login.forgot-password': 'Forgot Password',
  'user.login.signup': 'Sign Up',
  'user.login.login': 'Sign In',
  'user.register.register': 'Create Account',
  'user.reset.link-expired': 'Your link has expired',
  'user.reset.link-expired-tips':
    'Sorry, your link has expired, please {create} or {reset} again.',
  'user.register.first-name': 'First Name',
  'user.register.last-name': 'Last Name',
  'user.register.company-name': 'Company Name',
  'user.register.confirm-password': 'Confirm Password',
  'user.register.show': 'Show',
  'user.register.hide': 'Hide',
  'user.register.sign-in': 'Already have an account?',
  // 'user.register-result.activation-email':
  // 	'The activation email has been sent to your email address and is valid for 24 hours. Please log in to the email in time and click on the link in the email to activate the account.',
  'user.register-result.activation-email':
    'We sent a confirmation email to {email}. Please check the email to verify your account.',
  'user.register.account-locked':
    'Your account {email} is blocked for 30 minutes due to reaching the maximum 6 failed login attempts.',
  'user.email.required': 'Email is required',
  'user.email.wrong-format': 'Please enter a valid email address.',
  'user.reset.frozen': 'Please enter a valid email address.',
  'user.email.exists': 'The email already exists',
  'user.password.wrong-format':
    'Must be at least 6 or more characters, including at least one uppercase letter, one lowercase letter, and one number.',
  'user.password.required': 'Password is required',
  'user.password.twice': 'The passwords entered twice do not match!',
  'user.password.incorrect': 'Incorrect password',
  'user.first-name.required': 'First name is required',
  'user.last-name.required': 'Last name is required',
  'user.country.required': 'country is required',
  'user.first-name.wrong-format':
    'Cannot contain special characters: ~, !，@，#，$，%，^，&，*，(，)，<，>，?，:，"，{，}',
  'user.company-name.required': 'Company name is required',
  'user.company-owner.required': 'Company owner is required',
  'user.confirm-password.required': 'Please confirm your password!',
  'user.phone-number.required': 'Please enter your phone number!',
  'user.phone-number.wrong-format': 'The format is incorrect.',
  'user.input-characters.limit': 'Characters limit {maxNum}',
  'user.password-reset.rest': 'Password Reset',
  'user.password-reset.rest-btn': 'Reset Password',
  'user.password-reset.guide':
    'Verify your email to <cta>finish up the registration</cta>',
  'user.password-reset.send-email':
    'We sent an email to {email}. Please check the email to reset your password.',
  'user.password-reset.desc':
    "Enter your email below and we'll send you a link to reset your password.",
  'user.password-rest-send': 'Send',
  'user.navBar.lang': 'Languages',
  'user.activate.account-title': 'Activate your DTEN Account',
  'user.activate.account-subtitle': 'Sign up with a password',
  'user.activate.account-email': 'Your work email: {email}',
  'user.okta.back': 'Back',
  'user.okta.domain.placeholder': 'Your company domain',
  'user.okta.domain.required': 'Domain is required',
  'user.okta.clientId.required': 'Client ID is required',
  'user.okta.clientSecret.required': 'Client secret is required',
  'user.okta.authorizationServer.required': 'Authorization server is required',
  'user.sso.saml.entityId.required': ' Entity ID is required',
  'user.sso.saml.signOnUrl.required': ' signOnUrl is required',
  'user.sso.saml.signingCert.required': ' signingCert is required',
  'user.sso.saml.email.required': ' email is required',
  'user.sso.saml.lastName.required': ' First Name is required',
  'user.sso.saml.firstName.required': ' Last Name is required'
}
