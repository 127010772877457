/*
 * @Author: Tom Cruise
 * @Date: 2021-12-10 16:35:30
 * @LastEditTime: 2022-09-23 17:25:32
 * @LastEditors: Tom
 * @Description:
 */
import { useSnackbar } from 'notistack'

let useSnackbarRef

export const SnackbarUtilsConfigurator = () => {
  useSnackbarRef = useSnackbar()
  return null
}

export const toast = ({ msg, variant = 'default', ...rest }) => {
  useSnackbarRef.enqueueSnackbar(msg, { variant, ...rest })
}

export const success = (msg, options) => {
  toast({ msg, variant: 'success', ...options })
}

export const warning = (msg, options) => {
  toast({ msg, variant: 'warning', ...options })
}

export const info = (msg, options) => {
  toast({ msg, variant: 'info', ...options })
}

export const error = (msg, options) => {
  toast({ msg, variant: 'error', ...options })
}

export default {
  success,
  warning,
  info,
  error,
  toast
}
