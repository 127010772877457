import { action, flow, makeObservable, observable } from 'mobx'

import { getHealthCodes, getMenuShowApi } from '@Service/api'

class BaseStore {
  healthErrCodes = {}
  allHealthErrCodes = []
  menusShow = {}
  constructor(rootStore) {
    makeObservable(this, {
      healthErrCodes: observable,
      allHealthErrCodes: observable,
      menusShow: observable,
      setCodes: action.bound,
      fetchMenuShow: action.bound,
      fetchHealthErrCodes: action.bound,
      loadHealthErrCodes: action.bound
    })

    this.rootStore = rootStore
  }

  setCodes(codes) {
    let result = {}
    codes.forEach(item => (result[item.errorId] = item.errorDesc))
    this.healthErrCodes = result
  }

  // 已经缓存的信息不再请求
  loadHealthErrCodes(callback) {
    const self = this
    if (Object.keys(self.healthErrCodes)?.length === 0) {
      self.fetchHealthErrCodes(() => {
        callback && callback()
      })
    } else {
      callback && callback()
    }
  }

  fetchMenuShow = flow(function* (callback) {
    const self = this
    try {
      const res = yield getMenuShowApi()
      if (res.data.success) {
        const { data } = res.data
        self.menusShow = data
        callback && callback()
      } else {
        console.log('error')
      }
    } catch (error) {
      console.log(error)
    }
  })

  fetchHealthErrCodes = flow(function* (callback) {
    const self = this
    try {
      const res = yield getHealthCodes({ i18n: 'en' })
      if (res.data.success) {
        const { data } = res.data
        self.allHealthErrCodes = data
        self.setCodes(data)
        callback && callback()
      } else {
        console.log('error')
      }
    } catch (error) {
      console.log(error)
    }
  })
}

export default BaseStore
