import { useContext, useRef } from 'react'

import { PickersAdapterContext } from '../../../Context/LocalizationProvider'

export function useUtils() {
  return useContext(PickersAdapterContext)
}

export function useNow() {
  const utils = useUtils()
  const now = useRef(utils.date())
  return now.current
}
