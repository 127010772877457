import { DISPLAY_HEALTHCHECK } from '@Constants'
import classNames from 'clsx'
import { Base64 } from 'js-base64'
import { observer } from 'mobx-react'
import PropTypes from 'prop-types'

import { forwardRef, useEffect, useMemo, useState } from 'react'
import { NavLink as RouterNavLink, useHistory } from 'react-router-dom'

import { Box } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { ChevronRight } from '@material-ui/icons'

import Card from '@Components/Card/Card'
import CardContent from '@Components/Card/CardContent'
import CustomTabs from '@Components/CustomTabs/CustomTabs'
import GridContainer from '@Components/Grid/GridContainer'
import GridItem from '@Components/Grid/GridItem'
import Loading from '@Components/Loading/Loading'
import Muted from '@Components/Typography/Muted'
import Typography from '@Components/Typography/Typography'

import DeviceContext from '@Context/DeviceContext'

import { useStore } from '@Hooks/useStore'

import { authorityLists } from '@Utils/checkPermission'

import { admins } from '@Views/Admin/Details/Utils/util'

import useGroupInfo from 'src/Hooks/useGroupInfo'
import { getLocationQuery } from 'src/Utils'
import { getDtenDeviceModel } from 'src/Utils/device'

import ExpireLockedLayout from './ExpireLockedLayout'
import { setLinkTitle } from './Utils'

const detailsStyle = () => ({
  card: {
    position: 'relative'
  },
  wrapper: {
    paddingBottom: 50
  },
  root: {
    padding: '30px 85px'
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    padding: '0 0 21px 0',
    color: 'rgba(95,95,95,1)',
    fontSize: 21
  },
  breadCrumbsAcrow: {
    fontSize: 18,
    color: '#9B9B9B',
    margin: '0 10px'
  },
  link: {
    fontSize: 'inherit',
    fontFamily: '"Lato Bold", "Lato-Bold", Lato, sans-serif',
    fontWeight: 'bold',
    color: 'inherit',
    '&:hover': {
      cursor: 'pointer'
    }
  },
  muted: {
    fontSize: 'inherit',
    color: 'inherit'
  },
  locked: {
    height: '64px',
    fontSize: '20px',
    fontFamily: '"Lato Bold", "Lato-Bold", "Lato", sans-serif',
    fontWeight: 'bold',
    color: 'rgba(44,44,44,1)',
    lineHeight: '64px',
    textAlign: 'center',
    margin: '0 auto'
  },
  hidden: {
    display: 'none'
  }
})
const useStyles = makeStyles(detailsStyle)

const CustomRouterLink = forwardRef((props, ref) => (
  <RouterNavLink
    innerRef={ref}
    {...props}
  />
))

// function useQueryParamsData() {
//   const { route } = useStore()
//   return useObserver(() => ({
//     queryParams: route.queryParams
//   }))
// }

const BreadcrumbGroupName = props => {
  const { groupInfo, classes, history } = props
  const groupid = getLocationQuery(`groupid`)

  const clickedHandleGroupName = () => {
    history.push(`/device/manage?groupid=${groupid}`)
  }

  return groupid && groupInfo.groupName ? (
    <>
      <ChevronRight className={classes.breadCrumbsAcrow} />
      <Muted
        className={classes.link}
        onClick={clickedHandleGroupName}
      >
        {groupInfo.groupName}
      </Muted>
    </>
  ) : null
}

BreadcrumbGroupName.propTypes = {
  groupInfo: PropTypes.object,
  classes: PropTypes.object,
  history: PropTypes.object
}

const DetailsLayout = props => {
  const { children } = props
  const history = useHistory()
  const classes = useStyles()
  const [waiting, setWaiting] = useState('pending')
  const [resErrorMsg, setResErrorMsg] = useState('')
  const { groupInfo, loadGroup } = useGroupInfo()
  const groupid = getLocationQuery(`groupid`)

  const {
    user: { userInfo },
    devices: { devicesInfo, fetchDeviceInfo },
    route: { queryParams }
  } = useStore()

  // const queryParams = useQueryParamsData().queryParams

  const isAdmin = useMemo(() => {
    const { roleInAccount } = userInfo
    return admins.includes(roleInAccount)
  }, [userInfo.roleInAccount])
  // 获取查询参数
  const queryInfo = useMemo(() => {
    if (queryParams) {
      return JSON.parse(Base64.decode(queryParams))
    }
  }, [queryParams])

  // 根据角色判断跳转路径
  const clickedHandle = () => {
    if (groupid) {
      history.push('/device/group')
      return false
    }
    const { roleInAccount } = userInfo
    if (queryInfo.from === 'equipment') {
      history.push('/equipment')
    } else {
      if (authorityLists.includes(roleInAccount)) {
        history.push('/device/manage')
      } else {
        history.push('/equipment')
      }
    }
  }
  // 设置链接标题
  const linkTitle = useMemo(
    () => setLinkTitle(userInfo.roleInAccount),
    [userInfo.roleInAccount]
  )
  const getLinkTitle = () => {
    return groupid
      ? 'Device Groups'
      : queryInfo.from === 'equipment'
      ? 'DTEN Device'
      : linkTitle
  }

  const getPathName = url => {
    return groupid ? `${url}?groupid=${groupid}` : url
  }

  let tabsInfo = [
    {
      id: 'deviceProfile',
      name: 'Device Profile',
      routes: {
        pathname: getPathName('/detail/device-profile')
      }
    },
    {
      id: 'settings',
      name: 'Settings',
      routes: {
        pathname: getPathName('/detail/settings')
      }
    },
    {
      id: 'healthCheck',
      name: 'Health Check',
      routes: {
        pathname: getPathName('/detail/health-check')
      }
    }
  ]

  if (!DISPLAY_HEALTHCHECK) {
    tabsInfo = tabsInfo.filter(item => item.id !== 'healthCheck')
  }

  if (devicesInfo && getDtenDeviceModel(devicesInfo).dtenModel === 'Mate') {
    tabsInfo = tabsInfo.filter(item => item.id !== 'healthCheck')
  }

  useEffect(() => {
    loadGroup()
    fetchDeviceInfo(data => {
      setWaiting(data?.success ? 'done' : 'rejected')
      setResErrorMsg(data?.message)
    })
  }, [])

  return waiting === 'pending' ? (
    <Loading />
  ) : waiting === 'rejected' ? (
    <Box>{resErrorMsg}</Box>
  ) : queryInfo && devicesInfo ? (
    <DeviceContext.Provider value={{ queryInfo: queryInfo }}>
      <GridContainer className={classes.wrapper}>
        <GridItem sm={12}>
          <Typography
            className={classes.title}
            color="textSecondary"
            component="h1"
            variant="h6"
          >
            <Muted
              className={classes.link}
              onClick={clickedHandle}
            >
              {getLinkTitle()}
            </Muted>
            <BreadcrumbGroupName
              classes={classes}
              groupInfo={groupInfo}
              history={history}
            />
            <ChevronRight className={classes.breadCrumbsAcrow} />
            <Muted className={classes.muted}>{queryInfo.deviceName}</Muted>
          </Typography>
        </GridItem>
        <GridItem sm={12}>
          <Card
            className={classes.card}
            color="transparent"
          >
            <ExpireLockedLayout />
            <CardContent className={classNames('customCardBody', classes.root)}>
              {queryInfo && !isAdmin && queryInfo.lockDeviceStatus ? (
                <>
                  <div className={classes.locked}>
                    Sorry, the device has been locked, please contact your
                    administrator.
                  </div>
                  <div className={classes.hidden}>
                    <CustomTabs tabs={tabsInfo}>{children}</CustomTabs>
                  </div>
                </>
              ) : (
                <CustomTabs tabs={tabsInfo}>{children}</CustomTabs>
              )}
            </CardContent>
          </Card>
        </GridItem>
      </GridContainer>
    </DeviceContext.Provider>
  ) : null
}

DetailsLayout.propTypes = {
  children: PropTypes.node
}

CustomRouterLink.displayName = 'CustomRouterLink'

export default observer(DetailsLayout)
