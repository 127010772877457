import DateFnsUtils from '@date-io/date-fns'
import DateDayjsUtils from '@date-io/dayjs'
import * as Sentry from '@sentry/react'
import 'date-fns'
import dayjs from 'dayjs'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
import { SnackbarProvider } from 'notistack'

import { useEffect } from 'react'

import { MuiPickersUtilsProvider } from '@material-ui/pickers'
// @material-ui/styles
import ThemeProvider from '@material-ui/styles/ThemeProvider'

import CookiesNotification from '@Components/CookiesNotification/CookiesNotification'
import LoadingInit from '@Components/LoadingInit'
import { SnackbarUtilsConfigurator } from '@Components/Snackbar/Snackbar'

// 自定义主题
import theme from 'src/Common/jss/theme'
import LocalizationProvider from 'src/Context/LocalizationProvider'
import Router from 'src/Router/route'
import { getZendesk, isSpace } from 'src/Utils/auth'
import { getCookiesLoginDomain } from 'src/Utils/cache'
import { isHaveLoginDomain } from 'src/Utils/checkPermission'

import LocalesProvider from './Containers/LocalesProvider'

dayjs.extend(utc)
dayjs.extend(timezone)
const utcDayJs = (...args) => dayjs(...args).utc()

function App() {
  useEffect(() => {
    const handleAction = async () => {
      try {
        const res = await navigator.userAgentData.getHighEntropyValues([
          'architecture'
        ])
        const { architecture, platform } = res

        if (architecture !== 'x86' && platform === 'macOS') {
          //  localStorage.setItem('arch', 'unknown')
          Sentry.captureException(new Error('architecture issue'))
        } else {
          localStorage.setItem('arch', 'compatible')
        }
      } catch (err) {
        Sentry.captureException(err)
      }
    }

    handleAction()
  }, [])

  const hasSpaceOrderId = isSpace()
  //console.log('isSpace', hasSpaceOrderId, hasSpaceOrderId)
  if (hasSpaceOrderId) {
    window.location.href = '/'
    return false
  }
  // zendesk 过来，重新登录
  getZendesk().removeLoginInfo()
  useEffect(() => {
    redirectByLoginDomain()
  }, [])
  const redirectByLoginDomain = () => {
    const cookiesLoginDomain = getCookiesLoginDomain()
    if (isHaveLoginDomain(cookiesLoginDomain)) {
      localStorage.clear()
      window.location.href = `https://${cookiesLoginDomain}${window.location.pathname}${window.location.search}`
    }
  }

  return (
    <LocalesProvider>
      <LocalizationProvider
        dateAdapter={DateDayjsUtils}
        dateLibInstance={utcDayJs}
      >
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <div className="App">
            <ThemeProvider theme={theme}>
              <SnackbarProvider
                autoHideDuration={2000}
                maxSnack={2}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'center'
                }}
                preventDuplicate
              >
                <Router />
                <SnackbarUtilsConfigurator />
                <CookiesNotification />
                <LoadingInit />
              </SnackbarProvider>
            </ThemeProvider>
          </div>
        </MuiPickersUtilsProvider>
      </LocalizationProvider>
    </LocalesProvider>
  )
}

export default App
