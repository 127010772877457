import {
  defaultFont,
  fontRegular,
  grayColor,
  primaryBoxShadow,
  successColor,
  whiteColor
} from '../index'

const topBarStyle = theme => ({
  appBar: {
    boxShadow: '0 -10px 30px 0 rgba(128,128,128,0.4) !important',
    marginBottom: 0,
    color: grayColor[0],
    border: 0,
    transition: 'all 150ms ease 0s',
    backgroundColor: whiteColor
  },
  container: {
    paddingRight: '30px',
    justifyContent: 'space-between'
  },
  leftBox: {
    display: 'flex'
  },
  logoBox: {
    width: '236px'
  },
  flexGrow: {
    flexGrow: 1
  },
  signOutButton: {
    marginLeft: '8px'
  },
  logo: {
    width: '143px',
    height: 'auto'
  },
  logoWrapper: {
    width: '143px'
  },
  logoDemo: {
    background: '#9B9B9B',
    color: '#FFF',
    fontSize: '14px',
    marginLeft: '10px',
    padding: '0 5px'
  },
  '$logoText, $logoWrapper': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start'
  },
  logoText: {
    marginLeft: '5px'
  },
  logoTitle: {
    fontSize: '26px',
    color: successColor[0],
    fontFamily: '"Lato Bold", "Lato-Bold", "Lato", sans-serif',
    fontWeight: 'bold'
  },
  logoSubtitle: {
    ...defaultFont,
    fontSize: '20px',
    fontWeight: 400,
    color: 'rgba(121,125,122,1)',
    lineHeight: '36px',
    marginLeft: '2px'
  },
  generate: {
    padding: '7px 10px',
    borderRadius: '5px',
    marginRight: '20px'
  },
  managerClasses: {
    [theme.breakpoints.down('sm')]: {
      display: 'inline-block'
    }
  },
  buttonLink: {
    color: `${whiteColor} !important`,
    fontSize: 20,
    [theme.breakpoints.down('md')]: {
      display: 'flex',
      margin: '5px 15px 0',
      '& svg': {
        width: '30px',
        height: '24px',
        marginRight: '19px',
        marginLeft: '3px'
      },
      '& .fab, & .fas, & .far, & .fal, & .material-icons': {
        width: '30px',
        fontSize: '24px',
        lineHeight: '30px',
        marginRight: '19px',
        marginLeft: '3px'
      }
    }
  },
  userName: {
    width: 21,
    height: 21,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  popperClose: {
    pointerEvents: 'none',
    display: 'none !important'
  },
  popperResponsive: {
    zIndex: 1200
  },
  popperNav: {
    top: '10px !important'
  },
  dropdownItem: {
    ...defaultFont,
    fontSize: '14px',
    padding: '10px 20px',
    margin: '0 5px',
    borderRadius: '2px',
    position: 'relative',
    transition: 'all 150ms linear',
    display: 'block',
    clear: 'both',
    fontWeight: 400,
    height: '100%',
    color: grayColor[0],
    whiteSpace: 'nowrap',
    minHeight: 'unset',
    '& svg': {
      marginRight: '14px',
      fontSize: '16px'
    }
  },
  primaryHover: {
    '&:hover': {
      backgroundColor: successColor[0],
      color: whiteColor,
      ...primaryBoxShadow
    }
  },
  plans: {
    '& a': {
      ...fontRegular,
      fontSize: '14px',
      color: '#6D6E71',
      marginRight: '30px',
      '&:hover': {
        color: successColor[0]
      }
    }
  },
  partner: {
    marginRight: 40
  },
  partnerIcon: {
    color: '#6D6E71'
  },
  tag: {
    height: '28px',
    fontSize: '14px'
  },
  tagLabel: {
    marginRight: 2
  }
})

export default topBarStyle
