import classNames from 'clsx'
import { observer } from 'mobx-react'

import { useEffect, useState } from 'react'

import Box from '@material-ui/core/Box'
import { makeStyles } from '@material-ui/core/styles'

import { useStore } from '@Hooks/useStore'

import { fontRegular } from '@Common/jss/'
import { successColor, whiteColor } from '@Common/jss/index'

import ExpireLockedCopywriting from '@Views/Admin/Utils/ExpireLockedCopywriting'

const styles = () => ({
  root: {
    padding: '30px 50px',
    position: 'absolute',
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 99,
    background: 'rgba(255, 255, 255, 0.5)'
  },
  box: {
    width: '505px',
    padding: '40px 40px 25px 40px',
    backgroundColor: whiteColor,
    borderTop: `10px solid ${successColor[0]}`,
    borderRadius: '10px',
    boxShadow: '0px 0px 20px 0px rgba(0,0,0,0.35)'
  },
  head: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: 29
  },
  text: {
    ...fontRegular,
    '& > *': {
      display: 'flex',
      justifyContent: 'center'
    }
  },
  reason: {
    fontSize: 18,
    color: '#2C2C2C',
    lineHeight: '36px'
  },
  contact: {
    fontSize: 14,
    color: '#797D7A',
    lineHeight: '36px'
  },
  circle: {
    width: 64,
    height: 64,
    background: '#F4F6F7',
    borderRadius: 10,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  expireLockedIcon: {
    fontSize: 32,
    color: '#6D6E71'
  }
})
const useStyles = makeStyles(styles)

const ExpireLockedLayout = () => {
  const {
    devices: { devicesInfo }
  } = useStore()
  const [locked, setLocked] = useState(false)
  const classes = useStyles()

  useEffect(() => {
    setLocked(devicesInfo?.expireLock === 1)
  }, [devicesInfo])

  return locked ? (
    <div className={classes.root}>
      <Box className={classes.box}>
        <div className={classes.head}>
          <div className={classes.circle}>
            <svg
              aria-hidden="true"
              className={classNames('icon', {
                [classes.expireLockedIcon]: true
              })}
            >
              <use xlinkHref="#icon-locked" />
            </svg>
          </div>
        </div>
        <ExpireLockedCopywriting classes={classes} />
        {/* <Box className={classes.text}>
          <Box className={classes.reason}>
            License has expired. Please contact DTEN Support.
          </Box>
          <Box className={classes.contact}>Phone: 1.866.936.3836</Box>
          <Box className={classes.contact}>Email: SUPPORT@DTEN.COM</Box>
        </Box> */}
      </Box>
    </div>
  ) : null
}

export default observer(ExpireLockedLayout)
