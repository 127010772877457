import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

import { isTestUser } from 'src/Utils'
import { getCookiesToken } from 'src/Utils/cache'

import analytics from '../Utils/analytics'
//import ChurnZero from '../Utils/churnZero'
import setUserId from '../Utils/setUserId'

export default function useGoogleAnalytics() {
  const location = useLocation()

  useEffect(() => {
    analytics.init()
  }, [])

  useEffect(() => {
    const currentPath = location.pathname + location.search
    analytics.sendPageView(currentPath)
    window.dataLayer.push({
      user_id: setUserId(),
      current_url: currentPath
    })
    const userKeyLocal = getCookiesToken()
    if (userKeyLocal) {
      const userInfo = JSON.parse(localStorage.getItem('userInfo'))
      if (userInfo) {
        // ga 过滤内部测试邮箱
        const isTest = isTestUser(userInfo.emailAddress)
        window.dataLayer.push({
          isTest: isTest
        })
      }
    }
    //ChurnZero.identification()
  }, [location])
}
