import { cloneDeep } from 'lodash'
import { action, makeObservable, observable } from 'mobx'

import { LIMIT } from 'src/Utils/constants'

const STORE_NAME = `manageAuthQueryParams`

class MAuthStore {
  constructor(rootStore) {
    makeObservable(this, {
      queryParams: observable,
      setQueryParams: action.bound,
      setQueryParamsInit: action.bound
    })

    this.rootStore = rootStore
  }

  queryParams = localStorage.getItem(STORE_NAME)
    ? JSON.parse(localStorage.getItem(STORE_NAME))
    : {
        total: 0,
        limit: LIMIT,
        page: 1
      }

  setQueryParams(values) {
    const result = { ...cloneDeep(this.queryParams), ...values }
    this.queryParams = result
    localStorage.setItem(STORE_NAME, JSON.stringify(result))
  }

  setQueryParamsInit() {
    this.queryParams = {}
    localStorage.removeItem(STORE_NAME)
  }
}

export default MAuthStore
